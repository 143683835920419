import React, { Fragment, useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { checkBoxDatas } from "components/db/CategoryCheckBox";
import axios from "axios";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { isValidBizNum } from "components/common/common";
import { ErrorCodeList } from "components/common/errorCodeList";
import { setCookie } from "components/untils/Cookie";
import { useDispatch, useSelector } from "react-redux";
import { setLogin } from "redux/store/userSlice";

//term and condition
const dataArray = [
    {
        require: true,
        label: "(필수) 더 프리미언의 서비스 이용약관 동의",
        name: "checkbox1",
    },
    {
        require: true,
        label: "(필수) 더 프리미언의 개인정보처리방침 동의",
        name: "checkbox2",
    },
    {
        require: false,
        label: "(선택) 더 프리미언에서 추천하는 캠페인 및 이벤트 정보 수신 동의",
        name: "checkbox3",
    },
];

function JoinPage() {
    const inputRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    const [eachCheck, setEachCheck] = useState([]);
    const [allFlag, setAllFlag] = useState(false);
    const [termsFlag, setTermsFlag] = useState([false, false, false]);
    const [pwdCapsLock, setPwdCapsLock] = useState(false);
    const [rePwdCapsLock, setRePwdCapsLock] = useState(false);
    const [isAllChecked, setAllChecked] = useState(false);
    const [categoryCheck, setCategoryCheck] = useState(false);

    const {
        register,
        handleSubmit,
        watch,
        trigger,
        setValue,
        formState: { errors, isSubmitting, isDirty, isValid, dirtyFields },
    } = useForm({ mode: "onSubmit" });
    const checkedValues = watch(["checkbox1", "checkbox2"]);

    const onCheck = (checked, name, e) => {
        if (checked) {
            setEachCheck([...eachCheck, name]);
        } else if (!checked) {
            setEachCheck(eachCheck.filter((el) => el !== name));
        }
        if (eachCheck.length > 2 && checked === true) {
            alert(ErrorCodeList.msg711);
            setEachCheck(eachCheck.filter((el) => el !== name));
            e.target.value = false;
        }
    };

    const validate = (value) => {
        if (value?.length > 0) {
            setCategoryCheck(true);
        } else {
            setCategoryCheck(false);
        }
    };

    const onSubmit = (data) => {
        console.log("submit --- ", data);

        axios
            .post(process.env.REACT_APP_API + "client/user/checkid", {
                userId: data.userEmail,
            })
            .then(function (res) {
                if (data.companyNum !== "") {
                    if (isValidBizNum(data.companyNum)) {
                    } else {
                        alert(ErrorCodeList.msg720);
                        return false;
                    }
                }

                if (res?.data?.data?.tf == true) {
                    axios
                        .post(process.env.REACT_APP_API + "client/user/create", {
                            userId: data.userEmail,
                            password: data.userPwd,
                            company_name: data.companyName,
                            manager_contact: data.phoneNumber,
                            manager_name: data.companyUser,
                            company_url: data.companyUrl,
                            registration_number: data.companyNum,
                            criteria_type: data.criteria,
                            subscription_path: data.joinpath,
                            eventInfo: allFlag === true ? "Y" : "N",
                        })
                        .then(function (res) {
                            axios.post(process.env.REACT_APP_API + "mail/signUp", {
                                name: data.companyUser,
                                email: data.userEmail,
                            });
                            axios
                                .post(process.env.REACT_APP_API + "client/user/login", {
                                    userId: data.userEmail,
                                    password: data.userPwd,
                                })
                                .then((res) => {
                                    if (res?.data?.result === "success") {
                                        setCookie("token", res.data.data.token, { maxAge: 60 * 60 * 3 });
                                        dispatch(setLogin(res.data.data));
                                    }
                                })
                                .then(() => {
                                    navigate("/join/result", {
                                        state: {
                                            data: data,
                                        },
                                    });
                                })
                                .catch((error) => {
                                    alert(`에러가 발생하였습니다.\n잠시후 다시 시도해주세요.`);
                                    console.error(error);
                                });
                        });
                } else {
                    alert(ErrorCodeList.msg612);
                    return false;
                }
            });
    };
    //error
    const onError = (errors) => {
        console.log("onError", errors);
        // navigate("/join/result");
    };

    const selectAll = (e) => {
        const { checked } = e.target;
        setAllChecked(checked);
        setValue("checkbox1", checked); // 체크박스 1 값 업데이트
        setValue("checkbox2", checked); // 체크박스 2 값 업데이트
        setAllFlag(e.target.checked);
        setTermsFlag((prev) => {
            Object.keys(prev).map((item) => (prev[item] = e.target.checked));
            return {
                ...prev,
            };
        });
    };

    // 체크된 확인
    const isAnyCheckboxChecked = checkedValues.some((value) => value);

    const toggleCheck = (e, index) => {
        setTermsFlag((prev) => {
            const arr = { ...prev };
            arr[index] = !prev[index];
            return arr;
        });
    };

    const checkCapsLock1 = (e) => {
        let capsLock = e.getModifierState("CapsLock");
        setPwdCapsLock(capsLock);
    };

    const checkCapsLock2 = (e) => {
        let capsLock = e.getModifierState("CapsLock");
        setRePwdCapsLock(capsLock);
    };

    useEffect(() => {
        let allChecked = false;
        if (Object.values(termsFlag).every((item) => item)) {
            allChecked = true;
        }
        setAllFlag(allChecked);
    }, [termsFlag]);

    // useEffect(() => {
    //     if (isLoggedIn === true) {
    //         navigate("/join/result");
    //     }
    // }, [isLoggedIn]);

    return (
        <Fragment>
            <div className="hr_line"></div>
            <div className="register_form mtb190">
                <div className="container">
                    <div className="register_details">
                        <h2 className="inner_heading">광고주 회원가입</h2>

                        <form onSubmit={handleSubmit(onSubmit, onError)}>
                            <div className="row mb-3">
                                <label className="col-sm-3 col-form-label">
                                    {" "}
                                    이메일<span className="required_field">*</span>
                                </label>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="userEmail"
                                        placeholder="이메일을 입력해 주세요."
                                        {...register("userEmail", {
                                            required: "이메일은 필수입니다.",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: "올바른 이메일을 입력해 주세요.",
                                            },
                                        })}
                                    />
                                    {errors.userEmail && <span className="form-text text-danger">{errors.userEmail.message}</span>}
                                </div>
                            </div>

                            <div className="row mb-3">
                                <label className="col-sm-3 col-form-label">
                                    {" "}
                                    비밀번호<span className="required_field">*</span>
                                </label>
                                <div className="col-sm-9">
                                    <input
                                        type="password"
                                        className="form-control"
                                        name="userPwd"
                                        id="pwdInput"
                                        placeholder="영문, 숫자 조합 6자리 이상."
                                        onKeyDown={(e) => checkCapsLock1(e)}
                                        {...register("userPwd", {
                                            required: "비밀번호는 필수입니다.",
                                            pattern: {
                                                value: /^[a-zA-Z0-9]*$/,
                                                message: "영문, 숫자를 조합하여 주세요.",
                                            },
                                            minLength: {
                                                value: 6,
                                                message: "6자리 이상 입력하여 주세요.",
                                            },
                                        })}
                                    />
                                    {errors.userPwd && <span className="form-text text-danger">{errors.userPwd.message}</span>}
                                    {pwdCapsLock === true && <span className="form-text text-danger">Caps Lock이 켜져있습니다.</span>}
                                </div>
                            </div>

                            <div className="row mb-3">
                                <label className="col-sm-3 col-form-label">
                                    {" "}
                                    비밀번호 재확인<span className="required_field">*</span>
                                </label>
                                <div className="col-sm-9">
                                    <input
                                        type="password"
                                        className="form-control"
                                        name="checkPwd"
                                        placeholder="비밀번호를 다시 입력해 주세요."
                                        onKeyDown={(e) => checkCapsLock2(e)}
                                        {...register("checkPwd", {
                                            required: true,
                                            validate: (value) => {
                                                if (watch("userPwd") != value) return false;
                                            },
                                        })}
                                    />
                                    {errors.checkPwd && errors.checkPwd.type === "required" && <span className="form-text text-danger">비밀번호는 필수입니다.</span>}
                                    {errors.checkPwd && errors.checkPwd.type === "validate" && <span className="form-text text-danger">비밀번호가 일치하지 않습니다.</span>}
                                    {rePwdCapsLock === true && <span className="form-text text-danger">Caps Lock이 켜져있습니다.</span>}
                                </div>
                            </div>

                            <div className="row mb-3">
                                <label className="col-sm-3 col-form-label">
                                    {" "}
                                    회사명<span className="required_field">*</span>
                                </label>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="회사명을 입력해 주세요."
                                        name="companyName"
                                        {...register("companyName", {
                                            required: "회사명은 필수입니다.",
                                        })}
                                    />
                                    {errors.companyName && <span className="form-text text-danger">{errors.companyName.message}</span>}
                                </div>
                            </div>

                            <div className="row mb-3">
                                <label className="col-sm-3 col-form-label">
                                    {" "}
                                    담당자명<span className="required_field">*</span>
                                </label>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="companyUser"
                                        placeholder="담당자 성함을 입력해 주세요."
                                        {...register("companyUser", {
                                            required: "담당자 성함은 필수입니다.",
                                        })}
                                    />
                                    {errors.companyUser && <span className="form-text text-danger">{errors.companyUser.message}</span>}
                                </div>
                            </div>

                            <div className="row mb-3">
                                <label className="col-sm-3 col-form-label">
                                    {" "}
                                    담당자 연락처<span className="required_field">*</span>
                                </label>
                                <div className="col-sm-9">
                                    <input
                                        type="number"
                                        className="form-control"
                                        name="phoneNumber"
                                        maxLength="11"
                                        min="1"
                                        placeholder="담당자의 연락처를 01012345678 형식으로 적어주세요"
                                        {...register("phoneNumber", {
                                            required: "담당자 연락처는 필수입니다.",
                                            maxLength: {
                                                value: 11,
                                                message: "최대 11자리만 입력 가능합니다.",
                                            },
                                        })}
                                    />
                                    {errors.phoneNumber && <span className="form-text text-danger">{errors.phoneNumber.message}</span>}
                                </div>
                            </div>

                            <div className="row mb-3">
                                <label className="col-sm-3 col-form-label"> 사업자등록번호</label>
                                <div className="col-sm-9">
                                    <input
                                        type="number"
                                        min="1"
                                        className="form-control"
                                        name="companyNum"
                                        placeholder="사업자등록번호를 입력해 주세요."
                                        {...register("companyNum", {
                                            required: false,
                                            maxLength: {
                                                value: 11,
                                                message: "최대 11자리만 입력 가능합니다.",
                                            },
                                        })}
                                    />
                                    {errors.companyNum && <span className="form-text text-danger">{errors.companyNum.message}</span>}
                                </div>
                            </div>

                            <div className="row mb-3">
                                <label className="col-sm-3 col-form-label"> 회사 홈페이지</label>
                                <div className="col-sm-9">
                                    <input
                                        type="url"
                                        className="form-control"
                                        name="companyUrl"
                                        placeholder="홈페이지 주소를 입력해 주세요."
                                        {...register("companyUrl", {
                                            required: false,
                                            pattern: /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
                                        })}
                                    />
                                    {errors.companyUrl && errors.companyUrl.type === "pattern" && <span className="form-text text-danger">올바른 url를 입력하여 주세요.</span>}
                                </div>
                            </div>

                            <div className="row mb-3 check_box multi_check_box">
                                <label className="col-sm-3 col-form-label">
                                    {" "}
                                    취급업종<span className="required_field">*</span>
                                    <br />
                                    <span className="required_field">(최대 3개)</span>
                                </label>
                                <div className="col-sm-9">
                                    {checkBoxDatas.map((item) => {
                                        return (
                                            <div className="form-check form-check-inline" key={item.id}>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="criteria"
                                                    id={item.label}
                                                    value={item.label}
                                                    onClick={(e) => onCheck(e.target.checked, item.label, e)}
                                                    checked={eachCheck.includes(item.label) ? true : false}
                                                    {...register("criteria", { required: false, validate: validate })}
                                                />
                                                <label className="form-check-label" htmlFor={item.label} key={item.id} style={{ cursor: "pointer" }}>
                                                    {item.label}
                                                </label>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>

                            <div className="row mb-3">
                                <label className="col-sm-3 col-form-label"> 가입 경로</label>
                                <div className="col-sm-9">
                                    <select
                                        className="form-select"
                                        id="sel1"
                                        name="joinpath"
                                        {...register("joinpath", {
                                            required: false,
                                        })}
                                    >
                                        <option>인터넷 검색</option>
                                        <option>소셜미디어 광고</option>
                                        <option>지인추천</option>
                                        <option>기사(보도자료)</option>
                                        <option>이메일 광고</option>
                                        <option>기타</option>
                                    </select>
                                </div>
                            </div>

                            <div className="row mb-3" style={{ display: "none" }}>
                                <label className="col-sm-3 col-form-label">추천인</label>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="recommender"
                                        placeholder="추천인을 입력해 주세요"
                                        {...register("recommender", {
                                            required: false,
                                        })}
                                    />
                                    <span className="form-text text-danger d-block">추천인을 기입하시면 추천인과 가입자 모두에게 10,000원을 바로 적립해 드려요!</span>
                                </div>
                            </div>

                            <div className="row mb-5">
                                <div className="col-sm-12 form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" checked={allFlag} {...register("all")} onChange={selectAll} />
                                    <label className="form-check-label">
                                        전체 동의<span className="required_field">*</span>
                                    </label>
                                </div>

                                <div className="term_condition">
                                    <div className="col-sm-12  form-check form-check-inline">
                                        {dataArray.map((data, index) => {
                                            return (
                                                <div key={index}>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={termsFlag[index]}
                                                        {...register(data.name)}
                                                        onChange={(e) => toggleCheck(e, index)}
                                                    />
                                                    <label className="form-check-label">{data.label}</label>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <Button type="submit" className={`btn_layout ${isValid && isAnyCheckboxChecked && categoryCheck ? "" : "un_active"}`}>
                                    가입하기
                                </Button>
                            </div>
                        </form>
                    </div>
                    {/*register_details */}
                </div>
                {/*container */}
            </div>
            {/*register_form */}
        </Fragment>
    );
}

export default JoinPage;
