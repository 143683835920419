import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { useForm } from "react-hook-form";
import AuthTimer from "components/untils/AuthTimer";
import { ErrorCodeList } from "components/common/errorCodeList";

function PhoneEditModal({ setEffect, clientId, managerName }) {
    const ref = useRef(null);
    const [phoneNum, setPhoneNum] = useState("");
    const [authCheck, setAuthCheck] = useState(false);
    const [minutes, setMinutes] = useState("");
    const [seconds, setSeconds] = useState("");
    const [timeState, setTimeState] = useState(false);
    const [sendCnt, setSendCnt] = useState(1);
    const [sendCheck, setSendCheck] = useState(false);
    const [authNum, setAuthNum] = useState("");
    const [authResult, setAuthResult] = useState(false);
    // console.log("phone modal props ", setEffect);
    const {
        register,
        handleSubmit,
        reset,
        resetField,
        formState: { errors, isValid },
    } = useForm({ mode: "onChange" });

    const sendSmsBtn = () => {
        setSendCnt((prevCount) => prevCount + 1);
        if (sendCnt > 2) {
            alert("인증 번호 2회 전송은 1분후 재시도 가능합니다.");
            setSendCheck(true);
        } else {
            axios.post(process.env.REACT_APP_API + "auth/sendSMS", { phoneNumber: phoneNum }).then((res) => {
                if (res?.data?.result === "success") {
                    setMinutes(3);
                    setSeconds("00");
                    setTimeState(true);
                    alert(res.data.message);
                } else {
                    setTimeState(false);
                    alert(res.data.message);
                }
            });
        }
    };

    const checkBtn = () => {
        if (!authNum) {
            alert(ErrorCodeList.msg704);
        } else {
            axios.post(process.env.REACT_APP_API + "auth/verifySms", { phoneNumber: phoneNum, verifyNumber: authNum }).then((res) => {
                if (res?.data?.result === "success") {
                    alert(res?.data?.message);
                    setTimeState(false);
                    setAuthResult(true);
                } else {
                    alert(res?.data?.message);
                    setAuthResult(false);
                }
            });
        }
    };

    const onSubmit = (data) => {
        if (authResult) {
            axios.post(process.env.REACT_APP_API + "client/user/editClientPhone", { client_id: clientId, manager_contact: data.userPhone }).then((res) => {
                if (res?.data?.result === "success") {
                    alert(ErrorCodeList.msg706);
                    setEffect(true);
                    ref.current.click();
                    reset();
                } else {
                    alert(ErrorCodeList.msg707);
                    resetField("authNumber");
                }
            });
        }
    };

    const onError = (data) => {
        console.log("error", data);
        reset();
        ref.current.click();
    };

    useEffect(() => {
        const countdown = setInterval(() => {
            if (sendCheck === true) {
                if (parseInt(seconds) > 0) {
                    setSeconds(parseInt(seconds) - 1);
                }
                if (parseInt(seconds) === 0) {
                    if (parseInt(minutes) === 0) {
                        clearInterval(countdown);
                        setSendCheck(false);
                        setSendCnt(1);
                    } else {
                        setMinutes(parseInt(minutes) - 1);
                        setSeconds(59);
                    }
                }
            } else {
                clearInterval(countdown);
            }
        }, 1000);
        return () => clearInterval(countdown);
    }, [minutes, seconds]);

    useEffect(() => {
        const countdown = setInterval(() => {
            if (timeState === true) {
                if (parseInt(seconds) > 0) {
                    setSeconds(parseInt(seconds) - 1);
                }
                if (parseInt(seconds) === 0) {
                    if (parseInt(minutes) === 0) {
                        clearInterval(countdown);
                        setTimeState(false);
                    } else {
                        setMinutes(parseInt(minutes) - 1);
                        setSeconds(59);
                    }
                }
            } else {
                clearInterval(countdown);
            }
        }, 1000);
        return () => clearInterval(countdown);
    }, [minutes, seconds]);

    return (
        <div className="modal fade user_modal  modal-lg" id="contactNnbModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog  modal-dialog-centered">
                <div className="modal-content  text-center">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {" "}
                            담당자 연락처 수정하기
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={ref} style={{ display: "none" }}></button>
                        </h5>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit(onSubmit, onError)}>
                            <div className="row mb-3">
                                <div className="col-sm-12 text-start">
                                    <label className="col-form-label">이름</label>
                                </div>
                                <div className="col-sm-12">
                                    <input type="text" className="form-control" placeholder={managerName} disabled readOnly />
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-sm-12 text-start">
                                    <label className="col-form-label">휴대폰 번호</label>
                                </div>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="수정할 전화번호를 입력해 주세요"
                                        name="userPhone"
                                        {...register("userPhone", {
                                            onChange: (e) => setPhoneNum(e.target.value),
                                            required: true,
                                        })}
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <Button onClick={() => sendSmsBtn()}>인증번호 발송</Button>
                                    {authCheck === false && <AuthTimer minutes={minutes} seconds={seconds} timeState={timeState} className="time" />}
                                </div>
                            </div>

                            <div className="row mb-5">
                                <div className="col-sm-12 text-start">
                                    <label className="col-form-label">인증번호</label>
                                </div>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="수신한 인증번호 6자리 숫자를 입력해 주세요"
                                        name="authNumber"
                                        {...register("authNumber", {
                                            required: true,
                                            onChange: (e) => setAuthNum(e.target.value),
                                        })}
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <Button onClick={() => checkBtn()}>인증</Button>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-sm-12">
                                    <Button className={`save_btn btn_layout ${isValid && authResult ? "" : "un_active"}`} type="submit">
                                        저장
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PhoneEditModal;
