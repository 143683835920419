import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import AxiosPost from "components/untils/AxiosPost";
import { addCommas } from "components/common/common";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";

import { DescriptionFileSave } from "components/untils/UploadFile";
import { removeWhitespace } from "components/untils/UploadImg";

function CampaignQuote({ jsonData }) {
    console.log("jsonData", jsonData);
    const navigate = useNavigate();
    const loginSeq = useSelector((state) => state.auth.seq);
    const loginEmail = useSelector((state) => state.auth?.email);
    const [userInfo, setUserInfo] = useState("");
    const [buttonVisible, setButtonVisible] = useState(true);
    const [loading, setLoading] = useState(false);

    const nextPageBtn = () => {
        setLoading(true);
        try {
            let setData = {
                introduction: jsonData?.introduction?.replace(/\n/g, "<br>"),
                text_requirements: jsonData?.text_requirements?.replace(/\n/g, "<br>"),
            };
            let tempJson = jsonData;
            for (var key in setData) {
                tempJson[key] = setData[key];
            }
            // const newImgArr = Array.from(tempJson?.campaign_images);
            const onbinedArray = tempJson?.campaign_images ? tempJson?.campaign_product_pictures?.concat(tempJson?.campaign_images) : tempJson?.campaign_product_pictures;
            const firstItem = onbinedArray[0]; // 첫번째 건들이지 않기 위해 따로 저장
            const filterArray = onbinedArray?.filter((item, index) => {
                if (index > 0 && item?.name === firstItem?.name) {
                    return false;
                } else {
                    return true;
                }
            });

            // for (let i = 0; i < filterArray?.length; i++) {
            //     let imgDB = process.env.REACT_APP_S3_URL + "images/campaigns/" + 31 + "/" + filterArray[i].name;
            //     console.log("--", imgDB);
            //     setLoading(false);
            //     // await AxiosPost("campaign/imgCreate", {
            //     //     campaign_id: 31,
            //     //     image_data: imgDB,
            //     // }).then((res) => {
            //     //     DescriptionFileSave(filterArray[i], 31);
            //     // });
            // }

            AxiosPost("campaign/create", tempJson)
                .then(async (res) => {
                    if (res?.data?.result === "success") {
                        await AxiosPost("mail/campaignStart", {
                            name: userInfo?.manager_name,
                            campaignName: jsonData.campaign_name,
                            email: userInfo?.email,
                        });
                        navigate("/campaignpymtdone");

                        let axiosCampaignId = res?.data?.data?.rows?.insertId;
                        let pictureArr = Array.from(jsonData?.campaign_product_pictures);

                        for (let i = 0; i < filterArray?.length; i++) {
                            let imgDB = process.env.REACT_APP_S3_URL + "images/campaigns/" + axiosCampaignId + "/" + filterArray[i].name;
                            await AxiosPost("campaign/imgCreate", {
                                campaign_id: axiosCampaignId,
                                image_data: imgDB,
                            }).then((res) => {
                                DescriptionFileSave(filterArray[i], axiosCampaignId);
                                setLoading(false);
                            });
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    alert(error);
                });
        } catch (error) {
            setLoading(false); // 오류 발생 시 로딩 종료
        }
    };

    // jsonData?.product_price
    // 결제 테스트를 위한 코드
    const payTestBtn = () => {
        axios
            .post(
                "payletter",
                {
                    pgcode: "creditcard",
                    client_id: "pay_test",
                    user_id: loginEmail,
                    user_name: "양다현",
                    amount: jsonData?.product_price,
                    product_name: jsonData?.campaign_name,
                    return_url: "https://testpg.payletter.com/result",
                    callback_url: "https://testpg.payletter.com/callback",
                },
                {
                    headers: {
                        Authorization: `PLKEY ${process.env.REACT_APP_PAYLETTER_AUTH}`,
                    },
                }
            )
            .then((res) => {
                // console.log("결제 완료", res);
                // console.log(res?.data?.online_url);
                var popOption = "width=430, height=555, resizable=no, scrollbars=no, status=no;";
                window.open(res?.data?.online_url, "_blank", popOption);
            })
            .catch((err) => {
                console.log("에러", err);
            });
    };

    useEffect(() => {
        AxiosPost("client/user/info", { client_id: loginSeq }).then((res) => {
            setUserInfo(res?.data?.data?.rows);
        });
    }, []);

    return (
        <Fragment>
            <div className="check_campaign_request mtb190">
                <div className="container">
                    <h2 className="inner_heading">캠페인 견적서</h2>
                    <div className="findresults mb-5">
                        <p>
                            결제 버튼을 누르면 실제 결제창으로 넘어갑니다. <br />
                            신중히 생각 후 결제해 주세요.
                        </p>
                    </div>

                    <div className="campaign_request_detail">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-4">
                                    <h4>모집인원 합계</h4>
                                </div>
                                <div className="col-sm-4 text-center">
                                    <h4>{jsonData?.campaign_counter}명</h4>
                                </div>
                                <div className="col-sm-4 text-end">
                                    <h4>{addCommas(jsonData?.product_price)}원</h4>
                                </div>
                            </div>

                            {/* <div className="row">
                                <div className="col-sm-6">
                                    <p>부가세</p>
                                </div>
                                <div className="col-sm-6 text-end">
                                    <p>85,000원</p>
                                </div>
                            </div> */}

                            <div className="row">
                                <div className="col-sm-6">
                                    <h4>
                                        <span>최종 견적</span>
                                    </h4>
                                </div>
                                <div className="col-sm-6 text-end">
                                    <h4>
                                        <span>{addCommas(jsonData?.product_price)}원</span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-sm-12 text-center ">
                            {loading ? (
                                <Button className="btn_layout">
                                    <Spinner as="span" animation="border" size="sm" variant="light" role="status" aria-hidden="true" />
                                </Button>
                            ) : (
                                <Button onClick={nextPageBtn} className="btn_layout">
                                    결제하기
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default CampaignQuote;
