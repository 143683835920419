import React from "react";

export const TimeLeft = ({ endDate }) => {
    let year = new Date(endDate);
    const difference = +new Date(`${year}`) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
        timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
        };
    }

    return timeLeft;
};

export const eachTimeLeft = ({ end_date }) => {
    let timeLeft = {};
    let year = new Date("2023-05-27T15:00:00.000Z");
    const difference = +new Date(`${year}`) - +new Date();

    if (difference > 0) {
        timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / (1000 * 60)) % 60),
            seconds: Math.floor((difference / 1000) % 60),
        };
    }

    return timeLeft;
};

export const calculateTimeLeft = (end_date) => {
    end_date = end_date + " 23:59:59";
    const difference = +new Date(end_date) - +new Date();

    if (difference > 0) {
        const timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / (1000 * 60)) % 60),
            seconds: Math.floor((difference / 1000) % 60),
        };

        return timeLeft;
    }

    return null;
};
