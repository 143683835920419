import React, { Fragment, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import ContactUsModel from "components/modals/ContactUs/ContactUsModal";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { ErrorCodeList } from "components/common/errorCodeList";

const ContactUs = () => {
    const loginSeq = useSelector((state) => state.auth.seq);
    const [userInfo, setUserInfo] = useState(null);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: "onSubmit" });

    const onSubmit = (data) => {
        setLoading(true);
        axios
            .post(process.env.REACT_APP_API + "mail/inquire", {
                name: userInfo.manager_name,
                email: userInfo.email,
            })
            .then((res) => {
                if (res?.data?.status === "Success") {
                    handleShow();
                    setLoading(false);
                } else {
                    alert(ErrorCodeList.msg719);
                    setLoading(false);
                }
            });
    };

    const onError = (data) => {
        console.log("onError -- ", data);
    };

    useEffect(() => {
        axios.post(process.env.REACT_APP_API + "client/user/info", { client_id: loginSeq }).then((res) => {
            setUserInfo(res.data.data.rows);
        });
    }, []);

    return (
        <Fragment>
            <div className="hr_line"></div>
            <div className="contactus mtb190">
                <div className="container">
                    <h2 className="inner_heading">문의하기</h2>
                    <p className="text-center mb-5 notice">캠페인 진행에 대해 궁금한 점이 있으시면 언제든 문의해주세요.</p>

                    <div className="contactus_data">
                        <form onSubmit={handleSubmit(onSubmit, onError)}>
                            <div className="row  mb-3">
                                <div className="col-sm-6">
                                    <div className="row">
                                        <label className="col-sm-2 form-label">회사명</label>
                                        <div className="col-sm-10">
                                            <div className="   input-group">
                                                <input className="form-control" defaultValue={userInfo?.company_name} disabled readOnly />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="row">
                                        <label className="col-sm-2 form-label">담당자명</label>
                                        <div className="col-sm-10">
                                            <div className="input-group">
                                                <input className="form-control" defaultValue={userInfo?.manager_name} disabled readOnly />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row  mb-3">
                                <div className="col-sm-6">
                                    <div className="row">
                                        <label className="col-sm-2 form-label">이메일</label>
                                        <div className="col-sm-10">
                                            <div className="input-group">
                                                <input className="form-control" defaultValue={userInfo?.email} disabled readOnly />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="row">
                                        <label className="col-sm-2 form-label">연락처</label>
                                        <div className="col-sm-10">
                                            <div className="input-group">
                                                <input className="form-control" defaultValue={userInfo?.manager_contact || ""} disabled readOnly />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row  mb-3">
                                <div className="col-sm-6">
                                    <div className="row">
                                        <label className="col-sm-2 form-label">상담유형</label>
                                        <div className="col-sm-10">
                                            <div className="input-group">
                                                <select className="form-select" id="consult" {...register("counselingType")}>
                                                    <option>캠페인 서비스</option>
                                                    <option>캠페인 비용</option>
                                                    <option>플랫폼 기술</option>
                                                    <option>캠페인 진행</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            

                            <div className="row  mb-3">
                                <div className="col-sm-12">
                                    <div className="row">
                                        <label className="col-sm-1 form-label">상세 내용</label>
                                        <div className="col-sm-11">
                                            <div className="input-group">
                                                <textarea
                                                    className="form-control"
                                                    rows="6"
                                                    placeholder="캠페인 번호, 이름 등 문의 내용을 포함하여 상세하게 문의해 주시면 빠르게 답변을 드리는 데 도움이 됩니다."
                                                    {...register("content", { required: true })}
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row  mb-3">
                                <div className="col-sm-12 ">
                                    <div className="row">
                                        <label className="col-sm-1"></label>
                                        <div className="col-sm-11">
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    {...register("checkbox", {
                                                        required: "동의사항은 필수입니다.",
                                                    })}
                                                />
                                                <label className="form-check-label"> 개인정보 수집 및 이용안내에 동의합니다. </label>
                                            </div>
                                            {errors.checkbox && <span className="form-text text-danger">{errors.checkbox.message}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3 mt-5">
                                <div className="d-flex justify-content-center">
                                    {loading ? (
                                        <Button variant="primary" disabled>
                                            <Spinner as="span" animation="border" size="sm" variant="light" role="status" aria-hidden="true" />
                                            <span className="visually-hidden"> Loading... </span>
                                        </Button>
                                    ) : (
                                        <Button type="submit" className="btn_layout col-auto ">
                                            문의하기
                                        </Button>
                                    )}
                                    <ContactUsModel show={show} handleClose={handleClose} userName={userInfo?.manager_name} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ContactUs;
