import React, { Fragment, useState, useEffect, useCallback, useRef } from "react";
import "../../assets/style/custom.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useDropzone } from "react-dropzone";

function DropZoneList({ imageFiles, setImageFiles, setSelectFiles }) {
    const fileId = useRef(1);
    const dragItem = useRef(); // 드래그할 아이템의 인덱스
    const dragOverItem = useRef(); // 드랍할 위치의 아이템의 인덱스

    const onDrop = async (acceptedFiles) => {
        let largeImage = false; // 이미지 사이즈가 크다.
        let bigImage = false; // 이미지 용량이 크다.
        let typeImage = false; // 이미지 형식이 다르다.

        // 비동기 함수를 사용하여 이미지 사이즈 검증 처리
        async function validateImage(imageSrc, imageName) {
            return new Promise((resolve) => {
                const image = new Image();
                image.src = imageSrc;
                image.onload = () => {
                    // 이미지 사이즈 검증
                    if (image.width > 1080 || image.height > 1080) {
                        largeImage = true;
                    }
                    resolve({
                        id: "item" + fileId.current++,
                        src: imageSrc,
                        width: image.width,
                        height: image.height,
                        name: imageName,
                    });
                };
            });
        }
        // 비동기 함수를 사용하여 이미지 용량 검증 처리
        async function validateImageSize(file) {
            return new Promise((resolve) => {
                if (file.size > 10 * 1080 * 1080) {
                    bigImage = true;
                }
                resolve();
            });
        }

        for (const file of acceptedFiles) {
            // 이미지 형식 검증
            if (!/image\/(jpeg|png|jpg)/.test(file.type)) {
                typeImage = true;
            }
            const imageSrc = URL.createObjectURL(file);
            const imageName = file.name;
            // 이미지 용량 검증
            await validateImageSize(file);
            const imageInfo = await validateImage(imageSrc, imageName);
            if (largeImage) {
                alert("이미지는 1080*1080 사이즈로 가능합니다.");
                return;
            }
            if (acceptedFiles?.length > 4) {
                alert("이미지는 최대 4장까지 가능합니다.");
                return;
            }
            if (typeImage) {
                alert("올바른 이미지 파일 형식이 아닙니다.");
                return;
            }
            if (bigImage) {
                alert("이미지는 10MB까지 가능합니다.");
                return;
            }

            setImageFiles((prevImageFile) => [...prevImageFile, imageInfo]); // 이미지를 배열에 추가
        }
    };

    // 이미지 드래그 이동
    function handleOnDragEnd(result) {
        if (!result.destination) return;

        const items = Array.from(imageFiles);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setImageFiles(items);
    }

    // 이미지 파일 객체로 수정
    const createFileArray = async () => {
        const fileArray = await Promise.all(
            imageFiles.map(async (imageInfo) => {
                const blob = await fetch(imageInfo.src).then((response) => response.blob());
                return new File([blob], imageInfo.name, { type: blob.type });
            })
        );
        return fileArray;
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
    });

    const filterFiles = useCallback(
        (id) => {
            const filteredFiles = imageFiles?.filter((file) => file?.id !== id);
            setImageFiles(filteredFiles);
        },
        [imageFiles]
    );

    // 드래그 시작될 때 실행
    const dragStart = (e, position) => {
        dragItem.current = position;
    };

    // 드래그중인 대상이 위로 포개졌을 때
    const dragEnter = (e, position) => {
        dragOverItem.current = position;
    };

    // 드랍 (커서 뗐을 때)
    const drop = (e) => {
        const newList = [...imageFiles];
        const dragItemValue = newList[dragItem.current];
        newList.splice(dragItem.current, 1);
        newList.splice(dragOverItem.current, 0, dragItemValue);
        dragItem.current = null;
        dragOverItem.current = null;
        setImageFiles(newList);
    };

    // useEffect(() => {
    //     const animation = requestAnimationFrame(() => setEnabled(true));

    //     return () => {
    //         cancelAnimationFrame(animation);
    //         setEnabled(false);
    //     };
    // }, []);

    // if (!enabled) {
    //     return null;
    // }

    useEffect(() => {
        const fetchData = async () => {
            try {
                let fileDatas = await createFileArray(imageFiles);
                setSelectFiles(fileDatas);
            } catch (error) {
                // 오류 처리
                console.error("데이터 가져오기 오류:", error);
            }
        };

        fetchData();
    }, [imageFiles]);

    return (
        <div className=" ">
            {imageFiles?.length !== 4 && (
                <div {...getRootProps()} id="drop-zone">
                    <input {...getInputProps()} />
                    <div className="input-image form-control" style={{ height: "80px", marginBottom: "20px" }}>
                        <p>이미지를 여기에 드래그 앤 드롭하거나 클릭하여 업로드하세요.</p>
                    </div>
                </div>
            )}
            {imageFiles?.length > 0 && (
                <div className="col-sm-12">
                    <ul
                        className="imageFiles"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    >
                        {imageFiles?.map((imageInfo, index) => {
                            return (
                                <div className="image-box" key={index}>
                                    <span
                                        className="image-span"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            filterFiles(imageInfo?.id);
                                        }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        X
                                    </span>
                                    <li
                                        className="image-li"
                                        draggable
                                        onDragStart={(e) => dragStart(e, index)}
                                        onDragEnter={(e) => dragEnter(e, index)}
                                        onDragEnd={drop}
                                        onDragOver={(e) => e.preventDefault()}
                                    >
                                        <img src={imageInfo.src} alt={imageInfo.name} className="list-image" />
                                    </li>
                                </div>
                            );
                        })}
                    </ul>
                </div>
            )}
            {/* {imageFiles?.length > 0 ? (
                    return(
                        {imageFiles?.map((imageInfo, index) => {
                            <div
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                            >
                                <span
                                    className="image-span"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        filterFiles(imageInfo?.id);
                                    }}
                                    style={{ cursor: "pointer" }}
                                >
                                    X
                                </span>
                                <li className="image-li">
                                    <img src={imageInfo.src} alt={imageInfo.name} className="list-image" />
                                </li>
                            </div>
                            }
                    )
                ) : (
                  
                    <p>이미지를 여기에 드래그 앤 드롭하거나 클릭하여 업로드하세요.</p>
                )} */}

            <div className="form-text">*이미지는 1080*1080 사이즈로, 최대 4장 까지, 각 파일당 10MB까지 업로드 가능해요</div>
        </div>
    );
}

export default DropZoneList;
// <DragDropContext onDragEnd={handleOnDragEnd}>
//     <Droppable droppableId="droppable" direction="horizontal">
//         {(provided) => (
//             <ul className="imageFiles" ref={provided.innerRef}>
//                 {imageFiles?.map((imageInfo, index) => {
//                     return (
//                         <Draggable key={imageInfo?.id} {...provided.draggableProps} draggableId={imageInfo?.id} index={index}>
//                             {(provided) => (
//                                 <div
//                                     {...provided.draggableProps}
//                                     {...provided.dragHandleProps}
//                                     ref={provided.innerRef}
//                                     onClick={(e) => {
//                                         e.preventDefault();
//                                         e.stopPropagation();
//                                     }}
//                                 >
//                                     <span
//                                         className="image-span"
//                                         onClick={(e) => {
//                                             e.preventDefault();
//                                             e.stopPropagation();
//                                             filterFiles(imageInfo?.id);
//                                         }}
//                                         style={{ cursor: "pointer" }}
//                                     >
//                                         X
//                                     </span>
//                                     <li className="image-li">
//                                         <img src={imageInfo.src} alt={imageInfo.name} className="list-image" />
//                                     </li>
//                                 </div>
//                             )}
//                         </Draggable>
//                     );
//                 })}
//             </ul>
//         )}
//     </Droppable>
// </DragDropContext>
