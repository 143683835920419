import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

const initialState = {
    seq: "",
    email: "",
    isLoggedIn: false,
};
const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setLogin: (state, action) => {
            state.seq = action.payload.client_id;
            state.email = action.payload.email;
            state.isLoggedIn = true;
        },
        setLogout: (state) => {
            state.seq = "";
            state.email = "";
            state.isLoggedIn = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, () => initialState);
    },
});
// Action Creator를 내보냅니다.
export const { setLogin, setLogout } = authSlice.actions;

export default authSlice;
