import React from "react";
import { ResponsiveLine } from "@nivo/line";
import { addCommas } from "components/common/common";

function LineChart({ datas, jsonArray }) {
    const defaultData = [{ id: "empty", data: [{ x: 0, y: 0 }] }]; // 빈 차트를 위한 기본값 데이터 배열
    return (
        <div style={{ width: "auto", height: 550 }}>
            <ResponsiveLine
                data={datas[0]?.data?.length > 0 ? datas : defaultData}
                margin={{ top: 80, right: 60, bottom: 50, left: 60 }}
                xScale={{ type: "point" }}
                yScale={{
                    type: "linear",
                    min: 0,
                    max: "auto",
                    stacked: true,
                    reverse: false,
                }}
                yFormat=" >-.1f"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendOffset: 36,
                    legendPosition: "middle",
                }}
                axisLeft={{
                    tickSize: 14,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendOffset: -40,
                    legendPosition: "middle",
                    format: (value) => value.toLocaleString(),
                }}
                // pointSize={7}
                enableGridX={false}
                colors={{ scheme: "set2" }}
                pointSize={11}
                // pointColor="#ffffff"
                enablePointLabel={true}
                pointLabel={(e) => e.y?.toLocaleString()}
                pointLabelYOffset={26}
                pointColor={{ from: "color", modifiers: [] }}
                pointBorderWidth={2}
                pointBorderColor={{ from: "serieColor" }}
                useMesh={true}
                enableSlices="y"
                sliceTooltip={(points, slice) => {
                    const originalDataX = points?.slice?.points[0]?.data?.x; // 스케일링된 데이터의 x 값
                    const originalData = jsonArray?.find((item) => item?.create_at === originalDataX); // 원본 데이터
                    const containerStyle = {
                        width: "170px", // 원하는 너비 값으로 설정
                        backgroundColor: "#ffffff",
                        padding: "8px",
                        borderRadius: "4px",
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
                        textAlign: "center",
                    };
                    return (
                        <div style={containerStyle}>
                            {points &&
                                points?.slice?.points?.slice(0, 1)?.map((point) => {
                                    return (
                                        <div key={point.id}>
                                            <span style={{ display: "inline-block", width: "12px", height: "12px", backgroundColor: point.serieColor, marginRight: "10px" }}></span>
                                            <strong>{point.serieId}: </strong>
                                            {point.serieId === "팔로워" && originalData?.hasOwnProperty("followers_count") && addCommas(originalData.followers_count)}
                                            {point.serieId === "댓글" && originalData?.hasOwnProperty("comment_cnt") && addCommas(originalData.comment_cnt)}
                                            {point.serieId === "좋아요" && originalData?.hasOwnProperty("like_cnt") && addCommas(originalData.like_cnt)}
                                            {point.serieId === "반응률(ER)" && originalData.hasOwnProperty("ER") && originalData.ER}
                                        </div>
                                    );
                                })}
                        </div>
                    );
                }}
                enableCrosshair={false}
                legends={[
                    {
                        anchor: "top-right",
                        direction: "row",
                        justify: false,
                        translateX: 32,
                        translateY: -32,
                        itemWidth: 77,
                        itemHeight: 20,
                        itemsSpacing: 4,
                        symbolSize: 10,
                        symbolShape: "circle",
                        itemDirection: "left-to-right",
                        itemTextColor: "#777",
                        effects: [
                            {
                                on: "hover",
                                style: {
                                    itemBackground: "rgba(0, 0, 0, .03)",
                                    itemOpacity: 1,
                                },
                            },
                        ],
                    },
                ]}
            />
        </div>
    );
}

export default LineChart;
