import React, { useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import AxiosPost from "components/untils/AxiosPost";
import { isValidBizNum, isOver30Days } from "components/common/common";
import { ErrorCodeList } from "components/common/errorCodeList";

function CompanyNumberEditModal({ deforedate, clientId, setEffect, regnumActive }) {
    const ref = useRef(null);
    const [companyNum, setCompanyNum] = useState("");
    const [activeCheck, setActiveCheck] = useState(false);
    const [updateCheck, setUpdateCheck] = useState(false);

    const updateRegnum = () => {
        AxiosPost("client/user/checkRegNum", { registration_number: companyNum }).then((res) => {
            if (res?.data?.result === "fail") {
                alert(ErrorCodeList.msg704);
            } else {
                if (isValidBizNum(companyNum)) {
                    alert("확인되었습니다. 저장 버튼을 클릭해 주세요.");
                    setUpdateCheck(true);
                } else {
                    alert("올바른 사업자 등록번호를 입력해주세요.");
                }
            }
        });
    };

    const checkComapnyNum = () => {
        if (activeCheck) {
            if (regnumActive === "0") {
                updateRegnum();
            } else if (isOver30Days(deforedate)) {
                updateRegnum();
            } else {
                alert("30일 이후에 수정이 가능합니다.");
            }
        } else {
            alert("사업자 등록번호를 입력해 주세요.");
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setCompanyNum(value);
        setActiveCheck(value.trim() !== "");
    };

    const companyNumUpdate = () => {
        if (updateCheck) {
            AxiosPost("client/user/editClientRegNum", { client_id: clientId, registration_number: companyNum }).then((res) => {
                if (res?.data?.result === "success") {
                    setEffect(true);
                    setActiveCheck(false);
                    setUpdateCheck(false);
                    setCompanyNum("");
                    ref.current.click();
                } else {
                    alert("사업자 등록번호 수정 실패");
                    return false;
                }
            });
        } else {
            alert("번호를 다시 한번 확인해주세요.");
        }
    };

    return (
        <div className="modal fade user_modal  modal-lg" id="businessNnbModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog  modal-dialog-centered">
                <div className="modal-content  text-center">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            사업자 등록번호 수정하기
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={ref} style={{ display: "none" }}></button>
                    </div>
                    <div className="modal-body">
                        <p className="notice">사업자 등록번호는 한 달에 한 번만 수정 가능합니다. 신중하게 결정해주세요.</p>
                        <div className="row mb-5">
                            <div className="col-sm-12 text-start">
                                <label className="col-form-label">사업자 등록번호</label>
                            </div>
                            <div className="col-sm-9">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="수정할 사업자 등록번호를 입력해 주세요"
                                    value={companyNum}
                                    onChange={(e) => {
                                        setCompanyNum(e.target.value);
                                        handleInputChange(e);
                                    }}
                                />
                            </div>
                            <div className="col-sm-3">
                                <Button className={`${activeCheck ? "active" : ""}`} onClick={() => checkComapnyNum()}>
                                    확인
                                </Button>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-sm-12">
                                <Button className={`save_btn btn_layout ${updateCheck ? "active" : ""}`} disabled={!updateCheck} onClick={companyNumUpdate}>
                                    저장
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default CompanyNumberEditModal;
