import React, { Fragment, useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import { formatInstagramNumber, checkNaN } from "components/common/common";
import AxiosPost from "components/untils/AxiosPost";

import moment from "moment";
import { selectCampaignType, selectProcessText } from "components/common/SelectData";
import { calculateTimeLeft } from "components/untils/TimeLeft";
import { truncateString } from "components/untils/utils";

function OngoingCampaign({ myCampaignList, setMyCampaignList }) {
    // console.log("myCampaignList!!!", myCampaignList);
    const navigate = useNavigate();
    const [totalFollower, setTotalFollower] = useState("");
    useEffect(() => {
        const intervalId = setInterval(() => {
            // 데이터를 업데이트하는 로직을 작성합니다.
            // 남은 시간을 다시 계산하여 상태를 업데이트합니다.
            const updatedData = myCampaignList.map((item) => {
                let timeLeft;
                if (item?.current_status === "posting") {
                    timeLeft = calculateTimeLeft(item.posting_end_date);
                } else {
                    timeLeft = calculateTimeLeft(item.end_date);
                }
                return { ...item, timeLeft };
            });
            // console.log("updatedData -- ", updatedData);
            setMyCampaignList(updatedData);
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [myCampaignList]);

    const clickDetailPage = (item) => {
        navigate(`/campaignDetail/${item?.campaign_id}`, {
            state: {
                campaignId: item?.campaign_id,
            },
        });
    };

    // 진행 상태 구별
    const selectProcessType = (campaignType, startDate) => {
        let today = moment().format("YYYY-MM-DD");
        let start_date = moment(startDate).format("YYYY-MM-DD");
        let result = "";
        if (campaignType === "recruiting" || campaignType === "recruitDone") {
            result = "모집중";
        } else if (campaignType === "posting" && start_date > today) {
            result = "모집마감";
        } else if (campaignType === "shipping" && start_date > today) {
            result = "모집마감";
        } else if (campaignType === "shipping") {
            result = "배송중";
        } else if (campaignType === "posting") {
            result = "포스팅 현황";
        }
        return result;
    };

    const formatTime = (value) => {
        return value < 10 ? `0${value}` : value?.toString();
    };

    return (
        <Fragment>
            <div className="ongoing_campaign">
                <Accordion defaultActiveKey={0}>
                    {myCampaignList?.map((item, index) => {
                        const timeLeft =
                            item?.current_status === "recruiting" || item?.current_status === "recruitDone"
                                ? calculateTimeLeft(item.end_date)
                                : calculateTimeLeft(item.posting_end_date);
                        return (
                            // desc 한 campaign_id 와 item.campaign_id가 같으면 0 아니면 index
                            <Accordion.Item eventKey={myCampaignList[0]?.campaign_id === item?.campaign_id ? 0 : index + 1} key={item?.campaign_id}>
                                <Accordion.Header>
                                    <div className="col-sm-10 table-responsive">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>캠페인 No</th>
                                                    <th>등록일</th>
                                                    <th>캠페인 명</th>
                                                    <th>캠페인 상세</th>
                                                    <th>캠페인 유형</th>
                                                    <th>진행 상태</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="fixed_height">{item?.campaign_id}</div>
                                                    </td>
                                                    <td>
                                                        <div className="fixed_height">{moment(item?.create_at).format("YYYY-MM-DD")}</div>
                                                    </td>
                                                    <td>
                                                        <div className="fixed_height">{item?.campaign_name}</div>
                                                    </td>
                                                    <td>
                                                        {/* <div className="fixed_height">{item?.introduction}</div> */}
                                                        <div className="fixed_height" dangerouslySetInnerHTML={{ __html: item?.introduction }}></div>
                                                    </td>
                                                    <td>
                                                        <div className="fixed_height">{selectCampaignType(item?.campaign_type)}</div>
                                                    </td>
                                                    <td>
                                                        <div
                                                            className="fixed_height recruite_btn"
                                                            onClick={() =>
                                                                navigate(`/influencermanagement/${item?.campaign_id}`, {
                                                                    state: {
                                                                        campaignId: item?.campaign_id,
                                                                        totalCount: item?.total_count,
                                                                    },
                                                                })
                                                            }
                                                        >
                                                            <Link>{selectProcessType(item?.current_status, item?.posting_start_date)}</Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-sm-2"></div>
                                </Accordion.Header>

                                <Accordion.Body>
                                    <div className="row">
                                        <div className="col-sm-10 padding_0">
                                            <div className=" table-responsive">
                                                <table>
                                                    <tbody>
                                                        <tr className="cursor">
                                                            <td onClick={() => clickDetailPage(item)}>{item?.campaign_id}</td>
                                                            <td onClick={() => clickDetailPage(item)}>{moment(item?.create_at).format("YYYY-MM-DD")}</td>
                                                            <td onClick={() => clickDetailPage(item)}>{item?.campaign_name}</td>
                                                            <td onClick={() => clickDetailPage(item)} dangerouslySetInnerHTML={{ __html: truncateString(item?.introduction, 50) }}>
                                                                {/* {truncateString(item?.introduction, 50)} */}
                                                            </td>
                                                            <td>{selectCampaignType(item?.campaign_type)}</td>
                                                            <td>
                                                                <div
                                                                    className="recruite_btn"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() =>
                                                                        navigate(`/influencermanagement/${item?.campaign_id}`, {
                                                                            state: {
                                                                                campaignId: item?.campaign_id,
                                                                                totalCount: item?.total_count,
                                                                            },
                                                                        })
                                                                    }
                                                                >
                                                                    {selectProcessType(item?.current_status, item?.posting_start_date)}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="countdown">
                                                <div className="row countdown_box">
                                                    <div className="col-sm-2">
                                                        <div className="countdown_info">
                                                            <p>
                                                                <span>팔로워</span>
                                                                {item?.followers_count ? formatInstagramNumber(parseInt(item?.followers_count)) : 0}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-2">
                                                        <div className="countdown_info">
                                                            <p>
                                                                <span>댓글</span>
                                                                {item?.comment_cnt ? formatInstagramNumber(item?.comment_cnt) : 0}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-2">
                                                        <div className="countdown_info">
                                                            <p>
                                                                <span>좋아요</span>
                                                                {item?.like_cnt ? formatInstagramNumber(item?.like_cnt) : 0}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-6">
                                                        <div className="countdown_info">
                                                            <p>
                                                                <span
                                                                    className="date_time_txt"
                                                                    dangerouslySetInnerHTML={{ __html: selectProcessText(item?.current_status) }}
                                                                ></span>
                                                                <span className="date_time">
                                                                    {timeLeft
                                                                        ? `${timeLeft.days}일  ${formatTime(timeLeft.hours)}:${formatTime(timeLeft.minutes)}:${formatTime(
                                                                              timeLeft.seconds
                                                                          )}`
                                                                        : item?.current_status === "recruiting"
                                                                        ? "모집 마감"
                                                                        : "캠페인 마감"}
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-2">{<img src={item?.image_data} alt="Dummy Image" />}</div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        );
                    })}
                </Accordion>

                <div className="col-sm-12 text-center mt-5">
                    <Link to="/campaignestimate" className="btn_layout">
                        캠페인 시작하기
                    </Link>
                </div>
            </div>
        </Fragment>
    );
}

export default OngoingCampaign;
