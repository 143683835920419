import React, { Fragment, useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useLocation } from "react-router-dom";
import AxiosPost from "components/untils/AxiosPost";
import { AxiosPosts } from "components/untils/FatchPost";
import Spinner from "react-bootstrap/Spinner";

import InfluencerRecruitmentStatus from "components/CampaignManagement/InfluencerRecruitmentStatus";
import InfluencerSelection from "components/CampaignManagement/InfluencerSelection";
import InfluencerProductShipping from "components/CampaignManagement/InfluencerProductShipping";
import InfluencerPostingStatus from "components/CampaignManagement/InfluencerPostingStatus";
import axios from "axios";

function CampaignManagement() {
    const location = useLocation();
    const campaignId = location?.state?.campaignId;
    const totalCount = location?.state?.totalCount;

    const [influencerList, setInfluencerList] = useState([]);
    const [dataEffect, setDataEffect] = useState(false);
    const [currentType, setCurrentType] = useState(null);
    const [currentEffect, setCurrentEffect] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [getInfo, setGetInfo] = useState(null);
    const [nextEffect, setNextEffect] = useState(false);
    const [loadCurrent, setLoadCurrent] = useState(null);
    const [campaignType, setCampaignType] = useState(null);
    const [preChange, setPreChange] = useState(false);

    useEffect(() => {
        AxiosPost("campaign/getInfo", { campaign_id: campaignId })
            .then((res) => {
                setCurrentType(res?.data?.data?.current_status);
                setLoadCurrent(res?.data?.data?.current_status);
                setCampaignType(res?.data?.data?.campaign_type);
                setGetInfo(res?.data?.data);
                setCurrentEffect(false);
                setIsLoading(false);
                // setNextEffect(false);
            })
            .catch((error) => console.log(error));
    }, [currentEffect, nextEffect]);

    const handleTabChange = (key) => {
        setCurrentType(key);
    };

    const scrollEvt = () => {
        window.scrollTo(0, 0);
    };

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // 만약 첫번째 탭에서 선택값이 없을경우 자동 선택
    // useEffect(() => {
    //     if (totalCount == null || totalCount == undefined) {
    //         console.log("totalCount--", totalCount);
    //         return;
    //     } else {
    //         axios
    //             .post(process.env.REACT_APP_API + "campaign/selectInfluencerList", { campaign_id: campaignId, draft: "Y" })
    //             .then((res) => {
    //                 if (res?.data?.data?.length === 0) {
    //                     setPreChange(true);
    //                     axios.post(process.env.REACT_APP_API + "campaign/preselect", { campaign_id: campaignId, influencer_count: totalCount });
    //                 } else {
    //                     return false;
    //                 }
    //             })
    //             .catch((error) => {
    //                 console.error(error);
    //             });
    //     }
    // }, []);

    useEffect(() => {
        AxiosPosts("campaign/selectInfluencerList", { campaign_id: campaignId })
            .then((res) => {
                // console.log("selectInfluencerList ---- ", res?.data?.data);
                setInfluencerList(res?.data?.data);
                setDataEffect(false);
            })
            .catch((error) => console.log(error));
    }, [dataEffect, preChange]);

    return (
        <Fragment>
            <div className="hr_line"></div>
            <div className="influencer mtb190">
                <div className="container">
                    <h2 className="inner_heading">캠페인 관리</h2>
                    {isLoading ? (
                        <Spinner />
                    ) : (
                        <Tabs activeKey={currentType} onSelect={handleTabChange} id="fill-tab-example" className="mb-1" fill>
                            <Tab
                                eventKey="recruiting"
                                title={
                                    <div className="innertitle">
                                        <span>
                                            <span>1</span>
                                        </span>
                                        인플루언서 모집 현황
                                    </div>
                                }
                            >
                                <InfluencerRecruitmentStatus
                                    datas={influencerList}
                                    campaignId={campaignId}
                                    setCurrentEffect={setCurrentEffect}
                                    setDataEffect={setDataEffect}
                                    setCurrentType={setCurrentType}
                                    getInfo={getInfo}
                                    setNextEffect={setNextEffect}
                                    scrollEvt={scrollEvt}
                                    loadCurrent={loadCurrent}
                                    preChange={preChange}
                                    currentType={currentType}
                                />
                            </Tab>

                            <Tab
                                eventKey="recruitDone"
                                title={
                                    <div className="innertitle">
                                        <span>
                                            <span>2</span>
                                        </span>
                                        인플루언서 선발
                                    </div>
                                }
                            >
                                <InfluencerSelection
                                    datas={influencerList}
                                    campaignId={campaignId}
                                    setCurrentEffect={setCurrentEffect}
                                    setDataEffect={setDataEffect}
                                    setCurrentType={setCurrentType}
                                    getInfo={getInfo}
                                    nextEffect={nextEffect}
                                    setNextEffect={setNextEffect}
                                    scrollEvt={scrollEvt}
                                    currentType={currentType}
                                    loadCurrent={loadCurrent}
                                    campaignType={campaignType}
                                />
                            </Tab>

                            <Tab
                                eventKey="shipping"
                                title={
                                    <div className="innertitle">
                                        <span>
                                            <span>3</span>
                                        </span>
                                        제품 배송정보 입력
                                    </div>
                                }
                                disabled={campaignType !== "productReview"}
                            >
                                <InfluencerProductShipping
                                    datas={influencerList}
                                    campaignId={campaignId}
                                    setCurrentEffect={setCurrentEffect}
                                    setDataEffect={setDataEffect}
                                    setCurrentType={setCurrentType}
                                    nextEffect={nextEffect}
                                    setNextEffect={setNextEffect}
                                    loadCurrent={loadCurrent}
                                    currentType={currentType}
                                />
                            </Tab>

                            <Tab
                                eventKey="posting"
                                title={
                                    <div className="innertitle">
                                        <span>
                                            <span>4</span>
                                        </span>
                                        포스팅 현황
                                    </div>
                                }
                                // disabled={loadCurrent !== "posting"}
                            >
                                <InfluencerPostingStatus campaignId={campaignId} currentType={currentType} loadCurrent={loadCurrent} />
                            </Tab>
                        </Tabs>
                    )}
                </div>
            </div>
        </Fragment>
    );
}

export default CampaignManagement;
