import React, { Fragment, useState, useEffect } from "react";
import BasicProfile from "../../assets/images/profile_ex.png";
import InstaPic from "../../assets/images/img_dummy_pc_160x180.jpg";
import Button from "react-bootstrap/Button";
import AxiosPost from "components/untils/AxiosPost";
import moment from "moment";
import ProductRecall from "components/modals/CampaignManagement/ProductRecall";
import InfluencerEvaluation from "components/modals/CampaignManagement/InfluencerEvaluation";
import { useNavigate } from "react-router-dom";
import { getRandomFallbackImage } from "components/common/common";

function InfluencerPostingStatus({ campaignId, loadCurrent, currentType }) {
    const navigate = useNavigate();
    const [getInfluencerCode, setGetInfluencerCode] = useState(null);
    const [confirmUserCode, setConfirmUserCode] = useState(null);
    const [dataList, setDataList] = useState([]);
    const [changeEvent, setChangeEvent] = useState(false);
    const [recallState, setRecallState] = useState({}); // 회수요청 각모달의 상태
    const [confirmState, setConfirmState] = useState({}); // 평가하기 모달 상태

    const recallHandleButtonClick = (id) => {
        setRecallState((prevStates) => ({
            ...prevStates,
            [id]: true,
        }));
    };

    const recallHandleClose = (id) => {
        setRecallState((prevStates) => ({
            ...prevStates,
            [id]: false,
        }));
    };

    const confirmHandleButtonClick = (id) => {
        setConfirmState((prevStates) => ({
            ...prevStates,
            [id]: true,
        }));
    };

    const confirmHandleClose = (id) => {
        setConfirmState((prevStates) => ({
            ...prevStates,
            [id]: false,
        }));
    };

    useEffect(() => {
        AxiosPost("campaign/postingList", { campaign_id: campaignId })
            .then((res) => {
                // console.log("마지막", res?.data);
                setDataList(res?.data?.data);
                setChangeEvent(false);
                setRecallState({});
            })
            .catch((error) => {
                console.error(error);
            });
    }, [changeEvent]);

    return (
        <Fragment>
            <div className="recuritstatusbody">
                <div className="recuritstatus_detail">
                    <div className="row">
                        {dataList?.map((item) => {
                            return (
                                <div className="col-sm-3" key={item?.iuc_id}>
                                    <div className="influ_posting_status">
                                        <div className="date">
                                            {moment(item?.media_create_at).format("YYYY-MM-DD") !== "Invalid date" ? moment(item?.media_create_at).format("YYYY-MM-DD") : ""}
                                        </div>

                                        <div className="insta_user_info">
                                            <img
                                                src={item?.profile_picture_url !== "except X" && item?.profile_picture_url ? item?.profile_picture_url : BasicProfile}
                                                alt="User Pic"
                                            />
                                            <div className="">
                                                <h3>{item?.name}</h3>
                                                <p>{item?.instagram_username ? "@" + item?.instagram_username : ""}</p>
                                            </div>
                                        </div>

                                        <div className="influ_posting_like_comt">
                                            <a href={item.post_url} target="_blank">
                                                <img
                                                    src={item?.media_url !== null && item?.media_url !== "X" && item?.media_url !== "except X" ? item?.media_url : InstaPic}
                                                    alt=""
                                                />
                                            </a>
                                            <ul>
                                                <li>{item?.like_cnt}</li>
                                                <li>{item?.comment_cnt}</li>
                                            </ul>
                                        </div>

                                        <div className="recuritstatus_detailinfo">
                                            <div className="row">
                                                <div className="col-sm-6 pe-1">
                                                    {item?.return_check === "Y" ? (
                                                        <Button className="btn_layout2 btn_retrival_req">회수 완료</Button>
                                                    ) : (
                                                        <Button
                                                            className="btn_layout2 btn_retrival_req"
                                                            // data-bs-toggle="modal" data-bs-target="#product_recall"
                                                            onClick={() => {
                                                                recallHandleButtonClick(item?.iuc_id);
                                                                setGetInfluencerCode(item?.influencer_id);
                                                            }}
                                                            disabled={loadCurrent === "posting" ? false : true}
                                                        >
                                                            회수 요청
                                                        </Button>
                                                    )}
                                                </div>
                                                <div className="col-sm-6 ps-1">
                                                    {!item?.confirm_satisfaction || item?.confirm_satisfaction === "" ? (
                                                        <Button
                                                            className="btn_layout2 btn_evalute"
                                                            // data-bs-toggle="modal" data-bs-target="#influencer_evelution"
                                                            onClick={() => {
                                                                confirmHandleButtonClick(item?.iuc_id);
                                                                setConfirmUserCode(item?.influencer_id);
                                                            }}
                                                            disabled={loadCurrent === "posting" ? false : true}
                                                        >
                                                            평가 하기
                                                        </Button>
                                                    ) : (
                                                        <Button className="btn_layout2 btn_evalute">평가 완료</Button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ProductRecall
                                        show={recallState[item?.iuc_id]}
                                        handleClose={() => recallHandleClose(item?.iuc_id)}
                                        campaignId={item?.campaign_id}
                                        influencerId={getInfluencerCode}
                                        setChangeEvent={setChangeEvent}
                                    />
                                    <InfluencerEvaluation
                                        show={confirmState[item?.iuc_id]}
                                        handleClose={() => confirmHandleClose(item?.iuc_id)}
                                        campaignId={item?.campaign_id}
                                        influencerId={confirmUserCode}
                                        setChangeEvent={setChangeEvent}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className="col-sm-12 text-center mt-5">
                    <Button className="btn_layout" onClick={() => navigate("/campaignestimate")}>
                        새로운 캠페인 시작하기
                    </Button>
                </div>
            </div>
        </Fragment>
    );
}

export default InfluencerPostingStatus;
