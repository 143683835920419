import React, { Fragment, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import BasicProfile from "../../assets/images/profile_ex.png";
import DummyImg from "../../assets/images/img_dummy_pc_440.jpg";

import { useLocation } from "react-router";
import RadarChart from "components/untils/RadarChart";
import AxiosPost from "components/untils/AxiosPost";
import { FatchPost, AxiosPosts } from "components/untils/FatchPost";
import { selectAgeType } from "components/common/SelectData";
import { getRandomFallbackImage } from "components/common/common";
import {
    addCommas,
    calculateAverage,
    formatInstagramNumber,
    interactionTotal,
    parseInstagramCount,
    processText,
    followerGrahpMake,
    formatInstagramKorean,
} from "components/common/common";

function InfluencerProfile() {
    const location = useLocation();
    const campaignId = location.state.campaignId;
    const influencerId = location.state.influencerId;
    const influDatas = location?.state?.influDatas;
    const getInfo = location?.state?.getInfo;

    const [userInfo, setUserInfo] = useState(null);
    const [updateEffect, setUpdateEffect] = useState(false);
    const [postingList, setPostingList] = useState([]);
    const [instatTotal, setInstaTotal] = useState(null);
    const [minMedia, setMinMedia] = useState(null);
    const [maxMedia, setMaxMedia] = useState(null);

    const handleImgError = (e) => {
        e.target.src = BasicProfile;
    };

    function updateDrafting(result) {
        AxiosPosts("campaign/drafting", {
            campaign_id: campaignId,
            influencer_id: influencerId,
            flag: result,
        }).then((res) => {
            setUpdateEffect(true);
        });
    }

    const influeSelectClick = (selected) => {
        let result = selected === "Y" ? "N" : "Y";

        // if (getInfo?.current_status === "recruitDone") {
        //     if (influDatas?.length === 1) {
        //         alert("최소 프리미언이 필요합니다.");
        //     } else {
        //         updateDrafting(result);
        //     }
        // } else {
        updateDrafting(result);
        // }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await FatchPost("campaign/instaramPosList", { influencer_id: influencerId });
                setPostingList(response?.data?.data?.rows);

                let jsonData = {
                    influencer_id: response?.data?.data?.instarData?.influencer_id,
                    comment_cnt: response?.data?.data?.instarData?.comment_cnt,
                    follow_cnt: parseInstagramCount(response?.data?.data?.instarData?.follow_cnt),
                    follower_cnt: parseInstagramCount(response?.data?.data?.instarData?.follower_cnt),
                    like_cnt: response?.data?.data?.instarData?.like_cnt,
                    media_cnt: response?.data?.data?.instarData?.media_cnt,
                };
                // console.log("jsonData -- ", jsonData);
                setInstaTotal(jsonData);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        AxiosPost("campaign/campaignInfluId", { campaign_id: campaignId, influencer_id: influencerId })
            .then((res) => {
                // console.log("확인", res?.data?.data);
                setUserInfo(res?.data?.data);
                setUpdateEffect(false);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [updateEffect]);

    useEffect(() => {
        AxiosPost("campaign/minmaxMedialist", { influencer_id: influencerId })
            .then((res) => {
                // console.log("유저 리스트", res?.data?.data);
                setMinMedia(res?.data?.data[0]);
                setMaxMedia(res?.data?.data[1]);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    function checkNaN(value) {
        if (isNaN(value)) {
            return 0;
        } else {
            return value;
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Fragment>
            <div className="hr_line"></div>
            <div className="influencer_profile mtb190">
                <div className="container">
                    <h2 className="inner_heading">인플루언서 프로필 및 통계</h2>

                    <div className="influ_prfl_box influ_prfl_box1">
                        <div className="row">
                            <div className="col-sm-4">
                                <h6>기본 프로필</h6>
                                <div className="comn_profl_lyout default_profile">
                                    <div className="user_pic">
                                        <img src={userInfo?.profile_picture_url ? userInfo?.profile_picture_url : BasicProfile} onError={handleImgError} alt="User Pic" />
                                        <h4 className="mt-3">{userInfo?.name}</h4>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <p>팔로워</p>
                                        </div>
                                        <div className="col-sm-6">
                                            <p>
                                                {" "}
                                                <span>{userInfo?.followers_count ? processText(userInfo?.followers_count) : 0}</span>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <p>포스팅</p>
                                        </div>
                                        <div className="col-sm-6">
                                            <p>
                                                {" "}
                                                <span>{instatTotal ? processText(instatTotal?.media_cnt) : 0}</span>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <p>성별</p>
                                        </div>
                                        <div className="col-sm-6">
                                            <p>
                                                <span>{userInfo?.gender}</span>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <p>연령대</p>
                                        </div>
                                        <div className="col-sm-6">
                                            <p>
                                                <span>{selectAgeType(userInfo?.age_group)}</span>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <p>관심사</p>
                                        </div>
                                        <div className="col-sm-6">
                                            <p>
                                                <span>{userInfo?.category}</span>
                                            </p>
                                        </div>
                                    </div>
                                    {userInfo?.most_category !== "" && userInfo?.most_category !== null && (
                                        <div className="ai_box">
                                            <div className="row m-0 ">
                                                <div className="col-sm-6">
                                                    <p>
                                                        <b>AI가 분석한 관심사</b>
                                                    </p>
                                                </div>
                                                <div className="col-sm-6">
                                                    <p>
                                                        <span>{userInfo?.most_category}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* 750*300 */}
                            <div className="col-sm-8">
                                <h6>더프리미언 AI분석</h6>
                                <div className="comn_profl_lyout ai_graph">
                                    <RadarChart userInfo={userInfo} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="influ_prfl_box influ_prfl_box2">
                        <h6>인터렉션 (비즈니스 계정만)</h6>
                        {/* {userInfo?.is_business_account === "N" ? ( */}
                        <div className="business_account">
                            {userInfo?.is_business_account === "N" && (
                                <div className="overlay">
                                    <h1>Coming soon</h1>
                                </div>
                            )}
                            {/* 참고:- 백분율 값이 0이면 "overlay" div가 표시되고 그렇지 않으면 숨겨집니다. */}
                            <div className="row">
                                <div className="col-sm-3">
                                    <div className="comn_profl_lyout text-center">
                                        <h4>평균 참여율</h4>
                                        <div className="percentage_show">
                                            <h1 style={{ color: "#4994fa" }}>
                                                {userInfo?.is_business_account === "Y" && instatTotal
                                                    ? checkNaN(interactionTotal(parseInt(instatTotal?.comment_cnt), parseInt(instatTotal?.like_cnt)))
                                                    : 0}
                                                <span style={{ color: "#4994fa" }}>%</span>
                                            </h1>
                                            <p>(댓글 수 / 좋아요 수의 평균)</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-3">
                                    <div className="comn_profl_lyout text-center">
                                        <h4>평균 도달율</h4>
                                        <div className="percentage_show">
                                            <h1 style={{ color: "#7090ff" }}>
                                                {userInfo?.is_business_account === "Y" && instatTotal
                                                    ? checkNaN(interactionTotal(parseInt(instatTotal?.follow_cnt), parseInt(instatTotal?.follower_cnt)))
                                                    : 0}
                                                <span style={{ color: "#7090ff" }}>%</span>
                                            </h1>
                                            <p>(평균 조회수 / 팔로워 수)</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-3">
                                    <div className="comn_profl_lyout text-center">
                                        <h4>평균 공감율</h4>
                                        <div className="percentage_show">
                                            <h1 style={{ color: "#a190ff" }}>
                                                {userInfo?.is_business_account === "Y" && instatTotal
                                                    ? checkNaN(interactionTotal(parseInt(instatTotal?.follow_cnt), parseInt(instatTotal?.like_cnt)))
                                                    : 0}
                                                <span style={{ color: "#a190ff" }}>%</span>
                                            </h1>
                                            <p>(조회수 / 좋아요 수의 평균)</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-3">
                                    <div className="comn_profl_lyout text-center">
                                        <h4>성장율</h4>
                                        <div className="percentage_show">
                                            <h1 style={{ color: "#b889fd" }}>
                                                {userInfo?.is_business_account === "Y" && instatTotal
                                                    ? // ? calculateAverage(parseInt(instatTotal?.follower_cnt), parseInt(instatTotal?.media_cnt))
                                                      checkNaN(followerGrahpMake(minMedia?.follower_cnt, maxMedia?.follower_cnt, minMedia?.media_cnt, maxMedia?.media_cnt))
                                                    : 0}
                                                <span style={{ color: "#b889fd" }}>%</span>
                                            </h1>
                                            <p>(전월 대비 팔로워수 , 포스팅 수 증가율)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="influ_prfl_box influ_prfl_box3">
                        <div className="row">
                            <div className="col-sm-4">
                                <h6> 최신 포스트</h6>
                                <div className="comn_profl_lyout influ_posting_like_comt">
                                    <img src={postingList && postingList[0]?.media_url !== "except X" ? postingList[0]?.media_url : DummyImg} alt="" />
                                    <ul>
                                        <li>{postingList ? formatInstagramKorean(postingList[0]?.like_cnt) : 0}</li>
                                        <li>{postingList ? formatInstagramKorean(postingList[0]?.comment_cnt) : 0}</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-sm-4">
                                <h6>BEST 좋아요 포스트</h6>
                                <div className="comn_profl_lyout influ_posting_like_comt">
                                    <img src={postingList && postingList[1]?.media_url !== "except X" ? postingList[1]?.media_url : DummyImg} alt="" />
                                    <ul>
                                        <li>{postingList ? formatInstagramKorean(postingList[1]?.like_cnt) : 0}</li>
                                        <li>{postingList ? formatInstagramKorean(postingList[1]?.comment_cnt) : 0}</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-sm-4">
                                <h6>BEST 댓글 포스트</h6>
                                <div className="comn_profl_lyout influ_posting_like_comt">
                                    <img src={postingList && postingList[2]?.media_url !== "except X" ? postingList[2]?.media_url : DummyImg} alt="" />
                                    <ul>
                                        <li>{postingList ? formatInstagramKorean(postingList[2]?.like_cnt) : 0}</li>
                                        <li>{postingList ? formatInstagramKorean(postingList[2]?.comment_cnt) : 0}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {getInfo?.current_status == "recruiting" ||
                        (getInfo?.current_status == "recruitDone" && (
                            <div className="col-sm-12 text-center">
                                {userInfo?.drafting === "Y" ? (
                                    <Button className="btn_layout un_active" onClick={() => influeSelectClick(userInfo?.drafting)}>
                                        선발 취소
                                    </Button>
                                ) : (
                                    <Button className="btn_layout" onClick={() => influeSelectClick(userInfo?.drafting)}>
                                        선발 하기
                                    </Button>
                                )}
                            </div>
                        ))}
                </div>
            </div>
        </Fragment>
    );
}

export default InfluencerProfile;
