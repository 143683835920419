import React, { Fragment, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import BasicProfile from "../../assets/images/profile_ex.png";
import PostingStatusModal from "components/modals/InfluencerPostingStatus/PostingStatusModal";
import AxiosPost from "components/untils/AxiosPost";
import { selectAgeType } from "components/common/SelectData";
import { addCommas, formatInstagramNumber, processText, formatInstagram } from "components/common/common";
import { getRandomFallbackImage } from "components/common/common";

function InfluencerSelection({ datas, campaignId, setDataEffect, setCurrentEffect, getInfo, nextEffect, setNextEffect, scrollEvt, currentType, loadCurrent, campaignType }) {
    const [influDatas, setInfluDatas] = useState([]); // 선발된 인플루언서 - 페이지 로드 후 get
    const [selectNotNull, setSelectNotNull] = useState(false);
    const [currentDate, setCurrentDate] = useState(new Date()); // 현재 날짜
    const [dbDate, setDbDate] = useState(new Date(getInfo?.end_date));
    const [isDateAfterCurrent, setIsDateAfterCurrent] = useState(false); // 현재 날짜와 비교
    const [loadGetInfluencer, setLoadGetInfluencer] = useState([]); // 다시 불러온 선발된 인플루언서
    const [showModal, setShowModal] = useState(false); // 모달
    const handleClose = () => setShowModal(false);
    const navigate = useNavigate();

    // 인플루언서 선발 하기 버튼 이벤트
    const influeSelectClick = (influencerSeq, selected) => {
        let result = selected === "Y" ? "N" : "Y";

        AxiosPost("campaign/drafting", {
            campaign_id: campaignId,
            influencer_id: influencerSeq,
            flag: result,
        }).then((res) => {
            if (res?.data?.result === "success") {
                setDataEffect(true);
                setSelectNotNull(true);
            }
        });
    };

    // 상품 리뷰가 아닐때 포스팅 현황으로 업데이트 버튼 이벤트
    const updateStatusEvt = () => {
        influDatas.forEach((item) => {
            AxiosPost("campaign/selectInfluencer", { campaign_id: campaignId, influencer_id: item.influencer_id });
        });
        AxiosPost("campaign/updateStatus", { campaign_id: campaignId, currentStatus: "posting" }).then((res) => {
            if (res?.data?.result === "success") {
                setCurrentEffect(true);
                setNextEffect(true);
                scrollEvt();
            }
        });
    };
    // 확정 버튼 클릭시 다시한번 인플루언서 체크
    const agreementLoad = () => {
        return new Promise((resolve, reject) => {
            AxiosPost("campaign/selectInfluencerList", { campaign_id: campaignId, draft: "Y" })
                .then((res) => {
                    setLoadGetInfluencer(res?.data?.data);
                    if (influDatas === null || influDatas?.length === 0) {
                        resolve(false);
                        alert("최소 1명의 인플루언서를 선발해 주세요. ");
                    } else if (res?.data?.data?.length !== influDatas?.length) {
                        resolve(false);
                        window.location.reload();
                    } else if (res?.data?.data === null || res?.data?.data?.length === 0) {
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        });
    };

    // 상품리뷰 - 모달 오픈 여부
    const choiceOpenModal = () => {
        agreementLoad().then((result) => {
            if (result === true) {
                setShowModal(true);
            } else {
                return false;
            }
        });
    };

    const saveNextBtn = (e) => {
        agreementLoad().then((result) => {
            if (result === true) {
                updateStatusEvt();
            } else {
                return false;
            }
        });
    };

    useEffect(() => {
        AxiosPost("campaign/selectInfluencerList", { campaign_id: campaignId, draft: "Y" }).then((res) => {
            // console.log("!!", res?.data?.data);
            setInfluDatas(res?.data?.data);
            setSelectNotNull(false);
        });
    }, [selectNotNull, nextEffect, currentType]);

    useEffect(() => {
        const currentDate = new Date(); // 현재 날짜
        const dbDateString = dbDate?.toLocaleDateString(); // db에 있는 날짜
        const currentDateString = currentDate?.toLocaleDateString(); // 현재 날짜
        const isAfter = currentDate >= dbDate || currentDateString === dbDateString;
        setIsDateAfterCurrent(isAfter);
    }, [dbDate]);

    return (
        <Fragment>
            <div className="recuritstatusbody">
                <div className="recuritstatus_detail recuritstatus_detail1">
                    <div className="row">
                        {influDatas &&
                            influDatas?.map((item) => {
                                return (
                                    <div className="col-sm-3" key={item.influencer_id}>
                                        <div className="recuritstatus_box influe_selected">
                                            <div className="row recuritstatus_profile_info ">
                                                <div className="col-md-4 p-0">
                                                    <img
                                                        src={item?.profile_picture_url !== "except X" && item?.profile_picture_url ? item?.profile_picture_url : BasicProfile}
                                                        alt="User Pic"
                                                    />
                                                </div>
                                                <div className="col-md-8">
                                                    <h3>
                                                        {item?.name}
                                                        <span>/{item?.gender === "여성" ? "여" : "남"}</span>
                                                    </h3>
                                                    <p>@{item?.instagram_username}</p>
                                                </div>
                                            </div>

                                            <div className="btn_iconss ">
                                                <div className="select_confmd">
                                                    선발
                                                    <br />
                                                    확정
                                                </div>
                                                <div className="percent">
                                                    {item?.matchPercenTage}
                                                    <span>%</span>
                                                </div>
                                            </div>

                                            <div className="recuritstatus_detailinfo  mt-3">
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <p>팔로워</p>
                                                        <p>
                                                            <span>{item?.follower_count ? formatInstagram(item?.follower_count) : 0}</span>
                                                        </p>
                                                    </div>

                                                    <div className="col-sm-4">
                                                        <p>포스팅</p>
                                                        <p>
                                                            <span>{item?.media_count ? formatInstagram(item?.media_count) : 0}</span>
                                                        </p>
                                                    </div>

                                                    <div className="col-sm-4">
                                                        <p>연령대</p>
                                                        <p>
                                                            <span>{selectAgeType(item?.age_group)}</span>
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="ai_tech">
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <p>관심사</p>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <p>
                                                                <span>{item?.category}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    {item?.most_category !== null && item?.most_category !== "" && (
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <p>AI분석 관심사</p>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <p>
                                                                    <span>{item?.most_category}</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-6 pe-1 ">
                                                        <Button
                                                            onClick={() => {
                                                                navigate(`/influencerprofile/${campaignId}/${item?.influencer_id}`, {
                                                                    state: {
                                                                        campaignId: campaignId,
                                                                        influencerId: item.influencer_id,
                                                                        influDatas: influDatas,
                                                                        getInfo: getInfo,
                                                                    },
                                                                });
                                                            }}
                                                            className="btn_layout2 btn btn-primary"
                                                        >
                                                            프로필 보기
                                                        </Button>
                                                    </div>
                                                    <div className="col-sm-6 ps-1">
                                                        {item?.drafting === "N" ? (
                                                            <Button
                                                                className="btn_layout2 bg_skyblue"
                                                                onClick={() => influeSelectClick(item?.influencer_id, item?.drafting)}
                                                                disabled={loadCurrent === "shipping" || loadCurrent === "posting"}
                                                            >
                                                                선발 하기
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                className="btn_layout2 bg_grey"
                                                                onClick={() => influeSelectClick(item?.influencer_id, item?.drafting)}
                                                                disabled={loadCurrent === "shipping" || loadCurrent === "posting"}
                                                            >
                                                                선발 취소
                                                            </Button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>

                <div className="col-sm-12 text-center mt-5">
                    {campaignType === "productReview" ? (
                        <Button
                            className={`btn_layout ${isDateAfterCurrent ? "" : "un_active"}`}
                            onClick={choiceOpenModal}
                            disabled={!isDateAfterCurrent || loadCurrent !== "recruitDone"}
                        >
                            선발 확정
                        </Button>
                    ) : (
                        <Button
                            className={`btn_layout ${isDateAfterCurrent ? "" : "un_active"}`}
                            onClick={saveNextBtn}
                            disabled={!isDateAfterCurrent || loadCurrent !== "recruitDone"}
                        >
                            선발 확정
                        </Button>
                    )}
                </div>
            </div>
            <PostingStatusModal
                show={showModal}
                handleClose={handleClose}
                loadCurrent={loadCurrent}
                datas={datas}
                getInfo={getInfo}
                campaignId={campaignId}
                setCurrentEffect={setCurrentEffect}
                influDatas={influDatas}
                setNextEffect={setNextEffect}
                scrollEvt={scrollEvt}
                campaignType={campaignType}
            />
        </Fragment>
    );
}

export default InfluencerSelection;
