import React, { Fragment, useState } from "react";
import Button from "react-bootstrap/Button";
import LoginModal from "components/LoginModal/LoginModal";

function FindIdSuccess({ datas }) {
    const [modalShow, setModalShow] = useState(false);
    const handleClose = () => setModalShow(false);
    // console.log("datas -- ", datas.length);
    return (
        <Fragment>
            <LoginModal show={modalShow} handleClose={handleClose} />
            <div className="findresult_panel">
                <div className="findresults">
                    <p>더프리미언에 등록된 아이디는 총 {datas?.length}개입니다.</p>
                    {datas?.map((data) => {
                        return (
                            <div className="row ">
                                <p className="col-sm-4 col-form-label text-end pe-2">{data?.manager_name}</p>
                                <p className="col-sm-8  col-form-label text-start ps-2">{data?.email}</p>
                            </div>
                        );
                    })}
                </div>
                <div className="row mb-3">
                    <div className="col-sm-2"></div>
                    <div className="col-sm-8 text-center">
                        {" "}
                        <Button type="submit" className="btn_layout" onClick={() => setModalShow(true)}>
                            로그인{" "}
                        </Button>
                    </div>
                    <div className="col-sm-2"></div>
                </div>
            </div>
        </Fragment>
    );
}

export default FindIdSuccess;
