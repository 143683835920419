import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import FindIdSuccess from "components/User/FindIdSuccess";
import FindIdFail from "components/User/FindIdFail";
import FindPwdReset from "components/User/FindPwdReset";
import { Fragment } from "react";
import { useLocation } from "react-router-dom";

function TabFindResult() {
    const location = useLocation();
    const result = location?.state?.result;
    const idDatas = location?.state?.data;
    const pwdDatas = location?.state?.pwdData;
    const eventKey = location?.state?.eventKey;
    return (
        <Fragment>
            <div className="tab_panel mt100 mb100">
                <div className="container">
                    <Tabs defaultActiveKey={eventKey} id="fill-tab-example" className="mb-3" fill>
                        <Tab eventKey="findid" title="아이디 찾기">
                            {result === "success" ? <FindIdSuccess datas={idDatas} /> : <FindIdFail />}
                        </Tab>

                        <Tab eventKey="findpwd" title="비밀번호 찾기">
                            {result === "success" ? <FindPwdReset datas={pwdDatas} /> : <FindIdFail />}
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </Fragment>
    );
}

export default TabFindResult;
