import React, { Fragment, useEffect, useState } from "react";
import ImageProduct from "components/ImageProduct/ImageProduct";
import Button from "react-bootstrap/Button";
import { useLocation, useNavigate, Link } from "react-router-dom";
import AxiosPost from "components/untils/AxiosPost";
import { selectCampaignType, selectChannelValue, selectGenderType, selectAgeType, selectMethodType } from "components/common/SelectData";
import moment from "moment";
import { addCommas } from "components/common/common";

function CampaignDetail() {
    const location = useLocation();
    const navigate = useNavigate();
    const campaignId = location.state.campaignId;

    const [campaignInfo, setCampaignInfo] = useState(null);
    const [pictureImgs, setPictureImgs] = useState([]);
    const [ageArr, setArr] = useState([]);

    // 진행 상태 구별
    const selectProcessType = (campaignType, startDate) => {
        let today = moment().format("YYYY-MM-DD");
        let start_date = moment(startDate).format("YYYY-MM-DD");
        let result = "";
        if (campaignType === "recruiting" || campaignType === "recruitDone") {
            result = "모집중";
        } else if (campaignType === "posting" && start_date > today) {
            result = "모집마감";
        } else if (campaignType === "shipping" && start_date > today) {
            result = "모집마감";
        } else if (campaignType === "shipping") {
            result = "배송중";
        } else if (campaignType === "posting") {
            result = "포스팅 현황";
        } else if (campaignType === "complete") {
            result = "캠페인 완료";
        }
        return result;
    };

    const selectProcessOnClick = (campaignType) => {
        if (campaignType === "complete") {
            navigate(`/mycampaign`, {
                state: {
                    type: "complete",
                },
            });
        } else {
            navigate(`/influencermanagement/${campaignId}`, {
                state: {
                    campaignId: campaignId,
                },
            });
        }
    };

    // 객체 데이터
    const renderList = () => {
        let number = 1;
        const data = [
            { methodType: selectMethodType(campaignInfo?.sponsor_method) },
            { campaignUrl: campaignInfo?.campaign_url },
            { address: campaignInfo?.campaign_address },
            { visitMsg: campaignInfo?.visit_message },
            { preference: campaignInfo?.photography_preference },
            { explanation: campaignInfo?.explanation },
            { hasTags: campaignInfo?.hashtag1 },
        ];
        const hashTagArr = [{ hashTag: campaignInfo?.hashtag1 }, { hashTag: campaignInfo?.hashtag2 }, { hashTag: campaignInfo?.hashtag3 }, { hashTag: campaignInfo?.hashtag4 }];

        const hashResult = hashTagArr
            .filter((item) => item.hashTag !== "")
            .map((item) => `#${item.hashTag}`)
            .join(" ");
        return (
            <>
                {data.map((item, index) => {
                    const key = Object.keys(item)[0];
                    const value = item[key];
                    const isLastItem = index === data.length - 1;

                    if (value) {
                        return (
                            <li key={index}>
                                {isLastItem ? (
                                    <>
                                        {" "}
                                        {`${number++}.`} <span className="color_blue">#광고</span> {`${hashResult}`}{" "}
                                    </>
                                ) : (
                                    `${number++}. ${value}`
                                )}
                            </li>
                        );
                    } else {
                        return null;
                    }
                })}
            </>
        );
    };

    useEffect(() => {
        AxiosPost("campaign/getCampaignInfo", {
            campaign_id: campaignId,
        })
            .then((res) => {
                setCampaignInfo(res?.data?.data?.rows);
                setPictureImgs(res?.data?.data?.pictureImgs);
                setArr(selectAgeType(res?.data?.data?.rows?.age_condition).join(", "));
            })
            .catch((error) => console.log(error));
    }, []);
    return (
        <Fragment>
            <div className="hr_line"></div>
            <div className="dashboard mtb190">
                <div className="container">
                    <h2 className="inner_heading">캠페인 자세히 보기</h2>

                    <div className="dashbrd_uprinfo">
                        <div className="row">
                            <div className="col-sm-5">
                                <ImageProduct pictureImgs={pictureImgs} />
                            </div>
                            <div className="col-sm-7 padding_left_30">
                                <div className="post_recruite recruite text-end">
                                    {" "}
                                    {/* 게시 페이지에 대해 "recruit" className 대신 "post" className 추가 */}
                                    <Button onClick={() => selectProcessOnClick(campaignInfo?.current_status)}>
                                        {selectProcessType(campaignInfo?.current_status, campaignInfo?.campaign_start_date)}
                                    </Button>
                                </div>
                                <h3>
                                    <span>{campaignInfo?.product_name}</span>
                                    {campaignInfo?.campaign_name}
                                </h3>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: campaignInfo?.introduction,
                                    }}
                                ></p>

                                <div className="product_info">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <p>
                                                <span>캠페인 번호 No.</span>
                                            </p>
                                        </div>
                                        <div className="col-sm-9">
                                            <p>{campaignInfo?.campaign_id}</p>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <p>
                                                <span>캠페인 유형</span>
                                            </p>
                                        </div>
                                        <div className="col-sm-9">
                                            <p>{selectCampaignType(campaignInfo?.campaign_type)}</p>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <p>
                                                <span>등록일자</span>
                                            </p>
                                        </div>
                                        <div className="col-sm-9">
                                            <p>{moment(campaignInfo?.create_at).format("YYYY-MM-DD")}</p>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <p>
                                                <span>모집마감</span>
                                            </p>
                                        </div>
                                        <div className="col-sm-9">
                                            <p>{moment(campaignInfo?.influencer_end_date).format("YYYY-MM-DD")}</p>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <p>
                                                <span>포스팅기간</span>
                                            </p>
                                        </div>
                                        <div className="col-sm-9">
                                            <p>
                                                {moment(campaignInfo?.campaign_start_date).format("YYYY-MM-DD")} -&nbsp;
                                                {moment(campaignInfo?.campaign_end_date).format("YYYY-MM-DD")}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <p>
                                                <span>모집인원</span>
                                            </p>
                                        </div>
                                        <div className="col-sm-9">
                                            <p>{addCommas(campaignInfo?.total_count)}명</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="dashbrd_lwrinfo">
                        <div className="row  row-flex">
                            <div className="col-sm-6">
                                <div className="dashbrd_lwrinfo_detail">
                                    <h4>인플루언서 선정 기준</h4>
                                    <ul>
                                        <li>1. {campaignInfo?.category} 인플루언서</li>
                                        <li>2. {selectChannelValue(campaignInfo?.campaign_sns)}</li>
                                        <li>
                                            3. 연령 {ageArr} / 성별 {selectGenderType(campaignInfo?.gender_condition)}
                                            {campaignInfo?.region_condition ? `/ 지역 ${campaignInfo?.region_condition}` : null}
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="dashbrd_lwrinfo_detail">
                                    <h4>포스팅 가이드</h4>
                                    <ul>{renderList()}</ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 text-center">
                        <Link to="/mycampaign" className="btn_layout">
                            캠페인 리스트
                        </Link>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default CampaignDetail;
