export const checkBoxDatas = [
    { id: 1, label: "뷰티" },
    { id: 2, label: "패션" },
    { id: 3, label: "핫플레이스" },
    { id: 4, label: "맛집" },
    { id: 5, label: "여행" },
    { id: 6, label: "인테리어" },
    { id: 7, label: "셀럽" },
    { id: 8, label: "육아" },
    { id: 9, label: "IT" },
    { id: 10, label: "자동차" },
    { id: 11, label: "피트니스" },
    { id: 12, label: "반려동물" },
    { id: 13, label: "요리" },
    { id: 14, label: "스포츠" },
    { id: 15, label: "게임" },
];
