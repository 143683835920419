import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import AuthTimer from "components/untils/AuthTimer";
import { Fragment } from "react";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { ErrorCodeList } from "components/common/errorCodeList";

function FindPwd() {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        watch,
        trigger,
        getValues,
        formState: { errors, isSubmitting, isDirty, isValid },
    } = useForm({ mode: "onSubmit" });
    const watchUserEmail = watch("userEmail");
    const watchUserName = watch("userName");
    const watchUserPhone = watch("userPhone");

    const [phoneNum, setPhoneNum] = useState("");
    const [authCheck, setAuthCheck] = useState(false);
    const [minutes, setMinutes] = useState("");
    const [seconds, setSeconds] = useState("");
    const [timeState, setTimeState] = useState(false);
    const [sendCnt, setSendCnt] = useState(1);
    const [sendCheck, setSendCheck] = useState(false);
    const [authNum, setAuthNum] = useState("");
    const [authResult, setAuthResult] = useState(false);

    const [inputValues, setInputValues] = useState({
        userPhone: "",
        authNumber: "",
    });
    const handleInputChange = (event) => {
        setInputValues({
            ...inputValues,
            [event.target.name]: event.target.value,
        });
    };

    const sendSmsBtn = () => {
        // setSendCnt((prevCount) => prevCount + 1);
        // if (sendCnt > 2) {
        //     alert("인증 번호 2회 전송은 1분후 재시도 가능합니다.");
        //     setSendCheck(true);
        // } else {
        axios.post(process.env.REACT_APP_API + "auth/sendSMS", { phoneNumber: phoneNum }).then((res) => {
            if (res?.data?.result === "success") {
                setMinutes(3);
                setSeconds("00");
                setTimeState(true);
                alert(res.data.message);
            } else {
                setTimeState(false);
                alert(res.data.message);
            }
        });
        // }
    };

    const checkBtn = () => {
        if (!authNum) {
            alert(ErrorCodeList.msg704);
        } else {
            axios.post(process.env.REACT_APP_API + "auth/verifySms", { phoneNumber: phoneNum, verifyNumber: authNum }).then((res) => {
                if (res?.data?.result === "success") {
                    alert(res?.data?.message);
                    setTimeState(false);
                    setAuthResult(true);
                } else {
                    alert(res?.data?.message);
                    setAuthResult(false);
                }
            });
        }
    };

    const onSubmit = (data) => {
        if (authResult) {
            axios
                .post(process.env.REACT_APP_API + "user/findId", {
                    managerName: data.userName,
                    managerContact: data.userPhone,
                    userEmail: data.userEmail,
                })
                .then((res) => {
                    console.log("유저 비번 찾기", res?.data);
                    if (res?.data?.result === "success") {
                        navigate("/find/result", {
                            state: {
                                result: res?.data?.result,
                                pwdData: res?.data?.data[0],
                                eventKey: "findpwd",
                            },
                        });
                    } else {
                        alert("존재하지 않는 ID입니다. ID(이메일) 및 이름을 확인해 주세요.");
                    }
                });
        }
    };

    const onError = (errors) => {
        console.log("error", errors);
    };

    useEffect(() => {
        const countdown = setInterval(() => {
            if (sendCheck === true) {
                if (parseInt(seconds) > 0) {
                    setSeconds(parseInt(seconds) - 1);
                }
                if (parseInt(seconds) === 0) {
                    if (parseInt(minutes) === 0) {
                        clearInterval(countdown);
                        setSendCheck(false);
                        setSendCnt(1);
                    } else {
                        setMinutes(parseInt(minutes) - 1);
                        setSeconds(59);
                    }
                }
            } else {
                clearInterval(countdown);
            }
        }, 1000);
        return () => clearInterval(countdown);
    }, [minutes, seconds]);

    const smsSendCheckBtn = () => {
        const isValidPhone = /^010\d{7,8}$/.test(watchUserPhone);
        console.log(isValidPhone);
        if (watchUserEmail === "") {
            alert("이메일을 입력해주세요.");
        } else if (watchUserName === "") {
            alert("이름을 입력해주세요.");
        } else if (watchUserPhone === "") {
            alert("전화번호를 입력해주세요.");
        } else if (!isValidPhone) {
            alert("전화번호를 확인해 주세요.");
        } else {
            // alert("인증 번호 전송");
            sendSmsBtn();
        }
        // sendSmsBtn()
    };

    useEffect(() => {
        const countdown = setInterval(() => {
            if (timeState === true) {
                if (parseInt(seconds) > 0) {
                    setSeconds(parseInt(seconds) - 1);
                }
                if (parseInt(seconds) === 0) {
                    if (parseInt(minutes) === 0) {
                        clearInterval(countdown);
                        setTimeState(false);
                    } else {
                        setMinutes(parseInt(minutes) - 1);
                        setSeconds(59);
                    }
                }
            } else {
                clearInterval(countdown);
            }
        }, 1000);
        return () => clearInterval(countdown);
    }, [minutes, seconds]);

    return (
        <Fragment>
            <div className="findpwd_panel">
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <div className="row mb-3">
                        <div className="col-sm-12">
                            <label className="col-sm-12 col-form-label">이메일</label>
                            <input
                                type="email"
                                className="form-control"
                                name="userEmail"
                                {...register("userEmail", {
                                    required: true,
                                })}
                            />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-sm-12">
                            <label className="col-sm-12 col-form-label">이름</label>
                            <input
                                type="text"
                                className="form-control"
                                name="userName"
                                {...register("userName", {
                                    required: true,
                                })}
                            />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-sm-12">
                            <label className="col-sm-12 col-form-label ">휴대폰 번호</label>
                            <div className="input-group msg_time">
                                <input
                                    type="number"
                                    min="1"
                                    className="form-control"
                                    aria-describedby="userPhone"
                                    name="userPhone"
                                    value={inputValues.userPhone || ""}
                                    {...register("userPhone", {
                                        onChange: (e) => {
                                            setPhoneNum(e.target.value);
                                            handleInputChange(e);
                                        },
                                        required: true,
                                    })}
                                />
                                <span
                                    className={`input-group-text button  btn btn-primary ${inputValues.userPhone.length > 9 ? "active" : ""}`}
                                    id="userPhone"
                                    onClick={() => smsSendCheckBtn()}
                                >
                                    인증번호 발송
                                </span>
                                {authCheck === false && <AuthTimer minutes={minutes} seconds={seconds} timeState={timeState} className="time" />}
                            </div>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-sm-12 ">
                            <label className="col-sm-12 col-form-label">인증번호</label>
                            <div className="input-group mb-3">
                                <input
                                    type="number"
                                    min="1"
                                    className="form-control"
                                    aria-describedby="numberr"
                                    name="authNumber"
                                    value={inputValues.authNumber || ""}
                                    {...register("authNumber", {
                                        required: true,
                                        onChange: (e) => {
                                            setAuthNum(e.target.value);
                                            handleInputChange(e);
                                        },
                                    })}
                                />
                                <span
                                    className={`input-group-text button  btn btn-primary  ${inputValues.authNumber.length > 4 ? "active" : ""}`}
                                    id="numberr"
                                    onClick={() => checkBtn()}
                                >
                                    인증
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="col-sm-12">
                            {" "}
                            <Button type="submit" className={`btn_layout width_100 ${authResult ? "" : "un_active"} `}>
                                인증 확인
                            </Button>
                        </div>
                    </div>
                </form>
            </div>

            {/* 비밀번호 변경 ChangePassword panel Start */}
            {/* <div className="findpwd_panel">
                <form action="">
                    <div className="row mb-3">
                        <label className="col-sm-3 col-form-label text-end">새 비밀번호</label>
                        <div className="col-sm-9">
                            <input type="password" className="form-control" name="userpassword" />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label className="col-sm-3 col-form-label text-end">비밀번호 확인</label>
                        <div className="col-sm-9">
                            <input type="password" className="form-control" name="verifypwd" />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-sm-2"></div>
                        <div className="col-sm-9">  <Button type="submit" className=" width_100 btn btn-primary">비밀번호 변경</Button></div>
                        <div className="col-sm-1"></div>
                    </div>
                </form>
            </div> */}
            {/* End */}
        </Fragment>
    );
}

export default FindPwd;
