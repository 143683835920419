import React, { Fragment, useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { useDaumPostcodePopup } from "react-daum-postcode";
const CURRENT_URL = "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
import { handleMultiImageChange } from "components/untils/UploadImg";
import DropZoneList from "components/untils/DropZoneList";

function VisitReviewMoreInfo({ setActiveTab, jsonData, setJsonData }) {
    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({
        mode: "onChange",
        defaultValues: {
            campaign_name: jsonData?.campaign_name,
            additional_influencer_info: jsonData?.additional_influencer_info,
            address_detail: jsonData?.sponsor_method?.campaign_address_detail,
            explanation: jsonData?.sponsor_method?.explanation,
            visit_message: jsonData?.sponsor_method?.visit_message,
            campaign_url: jsonData?.campaign_url,
            introduction: jsonData?.introduction,
        },
    });
    const [addressInput, setAddressInput] = useState(jsonData?.sponsor_method?.campaign_address ? jsonData?.sponsor_method?.campaign_address : "");

    // 이미지 json 전달
    const [selectFiles, setSelectFiles] = useState([]);
    // 이미지  dropzone 전달
    const [imageFiles, setImageFiles] = useState(jsonData?.campaign_img_previews);

    // 주소찾기 API
    const open = useDaumPostcodePopup(CURRENT_URL);
    const onClickHandler = () => {
        open({ onComplete: handleComplete });
    };

    const handleComplete = (data) => {
        let fullAddress = data.address;
        let extraAddress = "";

        if (data.addressType === "R") {
            if (data.bname !== "") {
                extraAddress += data.bname;
            }
            if (data.buildingName !== "") {
                extraAddress += extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
            }
            fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
        }
        setAddressInput(data.address);
    };

    const onSubmit = (data) => {
        if (!addressInput) {
            alert("주소를 입력하여주세요.");
            return false;
        } else {
            let setData = {
                campaign_name: data.campaign_name,
                additional_influencer_info: data.additional_influencer_info,
                sponsor_method: {
                    // sponsor_method: "",
                    campaign_address: addressInput,
                    campaign_address_detail: data.address_detail,
                    explanation: data.explanation,
                    visit_message: data.visit_message,
                },
                campaign_images: selectFiles,
                campaign_img_previews: imageFiles,
                campaign_url: data.campaign_url,
                introduction: data.introduction,
                reservation_text: data.reservation_text == true ? "Y" : "",
                tabLevel: "postguide",
            };
            let tempJson = jsonData;
            for (var key in setData) {
                tempJson[key] = setData[key];
            }
            setJsonData(tempJson);
            setActiveTab("postguide");
        }
    };

    const onError = (data) => {
        console.log("onError --- ", data);
    };

    return (
        <Fragment>
            <div className="basic_register_campaign  common_review">
                <div className="container">
                    <form onSubmit={handleSubmit(onSubmit, onError)}>
                        <div className="row mb-7">
                            <div className="col-sm-12">
                                <label className="form-label">
                                    <span className="numbers">01</span> 캠페인 제목을 입력해 주세요* (항목은 APP에서 프리미언에게 보여지는 제목입니다.)
                                    <span className="required_field">*</span>
                                </label>
                            </div>
                            <div className="col-sm-12">
                                <div className=" input-group">
                                    <input className="form-control" placeholder="50자 이내로 입력해 주세요" {...register("campaign_name", { required: true })} />
                                </div>
                            </div>
                        </div>

                        <div className="row mb-7">
                            <div className="col-sm-12">
                                <label className="form-label">
                                    <span className="numbers">02</span>프리미언(인플루언서)에게 제공할 사항을 자세히 알려주세요.
                                    <br />
                                    제외되는 항목이 있다면 꼭, 함께 알려주세요
                                    <span className="required_field">*</span>
                                </label>
                            </div>
                            <div className="col-sm-12">
                                <div className=" input-group">
                                    <textarea
                                        className="form-control"
                                        rows="3"
                                        placeholder="20,000원 상당의 식음료 사용권
                                        주류제외"
                                        {...register("additional_influencer_info", {
                                            required: true,
                                        })}
                                    ></textarea>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-7">
                            <div className="col-sm-12">
                                <label className="form-label">
                                    <span className="numbers">03</span>프리미언이 방문할 장소의 정확한 주소를 알려주세요
                                    <span className="required_field">*</span>
                                </label>
                            </div>
                            <div className="col-sm-12">
                                <div className=" input-group">
                                    <div className="row width_100">
                                        <div className="col-sm-2">
                                            <Button className="width_100 un_active" onClick={onClickHandler} style={{ position: "initial", padding: "15px" }}>
                                                {" "}
                                                주소 찾기
                                            </Button>
                                        </div>
                                        <div className="col-sm-10">
                                            <input className="form-control" defaultValue={addressInput} readOnly />
                                        </div>

                                        <div className="col-sm-12 mt-2">
                                            <input className="form-control" placeholder="상세 주소를 입력하세요" {...register("address_detail")} />
                                        </div>
                                        <div className="col-sm-12 mt-2">
                                            <textarea
                                                className="form-control"
                                                rows="3"
                                                placeholder="추가 설명 창 (프리미언이 장소를 쉽게 찾을 수 있도록 필요한 경우 자세한 위치를 설명해 주세요. 
                                                            예시: 1층 뒷쪽 호숫가에 있어요.)"
                                                {...register("explanation")}
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-7">
                            <div className="col-sm-12">
                                <label className="form-label">
                                    <span className="numbers">04</span>프리미언이 방문할 수 있는 날짜와 시간, 방문시 유의사항을 자세히 알려주세요
                                    <span className="required_field">*</span>
                                </label>
                            </div>
                            <div className="col-sm-12">
                                <div className=" input-group">
                                    <textarea
                                        className="form-control"
                                        rows="3"
                                        placeholder="월요일 휴무. 화/수/목 방문 가능. 붐비는 시간인 5~8시 방문 제외.
                                                    최소 방문 1~2일 전 체험권 내 연락처로 예약 필수 / 당일 예약 불가."
                                        {...register("visit_message", { required: true })}
                                    ></textarea>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-check  " style={{ marginTop: "7px" }}>
                                    <input className="form-check-input" type="checkbox" id="reservation_text" {...register("reservation_text")} />
                                    <label className="form-check-label" style={{ marginTop: "5px" }} htmlFor={"reservation_text"}>
                                        최소 방문 1~2일 전 체험권 내 연락처로 예약 필수 / 당일 예약 불가.
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-7">
                            <div className="col-sm-12">
                                <label className="form-label">
                                    <span className="numbers">05</span>상품, 서비스, 브랜드를 잘 알 수 있는 SNS 링크 또는 홈페이지 주소를 알려주세요{" "}
                                </label>
                            </div>
                            <div className="col-sm-12">
                                <div className=" input-group">
                                    <input className="form-control" placeholder="50자 이내로 입력해 주세요" {...register("campaign_url")} />
                                </div>
                            </div>
                        </div>

                        <div className="row mb-7">
                            <div className="col-sm-12">
                                <label className="form-label">
                                    <span className="numbers">06</span> 캠페인의 이해를 도와줄 수 있는 소개 이미지를 업로드해 주세요{" "}
                                </label>
                            </div>
                            <div className="col-sm-12">
                                <DropZoneList imageFiles={imageFiles} setImageFiles={setImageFiles} setSelectFiles={setSelectFiles} />
                            </div>
                        </div>

                        <div className="row mb-7">
                            <div className="col-sm-12">
                                <label className="form-label">
                                    <span className="numbers">07</span> 캠페인을 자유롭게 소개해 주세요.<span className="required_field">*</span>
                                </label>
                            </div>
                            <div className="col-sm-12">
                                <div className=" input-group">
                                    <textarea
                                        className="form-control"
                                        placeholder="- 4줄 이하로 간단하게 작성해 주세요.
                                        -브랜드 및 상품에 대한 주요 장점을 알려주세요.
                                        -이번 캠페인의 중요 마케팅 포인트를 알려주세요."
                                        rows="6"
                                        {...register("introduction", { required: true })}
                                    ></textarea>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-sm-12 text-center">
                                <Button type="submit" className={`btn_layout ${isValid ? "" : "un_active"}`}>
                                    포스팅 가이드 입력하기
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    );
}

export default VisitReviewMoreInfo;
