export const ErrorCodeList = {
    // HTTP 응답 코드
    msg200: "요청에 성공하였습니다.",
    msg201: "요청이 성공적이었으며 그 결과로 새로운 리소스가 생성되었습니다.",
    msg400: "올바르지 않은 요청입니다.",
    msg401: "비승인 요청입니다.",
    msg403: "권한이 없습니다.",
    msg404: "페이지를 찾을 수 없습니다.",
    msg405: "허가되지 않은 접근 입니다",
    msg408: "요청중 시간이 초과되었습니다.",
    msg500: "서버의 문제로 요청이 실패 하였습니다.",
    msg502: "게이트웨이가 연결된 서버에서 잘못된 응답을 받았습니다.",
    msg504: "게이트웨이가 연결된 서버에서 응답을 받지 못했습니다.",
    // ADMIN 관련 코드
    msg600: "로그인에 성공 하였습니다.",
    msg601: "ID(이메일) 혹은 비밀번호를 다시 확인해 주세요.",
    msg602: "비밀번호 변경에 성공하였습니다.",
    msg603: "비밀번호는 8~16 자리로 설정해주세요.",
    msg604: "숫자,문자,특수문자를 포함하여 설정해주세요.",
    msg605: "이름, 아이디, 동일문자 3개이상, 연속문자 3개 이상은 사용하실 수 없습니다.",
    msg606: "직전 비밀번호로 변경이 불가능합니다.",
    msg607: "운영자 등록이 성공하였습니다.",
    msg608: "운영자 등록이 실패하였습니다.",
    msg609: "공지사항 등록이 성공하였습니다.",
    msg610: "공지사항 등록이 실패하였습니다.",
    msg611: "비밀번호 변경에 실패하였습니다.",
    msg612: "이미 가입된 이메일 입니다.",
    // 참가 등록
    msg700: "parameter가 누락되었습니다.",
    msg701: "휴대폰 인증이 성공하였습니다..",
    msg702: "인증번호를 다시 확인하여주세요.",
    msg703: "메일 인증이 성공하였습니다.",
    msg704: "인증번호를 입력해 주세요.",
    msg705: "인증번호 2회 전송 후 1분후 재시도 가능합니다.",
    msg706: "연락처 수정에 성공하였습니다.",
    msg707: "연락처 수정에 실패하였습니다.",
    msg708: "사업자 등록번호가 중복되었습니다.",
    msg709: "사업자 등록번호 수정이 완료되었습니다.",
    msg710: "수정이 가능합니다. n 저장 버튼을 클릭해주세요.",
    msg711: "최대 3개까지 선택 가능합니다.",
    msg712: "업종을 선택해 주세요.",
    msg713: "업종 수정에 실패하였습니다.",
    msg714: "캠페인 유형을 선택해 주세요.",
    msg715: "리뷰 카테고리를 선택해 주세요.",
    msg716: "프리미언 채널을 선택해 주세요.",
    msg717: "캠페인 진행 방식을 선택해 주세요.",
    msg718: "캠페인 인원을 선택해 주세요.",
    msg719: "이메일 전송에 실패하였습니다.",
    msg720: "사업자 등록번호를 다시 확인해 주세요.",
};
