import React, { Fragment, useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import DummyImage from "../../assets/images/dummy_image.png";
import { selectCampaignType } from "components/common/SelectData";
import { addCommas, formatPercent, formatInstagram, formatInstagramKorean } from "components/common/common";
import DummyPostImg from "../../assets/images/img_dummy_pc_80.jpg";
import moment from "moment";
import LineChart from "components/untils/LineChart";
import AxiosPost from "components/untils/AxiosPost";
import { truncateString } from "components/untils/utils";
import { useNavigate } from "react-router-dom";
// import { Tab, Tabs } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

function CompletedCampaign({ myEndCampaignList, setMyEndCampaignList }) {
    // console.log("myEndCampaignList --- ", myEndCampaignList);
    const navigate = useNavigate();
    const [isActive, setActive] = useState("false");
    const [sendKey, setSendKey] = useState(myEndCampaignList[0]?.campaign_id);
    const [postList, setPostList] = useState([]);
    const [dateDatas, setDateDatas] = useState([]);

    const handleToggle = () => {
        setActive(!isActive);
    };

    // 캠페인 디테일 이동
    const clickDetailPage = (item) => {
        navigate(`/campaignDetail/${item}`, {
            state: {
                campaignId: item,
            },
        });
    };

    const scaleValue = (value, minValue, maxValue, minScale, maxScale) => {
        const scaledValue = ((value - minValue) / (maxValue - minValue)) * (maxScale - minScale) + minScale;
        return scaledValue;
    };

    // 팔로워 데이터 가공
    const followerGraphData = [
        {
            id: "팔로워",
            color: "hsl(82, 70%, 50%)",
            data:
                dateDatas?.length === 0
                    ? []
                    : dateDatas?.map((item) => ({
                          x: item?.create_at,
                          y: item?.followers_count,
                      })),
        },
    ];
    // 반응률 데이터 가공
    const erGraphData = [
        {
            id: "반응률(ER)",
            color: "hsl(202, 70%, 50%)",
            data:
                dateDatas?.length === 0
                    ? []
                    : dateDatas?.map((item) => ({
                          x: item?.create_at,
                          y: (((parseInt(item?.like_cnt) + parseInt(item?.comment_cnt)) / parseInt(item?.followers_count)) * 100).toFixed(2),
                      })),
        },
    ];

    // 좋아요 데이터 가공
    const likeGraphData = [
        {
            id: "좋아요",
            color: "hsl(240, 70%, 50%)",
            data:
                dateDatas?.length === 0
                    ? []
                    : dateDatas?.map((item) => ({
                          x: item?.create_at,
                          y: item?.like_cnt,
                      })),
        },
    ];
    // 댓글 데이터 가공
    const commentGraphData = [
        {
            id: "댓글",
            color: "hsl(349, 70%, 50%)",
            data:
                dateDatas?.length === 0
                    ? []
                    : dateDatas?.map((item) => ({
                          x: item?.create_at,
                          y: item?.comment_cnt,
                      })),
        },
    ];

    // 데이터 가공
    const graphData = [
        {
            id: "좋아요",
            color: "hsl(240, 70%, 50%)",
            data:
                dateDatas?.length === 0
                    ? []
                    : dateDatas?.map((item) => ({
                          x: item?.create_at,
                          y: item?.like_cnt,
                      })),
        },
        {
            id: "댓글",
            color: "hsl(349, 70%, 50%)",
            data:
                dateDatas?.length === 0
                    ? []
                    : dateDatas?.map((item) => ({
                          x: item?.create_at,
                          y: item?.comment_cnt,
                      })),
        },
    ];

    const plusValueData = dateDatas?.map((item) => ({
        create_at: item?.create_at,
        followers_count: item?.followers_count,
        comment_cnt: item?.comment_cnt,
        like_cnt: item?.like_cnt,
        ER: (((parseInt(item?.like_cnt) + parseInt(item?.comment_cnt)) / parseInt(item?.followers_count)) * 100).toFixed(2),
    }));

    // 포스트 에러 시 대체 이미지
    const handlePostImageError = (event) => {
        event.target.src = DummyPostImg;
    };

    const checkErrorNumber = (number) => {
        if (number === Infinity) {
            return 0;
        } else if (isNaN(number)) {
            return 0;
        } else {
            return number;
        }
    };

    useEffect(() => {
        AxiosPost("campaign/instarPostList", { campaign_id: sendKey })
            .then((res) => {
                setDateDatas(res?.data?.data?.dateData);
                // console.log("11", res?.data?.data);
                setPostList(res?.data?.data?.rows);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [sendKey]);

    return (
        <Fragment>
            <div className="ongoing_campaign completed_campaign">
                <Accordion defaultActiveKey={0}>
                    {myEndCampaignList &&
                        myEndCampaignList?.map((item, index) => {
                            return (
                                <Accordion.Item
                                    eventKey={myEndCampaignList[0]?.campaign_id === item?.campaign_id ? 0 : index + 1}
                                    onClick={() => {
                                        setSendKey(item?.campaign_id);
                                    }}
                                    key={item?.campaign_id}
                                >
                                    <Accordion.Header>
                                        <div className="col-lg-11 col-md-11 col-sm-11 table-responsive">
                                            <table>
                                                <thead>
                                                    <tr
                                                        onClick={() => {
                                                            clickDetailPage(item?.campaign_id);
                                                        }}
                                                    >
                                                        <th>캠페인명</th>
                                                        <th>캠페인 유형</th>
                                                        <th>캠페인 진행기간</th>
                                                        <th>참여자수 / 지원자수 / 모집자수</th>
                                                        <th>집행 광고비 (원)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <div className="fixed_height">{item?.campaign_name}</div>
                                                        </td>
                                                        <td>
                                                            <div className="fixed_height">{selectCampaignType(item?.campaign_type)}</div>
                                                        </td>
                                                        <td>
                                                            <div className="fixed_height">
                                                                {moment(item?.posting_start_date).format("YY-MM-DD")} - {moment(item?.posting_end_date).format("YY-MM-DD")}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="fixed_height">
                                                                {item?.posting_count ? item?.posting_count : 0} / {item?.influencer_count ? item?.influencer_count : 0} /{" "}
                                                                {item?.total_count ? item?.total_count : 0}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="fixed_height">{item?.campaign_price ? addCommas(item?.campaign_price) : 0}</div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col-lg-1 col-md-1 col-sm-1"></div>
                                    </Accordion.Header>

                                    <Accordion.Body>
                                        <div className="row">
                                            <div className="col-sm-10 padding_0">
                                                <div className="countdown">
                                                    <div className="row">
                                                        <div className="col-sm-1 ps-0 pe-0 d-none">
                                                            <div className="countdown_info countdown_heading">
                                                                <p>KPI</p>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-2 width_50">
                                                            <div className="countdown_info">
                                                                <p>
                                                                    <span>도달수</span>
                                                                    {item?.followers_cnt ? addCommas(item?.followers_cnt) : 0}
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-2 width_50">
                                                            <div className="countdown_info">
                                                                <p>
                                                                    <span>댓글</span>
                                                                    {item?.comment_cnt ? addCommas(item?.comment_cnt) : 0}
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-2 width_50">
                                                            <div className="countdown_info">
                                                                <p>
                                                                    <span>좋아요</span>
                                                                    {item?.like_cnt ? addCommas(item?.like_cnt) : 0}
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-2 width_50">
                                                            <div className="countdown_info">
                                                                <p>
                                                                    <span>반응률(ER)</span>
                                                                    {parseInt(item?.followers_cnt)
                                                                        ? formatPercent(
                                                                              ((parseInt(item?.like_cnt) + parseInt(item?.comment_cnt)) / parseInt(item?.followers_cnt)) * 100
                                                                          )
                                                                        : 0}
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-2 d-none">
                                                            <div className="countdown_info">
                                                                <p>
                                                                    <span>CPV</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <img src={item?.image_data} alt="Dummy Image" />
                                            </div>
                                        </div>
                                        <div className="row graph_info">
                                            <div className="graph" style={{ marginTop: "50px" }}>
                                                {graphData[0]?.data?.length > 0 && graphData[1]?.data?.length > 0 && (
                                                    <Tabs defaultActiveKey="follower" id="uncontrolled-tab-example" className="mb-1" fill>
                                                        <Tab eventKey="follower" title="팔로워">
                                                            <LineChart datas={followerGraphData} jsonArray={plusValueData} />
                                                        </Tab>
                                                        <Tab eventKey="comment" title="댓글 수">
                                                            <LineChart datas={commentGraphData} jsonArray={plusValueData} />
                                                        </Tab>
                                                        <Tab eventKey="like" title="좋아요">
                                                            <LineChart datas={likeGraphData} jsonArray={plusValueData} />
                                                        </Tab>
                                                        <Tab eventKey="er" title="반응률(ER)">
                                                            <LineChart datas={erGraphData} jsonArray={plusValueData} />
                                                        </Tab>
                                                    </Tabs>
                                                )}

                                                <div className="graph_details">
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="row">
                                                                <div className="col-sm-4">
                                                                    <p>
                                                                        <span>제품/브랜드 도달효과</span>
                                                                    </p>
                                                                </div>
                                                                <div className="col-sm-8">
                                                                    <p>총 {item?.followers_cnt ? addCommas(item?.followers_cnt) : 0}의 팔로워에게 캠페인이 도달</p>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-sm-4">
                                                                    <p>
                                                                        <span>
                                                                            포스팅 조회
                                                                            <span className="text-danger star">*</span>
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                                <div className="col-sm-8">
                                                                    <p>
                                                                        총 {item?.followers_cnt ? addCommas(item?.followers_cnt + item?.comment_cnt + item?.like_cnt) : 0}의
                                                                        팔로워가 캠페인을 조회
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-sm-4">
                                                                    <p>
                                                                        <span>포스팅에 대한 반응</span>
                                                                    </p>
                                                                </div>
                                                                <div className="col-sm-8">
                                                                    <p>
                                                                        총 {item ? addCommas(item?.comment_cnt + item?.like_cnt) : 0}
                                                                        명이 캠페인에 반응
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-6">
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <div className="average_data">
                                                                        <p>
                                                                            반응 건당 비용(CPE)
                                                                            <span>
                                                                                {addCommas(
                                                                                    checkErrorNumber(
                                                                                        parseInt(item?.campaign_price) / (item?.comment_cnt + item?.like_cnt)
                                                                                    )?.toFixed(1)
                                                                                )}
                                                                                원
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-6">
                                                                    <div className="average_data">
                                                                        <p>
                                                                            노출건당 비용(CPR)
                                                                            <span>{checkErrorNumber(item?.campaign_price / item?.followers_cnt)?.toFixed(1)}원</span>
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-12 mt-3  text-end">
                                                                    <h4 className="notice_impo ">*해당 항목은 프로페셔널 계정만 수집되었습니다.</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={`row registered_post ${isActive ? "expand_list" : ""}`}>
                                            <h4>등록 포스팅</h4>
                                            <div className="table-responsive">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>NO.</th>
                                                            <th></th>
                                                            <th>캠페인 포스팅</th>
                                                            <th>좋아요</th>
                                                            <th>댓글</th>
                                                            <th>도달</th>
                                                            <th>매칭율</th>
                                                            <th>평가점수</th>
                                                        </tr>
                                                    </thead>
                                                    {/* ${index === 0 ? "best_view" : ""} */}
                                                    <tbody>
                                                        {postList &&
                                                            postList?.map((item, index) => {
                                                                return (
                                                                    <tr key={item?.posturl_id}>
                                                                        <td>{index + 1}</td>
                                                                        <td>
                                                                            <a href={item?.post_url ? item?.post_url : DummyPostImg} target="_blank">
                                                                                <div className={`best_view_like  ${index === 0 ? "best_like" : ""}`}>
                                                                                    <img
                                                                                        src={item?.media_url ? item?.media_url : DummyImage}
                                                                                        onError={handlePostImageError}
                                                                                        alt=""
                                                                                        style={{ height: "65px" }}
                                                                                    />
                                                                                    <span>{index === 0 ? "Best Like" : ""}</span>
                                                                                </div>
                                                                            </a>
                                                                        </td>
                                                                        <td>
                                                                            <p>{truncateString(item?.contents, 150)}</p>
                                                                        </td>
                                                                        <td>{item?.like_cnt ? addCommas(item?.like_cnt) : "여러명"}</td>
                                                                        <td>{item?.comment_cnt ? addCommas(item?.comment_cnt) : "여러명"}</td>
                                                                        <td>{item?.followers_count ? formatInstagramKorean(item?.followers_count) : "여러명"}</td>
                                                                        <td>{item?.matchPercenTage}</td>
                                                                        <td>{item?.confirm_avg == null ? item?.confirm_avg : item?.result}</td>
                                                                        {/* <td>{item?.confirm_avg == 0 ? item?.result : item?.confirm_avg}</td> */}
                                                                    </tr>
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="col-sm-12 text-center mt-5">
                                            <Button className="btn_layout" onClick={handleToggle}>
                                                포스팅 더 보기
                                            </Button>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            );
                        })}
                </Accordion>

                {/* <div className="col-sm-12 mt-4">
                    <Button className="dwnload_exl">리포트 Excel 다운로드</Button>
                </div> */}
            </div>
        </Fragment>
    );
}

export default CompletedCampaign;
