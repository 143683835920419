import React, { Fragment, useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { channelList, photographyList } from "components/db/DataArrayList";
import { locale } from "components/common/common";
import { GetMinDate, todayDate } from "components/untils/GetMinDate";

function EditCampaignRequest({ setEditResult, jsonData, setJsonData }) {
    const [countNum, setCountNum] = useState(jsonData?.campaign_counter);
    const [pointNum, setPointNum] = useState(jsonData?.campaign_point);
    const [campaignType, setCampaignType] = useState(jsonData?.campaign_type);
    const [channelValue, setChannelValue] = useState(jsonData?.campaign_channel);
    const [progressType, setProgressType] = useState(jsonData?.progress_type);
    const [imageCheck, setImageCheck] = useState(jsonData?.contents_type === "all" ? "image" : jsonData?.contents_type === "image" ? "image" : null);
    const [videoCheck, setVideoCheck] = useState(jsonData?.contents_type === "all" ? "video" : jsonData?.contents_type === "video" ? "video" : null);
    const [preference, setPreference] = useState(jsonData?.photography_preference);
    const [dateStart, setDateStart] = useState(jsonData?.campaign_postingperiod[0].start_date);
    const [dateEnd, setDateEnd] = useState(jsonData?.campaign_postingperiod[0].end_date);
    const [postingStart, setPostingStart] = useState(jsonData?.campaign_postingperiod[1].start_date);
    const [postingEnd, setPostingEnd] = useState(jsonData?.campaign_postingperiod[1].end_date);
    const [userInput, setUserInput] = useState(false);
    const [status, setStatus] = useState(0); // 0: no show, 1: show yes, 2: show no.
    const [formatValue, setFormValue] = useState(jsonData?.campaign_point);

    const radioHandler = (status) => {
        setStatus(status);
    };

    const handleDateChange = (event, picker) => {
        setDateStart(picker.startDate);
        setDateEnd(picker.endDate);
    };

    const handlePostingDateChange = (event, picker) => {
        setPostingStart(picker.startDate);
        setPostingEnd(picker.endDate);
    };

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({
        mode: "onSubmit",
        defaultValues: {
            campaign_name: jsonData?.campaign_name,
            text_requirements: jsonData?.text_requirements,
            hashtag0: jsonData?.campaign_hashtag?.hashtag1,
            hashtag1: jsonData?.campaign_hashtag?.hashtag2,
            hashtag2: jsonData?.campaign_hashtag?.hashtag3,
            hashtag3: jsonData?.campaign_hashtag?.hashtag4,
        },
    });
    // 하나라도 체크박스 되어있나 체크
    const validate = (value) => {
        return value?.length > 0 || "체크박스를 하나 이상 선택해주세요.";
    };

    const handleForm = (e) => {
        const processProduct1 = document.querySelector(".processProduct1");
        const processRecommended1 = document.querySelector(".processRecommended1");
        const processRecommended2 = document.querySelector(".processRecommended2");
        if (e.target.value === "P") {
            processProduct1.style.display = "inline-block";
            processRecommended1.style.display = "none";
            processRecommended2.style.display = "none";
        } else if (e.target.value === "R") {
            processProduct1.style.display = "none";
            processRecommended1.style.display = "inline-block";
            processRecommended2.style.display = "block";
        }
    };

    // 포인트 핸들 이벤트
    const handlePointChange = (e) => {
        const rawValue = e.target.value?.replace(/,/g, ""); // 쉼표 제거
        const strippedValue = rawValue?.replace(/^0+/, ""); // 앞쪽 0 제거
        setFormValue(Number(strippedValue)?.toLocaleString());
        setPointNum(strippedValue);
    };

    const onSubmit = (data) => {
        let count = parseInt(countNum);
        let point = parseInt(pointNum);

        let priceResult;
        if (count <= 10 && progressType === "P") {
            priceResult = 300000;
        } else if (count <= 10 && progressType === "R") {
            priceResult = 300000 + count * point * 1.3;
        } else if (progressType === "P") {
            priceResult = 300000 + (count - 10) * 15000;
        } else if (progressType === "R") {
            priceResult = 300000 + (count - 10) * point * 1.3;
        }
        let contents_type = "";
        if (imageCheck && videoCheck) {
            contents_type = "all";
        } else if (imageCheck) {
            contents_type = "image";
        } else if (videoCheck) {
            contents_type = "video";
        } else {
            contents_type = "";
        }
        if (!campaignType || !channelValue || !progressType || countNum === 0) {
            alert("빈칸을 입력해주세요.");
        } else if (userInput && !userInput) {
            alert("촬영 방법을 입력하여주세요.");
        } else if (contents_type === "") {
            alert("콘텐츠 유형을 선택해 주세요.");
            return;
        } else if (
            data?.campaign_hashtag?.hashtag1 === "" &&
            data?.campaign_hashtag?.hashtag2 === "" &&
            data?.campaign_hashtag?.hashtag3 === "" &&
            data?.campaign_hashtag?.hashtag4 === ""
        ) {
            alert("해시태그를 입력해 주세요.");
            return;
        } else {
            // // include_text: data.include_text == true ? "Y" : "N",
            let setData = {
                campaign_type: campaignType,
                campaign_channel: channelValue,
                progress_type: progressType,
                campaign_counter: countNum,
                product_price: priceResult,
                campaign_point: parseInt(pointNum),
                campaign_name: data.campaign_name,
                contents_type: contents_type,
                photography_preference: preference,
                text_requirements: data.text_requirements,

                campaign_postingperiod: [
                    {
                        start_date: moment(dateStart).format("YYYY-MM-DD"),
                        end_date: moment(dateEnd).format("YYYY-MM-DD"),
                        period_type: "인플루언서 모집기간",
                        date_type: "I",
                    },
                    {
                        start_date: moment(postingStart).format("YYYY-MM-DD"),
                        end_date: moment(postingEnd).format("YYYY-MM-DD"),
                        period_type: "캠페인 포스팅 기간",
                        date_type: "P",
                    },
                ],
                campaign_hashtag: {
                    hashtag1: data.hashtag0,
                    hashtag2: data.hashtag1,
                    hashtag3: data.hashtag2,
                    hashtag4: data.hashtag3,
                },
            };
            let tempJson = jsonData;
            for (var key in setData) {
                tempJson[key] = setData[key];
            }
            setJsonData(tempJson);
            setEditResult(false);
        }
    };
    const onError = (data) => {
        alert("모든 항목을 작성해 주세요.");
        console.log("onError --- ", data);
    };

    // 포토그래피 타입 찾기
    useEffect(() => {
        const findPhoto = photographyList.find((item) => item.value === jsonData?.photography_preference);
        const findPhotoId = findPhoto ? findPhoto.id : 4;
        setStatus(findPhotoId);
    }, []);

    // 캠페인 진행 방식 input 을 위한
    useEffect(() => {
        const processProduct1 = document.querySelector(".processProduct1");
        const processRecommended1 = document.querySelector(".processRecommended1");
        const processRecommended2 = document.querySelector(".processRecommended2");
        if (jsonData?.progress_type === "P") {
            processProduct1.style.display = "inline-block";
            processRecommended1.style.display = "none";
            processRecommended2.style.display = "none";
        } else if (jsonData?.progress_type === "R") {
            processProduct1.style.display = "none";
            processRecommended1.style.display = "inline-block";
            processRecommended2.style.display = "block";
        }
    }, []);

    const DateComponent = ({ minDate }) => {
        return (
            <DateRangePicker
                onApply={handlePostingDateChange}
                initialSettings={{
                    locale: locale,
                    minDate: minDate,
                }}
            >
                <div className="clander_input">
                    <input type="text" className="form-control" value={postingStart ? moment(postingStart).format("YYYY-MM-DD") : ""} placeholder="포스팅 시작일" readOnly />
                    <input type="text" className="form-control " value={postingEnd ? moment(postingEnd).format("YYYY-MM-DD") : ""} placeholder="포스팅 마감일" readOnly />
                </div>
            </DateRangePicker>
        );
    };

    return (
        <Fragment>
            <div className="edit_reeview">
                <div className="container">
                    <form onSubmit={handleSubmit(onSubmit, onError)}>
                        <div className="row">
                            <div className="col-sm-3">
                                <label className="form-label">캠페인 유형</label>
                            </div>
                            <div className="col-sm-9">
                                <div className="form-check form-check-inline  mb-3">
                                    <input
                                        className="form-check-input"
                                        disabled
                                        type="radio"
                                        name="product_review"
                                        id="product_review"
                                        value="productReview"
                                        checked={campaignType === "productReview"}
                                        // defaultChecked={campaignType === "productReview" || jsonData?.campaign_type === "productReview"}
                                        // {...register("campaignType", {
                                        //     validate: validate,
                                        //     onChange: (e) => setCampaignType(e.target.value),
                                        // })}
                                    />
                                    <label className="form-check-label" htmlFor="product_review">
                                        {" "}
                                        상품리뷰{" "}
                                    </label>
                                </div>

                                <div className="form-check form-check-inline  mb-3">
                                    <input
                                        className="form-check-input"
                                        disabled
                                        type="radio"
                                        name="service_review"
                                        id="service_review"
                                        value="serviceReview"
                                        checked={campaignType === "serviceReview"}
                                        // defaultChecked={jsonData?.campaign_type === "serviceReview"}
                                        // onChange={(e) => setCampaignType(e.target.value)}
                                        // {...register("campaignType", {
                                        //     validate: validate,
                                        //     onChange: (e) => setCampaignType(e.target.value),
                                        // })}
                                    />
                                    <label className="form-check-label" htmlFor="service_review">
                                        {" "}
                                        서비스리뷰{" "}
                                    </label>
                                </div>

                                <div className="form-check form-check-inline  mb-3">
                                    <input
                                        className="form-check-input"
                                        disabled
                                        type="radio"
                                        name="visit_review"
                                        id="visit_review"
                                        value="visitReview"
                                        checked={campaignType === "visitReview"}
                                        // onChange={(e) => setCampaignType(e.target.value)}
                                        // {...register("campaignType", {
                                        //     validate: validate,
                                        //     onChange: (e) => setCampaignType(e.target.value),
                                        // })}
                                    />
                                    <label className="form-check-label" htmlFor="visit_review">
                                        {" "}
                                        방문리뷰{" "}
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="row ">
                            <div className="col-sm-3">
                                <label className="form-label">진행 프리미언 유형</label>
                            </div>
                            <div className="col-sm-9">
                                {channelList.map((item) => {
                                    return (
                                        <div className="form-check form-check-inline  mb-3" key={item.id}>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                id={item.value}
                                                name={item.value}
                                                value={item.value}
                                                disabled={item.disabled}
                                                checked={channelValue === item.value}
                                                // onChange={(e) => setChannelValue(e.target.value)}
                                                {...register("channelValue", {
                                                    validate: validate,
                                                    onChange: (e) => setChannelValue(e.target.value),
                                                })}
                                            />
                                            <label className="form-check-label" htmlFor={item.value}>
                                                {item.label}
                                            </label>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-3">
                                <label className="form-label">캠페인 진행 방식 및 명수</label>
                            </div>
                            <div className="col-sm-9 nopeopless">
                                <div className="row text-start">
                                    <div className="col-sm-12">
                                        <div className="form-check form-check-inline  mb-3">
                                            <input
                                                type="radio"
                                                name="slider"
                                                className="form-check-input"
                                                value="P"
                                                id="processProduct"
                                                checked={progressType === "P"}
                                                onChange={handleForm}
                                                onClick={(e) => setProgressType(e.target.value)}
                                            />
                                            <label htmlFor="processProduct" className="form-check-label slide processProduct">
                                                상품(서비스)로만 진행할래요
                                                <br />
                                                <span style={{ display: "none" }}>(프리미언에게 포스팅 진행 추가 포인트가 지급되지 않습니다.)</span>
                                            </label>
                                        </div>

                                        <div className="col-sm-2 processProduct1" style={{ display: "none" }}>
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={countNum}
                                                //  onChange={(e) => setCountNum(e.target.value)}
                                                {...register("countNum", {
                                                    required: true,
                                                    onChange: (e) => setCountNum(e.target.value),
                                                })}
                                            />
                                            <p className="d-inline nopeople">명</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row text-start">
                                    <div className="col-sm-12">
                                        <div className="form-check form-check-inline  mb-3">
                                            <input
                                                type="radio"
                                                name="slider"
                                                className="form-check-input"
                                                id="processRecommended"
                                                value="R"
                                                checked={progressType === "R"}
                                                onChange={handleForm}
                                                onClick={(e) => setProgressType(e.target.value)}
                                            />
                                            <label htmlFor="processRecommended" className="form-check-label slide processRecommended">
                                                인플루언서에게 상품(서비스)과 포인트를 지급할래요
                                                <span className="processRecommended2 " style={{ display: "none" }}>
                                                    <br />
                                                    지급 포인트
                                                    <input
                                                        type="text"
                                                        style={{
                                                            border: "1px solid #ced4ad",
                                                            borderRadius: "0.375rem",
                                                            width: "130px",
                                                            height: "30px",
                                                            padding: "15px",
                                                            margin: "0 10px",
                                                            appearance: "none",
                                                        }}
                                                        value={formatValue}
                                                        onChange={(e) => handlePointChange(e)}
                                                    />
                                                    원
                                                </span>
                                                <span className="text-danger d-block">
                                                    *인플루언서 1인당 지급 포인트를 입력하세요
                                                    <br />
                                                    *등급이 높은 인플루언서에게 별도 푸시 발송
                                                    <br />
                                                    *높은 인플루언서 신청율
                                                </span>
                                            </label>
                                        </div>
                                        <div className="col-sm-2 processRecommended1 mrt-15" style={{ display: "none" }}>
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={countNum}
                                                // onChange={(e) => setCountNum(e.target.value)}
                                                {...register("countNum", {
                                                    required: true,
                                                    onChange: (e) => setCountNum(e.target.value),
                                                })}
                                            />
                                            <p className="d-inline nopeople">명</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row  mb-4">
                            <div className="col-sm-3">
                                <label className="form-label">캠페인 제목</label>
                            </div>
                            <div className="col-sm-9">
                                <input className="form-control" {...register("campaign_name", { required: true })} />
                            </div>
                        </div>

                        <div className="row  mb-4">
                            <div className="col-sm-3">
                                <label className="form-label">캠페인 기간</label>
                            </div>
                            <div className="col-sm-9 datepicker">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <label className="form-label">프리미언 모집 기간</label>
                                    </div>

                                    <div className="col-sm-8">
                                        <DateRangePicker
                                            onApply={handleDateChange}
                                            initialSettings={{
                                                locale: locale,
                                                minDate: todayDate(),
                                            }}
                                        >
                                            <div className="clander_input">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={dateStart ? moment(dateStart).format("YYYY-MM-DD") : ""}
                                                    placeholder="모집 시작일"
                                                    readOnly
                                                />
                                                <input
                                                    type="text"
                                                    className="form-control "
                                                    value={dateEnd ? moment(dateEnd).format("YYYY-MM-DD") : ""}
                                                    placeholder="모집 마감일"
                                                    readOnly
                                                />
                                            </div>
                                        </DateRangePicker>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <label className="form-label">포스팅 기간</label>
                                    </div>

                                    <div className="col-sm-8">
                                        <DateComponent minDate={GetMinDate(dateEnd, 1)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row  mb-4">
                            <div className="col-sm-3">
                                <label className="form-label">콘텐츠 유형</label>
                            </div>
                            <div className="col-sm-9">
                                <div className="form-check form-check-inline  mb-3">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="contenttype"
                                        value="image"
                                        id="image-content"
                                        checked={imageCheck === "image"}
                                        // onChange={(e) => setImageCheck(e.target.value)}
                                        onChange={(e) => {
                                            const isChecked = e.target.checked;
                                            isChecked ? setImageCheck(e.target.value) : setImageCheck(null);
                                        }}
                                    />
                                    <label className="form-check-label" htmlFor="image-content">
                                        {" "}
                                        이미지{" "}
                                    </label>
                                </div>

                                <div className="form-check form-check-inline mb-3">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="contenttype"
                                        value="video"
                                        id="video-content"
                                        checked={videoCheck === "video"}
                                        onChange={(e) => {
                                            const isChecked = e.target.checked;
                                            isChecked ? setVideoCheck(e.target.value) : setVideoCheck(null);
                                        }}
                                    />
                                    <label className="form-check-label" htmlFor="video-content">
                                        {" "}
                                        동영상{" "}
                                    </label>
                                </div>
                                {/* <div className="form-check  form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="include_text"
                                        id="include_text"
                                        value="Y"
                                        checked={include == "Y"}
                                        onChange={(e) => {
                                            const isChecked = e.target.checked;
                                            console.log("dddd", isChecked);
                                            isChecked ? setInclude(e.target.value) : setInclude("N");
                                        }}
                                        {...register("include_text")}
                                    />
                                    <label className="form-check-label" htmlFor={"include_text"}>
                                        포스팅이 잘 검색될 수 있도록, 3줄이상 / 이미지 5장 이상 포스팅에 포함되어야 한다는 문구 보이기
                                    </label>
                                </div> */}
                            </div>
                        </div>

                        <div className="row  mb-4">
                            <div className="col-sm-3">
                                <label className="form-label">촬영 방법</label>
                            </div>
                            <div className="col-sm-9">
                                {photographyList.map((item) => {
                                    return (
                                        <div className="form-check  mb-3" key={item.id}>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="shoot"
                                                id={item.label}
                                                checked={status === item.id}
                                                onChange={(e) => {
                                                    radioHandler(item.id);
                                                    setPreference(item.value);
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor={item.label}>
                                                {item.value}
                                            </label>
                                        </div>
                                    );
                                })}

                                <div className="form-check  mb-3">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="shoot"
                                        id="direct"
                                        checked={status === 4}
                                        onChange={(e) => {
                                            radioHandler(4);
                                            setUserInput(true);
                                        }}
                                    />
                                    <label className="form-check-label" htmlFor="direct">
                                        {" "}
                                        직접 입력{" "}
                                    </label>
                                </div>
                                <div className="col-sm-4">
                                    {status === 4 && (
                                        <input
                                            className="form-control"
                                            placeholder=""
                                            style={{ display: "inline-block", width: "auto" }}
                                            defaultValue={jsonData?.photography_preference}
                                            onChange={(e) => setPreference(e.target.value)}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-sm-3">
                                <label className="form-label">포함 필수 키워드</label>
                            </div>
                            <div className="col-sm-9">
                                <textarea
                                    className="form-control"
                                    placeholder="프리미언(인플루언서) 맨션에 꼭 들어가야 하는 중요 키워드, 설명 등 필수 필요사항을 입력해 주세요. (3줄 이하로 간단하게 작성)"
                                    rows="6"
                                    {...register("text_requirements", { required: true })}
                                ></textarea>
                            </div>
                        </div>

                        <div className="row mb-5">
                            <div className="col-sm-3">
                                <label className="form-label">필수 해시태그</label>
                            </div>
                            <div className="col-sm-9">
                                <div className="row hash_tags">
                                    {Array.from({ length: 4 }, (_, i) => {
                                        return (
                                            <div className="col-sm-3" key={i}>
                                                <input className="form-control" name="campaign_hashtag" {...register(`hashtag${i}`)} />
                                            </div>
                                        );
                                    })}
                                    <div className="col-sm-12 form-text">
                                        <span className="required_field">#광고</span>#광고 해시태그가 첫 번째에 고정 노출. 이는 ‘추천·보증 등에 관한 표시·광고 심사지침 개정’에 따라
                                        해당 내용을 필수로 고지해야 합니다.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-sm-12 text-center">
                                <Button className="btn_layout" type="submit">
                                    저장
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    );
}

export default EditCampaignRequest;
