import React from "react";
import { Fragment } from "react";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

function FindIdFail() {
    let navigate = useNavigate();
    return (
        <Fragment>
            <div className="findresult_panel_ad">
                <div className="findresults">
                    <p>입력하신 정보와 일치하는 회원가입 정보가 없습니다.<br /> 가입을 진행하시거나, 도움이 필요하시면 <br /> 고객센터(1:1챗)에 문의를 남겨주세요.</p>
                </div>
                <div className="row mb-3">
                    <div className="col-sm-2"></div>
                    <div className="col-sm-8 text-center">
                        {" "}
                        <Button onClick={() => navigate("/join")} className="btn_layout">
                            광고주 즉시 가입{" "}
                        </Button>
                    </div>
                    <div className="col-sm-2"></div>
                </div>
            </div>
        </Fragment>
    );
}

export default FindIdFail;
