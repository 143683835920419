import React, { useState, useEffect, Fragment } from "react";
import Table from "react-bootstrap/Table";
import AxiosPost from "components/untils/AxiosPost";
import { useSelector } from "react-redux";
import PaginationBox from "components/untils/PaginationBox";
const itemsPerPage = 5; // 페이지당 아이템 개수

const SettlementDetails = () => {
    const loginSeq = useSelector((state) => state.auth.seq);

    const [myCampaignList, setMyCampaignList] = useState([]);
    const [totalCount, setTotalCount] = useState(1);

    useEffect(() => {
        fetchData(1);
    }, []);

    const fetchData = (page) => {
        AxiosPost("campaign/myCampaigns", { client_id: loginSeq, page: page, rowCount: itemsPerPage }).then((res) => {
            // console.log("마이 캠페인 --- ", res.data);
            if (res?.data?.result === "success") {
                setMyCampaignList(res?.data?.data?.rows);
                setTotalCount(res?.data?.data?.count);
            }
        });
    };

    const onPageChange = (page) => {
        fetchData(page);
    };

    return (
        <Fragment>
            <div className="hr_line"></div>
            <div className="settlement_dtls mtb190">
                <div className="container">
                    <h2 className="inner_heading">정산내역</h2>

                    <div className="table-responsive">
                        <Table striped className="text-center">
                            <thead>
                                <tr>
                                    <th>번호</th>
                                    <th>날짜</th>
                                    <th>캠페인 No</th>
                                    <th>상세내용</th>
                                    <th>입금</th>
                                    <th>출금</th>
                                    <th>환급액(원)</th>
                                    <th>잔액(원)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {myCampaignList &&
                                    myCampaignList?.map((item) => {
                                        return (
                                            <tr key={item?.campaign_id}>
                                                <td>{item?.campaign_id}</td>
                                                <td>2023.04.10</td>
                                                <td>CV2304090001</td>
                                                <td>신규 캠페인 결제</td>
                                                <td>0</td>
                                                <td className="text-danger">800,000</td>
                                                <td>0</td>
                                                <td>3,855,000</td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </Table>
                    </div>
                    <PaginationBox data={myCampaignList} totalCount={totalCount} itemsPerPage={itemsPerPage} onPageChange={onPageChange} />
                </div>
            </div>
        </Fragment>
    );
};

export default SettlementDetails;
