import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import FindId from "./FindId";
import FindPwd from "./FindPwd";
import { useLocation } from "react-router-dom";

function TabPage() {
    const location = useLocation();
    const eventKey = location?.state?.eventKey;
    let defaultEvent = eventKey ? eventKey : "findid";
    return (
        <React.Fragment>
            <div className="hr_line"></div>
            <div className="tab_panel mtb190">
                <div className="container">
                    <Tabs defaultActiveKey={defaultEvent} id="fill-tab-example" className="mb-3" fill>
                        <Tab eventKey="findid" title="아이디 찾기">
                            <FindId />
                        </Tab>

                        <Tab eventKey="findpwd" title="비밀번호 찾기">
                            <FindPwd />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </React.Fragment>
    );
}

export default TabPage;
