import React, { Fragment, useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Logo from "../../../assets/images/header_logo.png";
import LoginModal from "components/LoginModal/LoginModal";
import { useSelector, useDispatch } from "react-redux";
import { setLogout } from "redux/store/userSlice";
import { persistor } from "../../../index";
import { removeCookie } from "components/untils/Cookie";
import { FatchPost } from "components/untils/FatchPost";

function LogOutHeader() {
    const dispatch = useDispatch();
    const [modalShow, setModalShow] = useState(false);
    const [pathName, setPathName] = useState("");
    const [userInfo, setUserInfo] = useState(null);
    const loggedIn = useSelector((state) => state.auth.isLoggedIn);
    const loginSeq = useSelector((state) => state.auth.seq);
    const handleClose = () => setModalShow(false);

    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 150);
        });
    }, []);
    const purge = async () => {
        dispatch(setLogout());
        await persistor.purge();
        removeCookie("token");
    };

    const logoutEnterPage = (path) => {
        if (path === "campaignestimate") {
            setPathName("/campaignestimate");
        } else if (path === "mycampaign") {
            setPathName("/mycampaign");
        }
        setModalShow(true);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await FatchPost("client/user/info", { client_id: loginSeq });
                setUserInfo(response?.data?.data?.rows);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [loggedIn]);

    const scrollToTop = () => {
        window.scroll({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <Fragment>
            <LoginModal show={modalShow} pathname={pathName} handleClose={handleClose} />
            <div className={scroll ? "header  fixed-top" : "not_fixed"}>
                {" "}
                <div className="">
                    <div className="container">
                        <nav className="navbar navbar-light navbar-expand-md bg-faded justify-content-center main_header">
                            <Link to="/" className="navbar-brand d-flex mr-auto header_logo">
                                <img src={Logo} alt="더프리미언" />
                            </Link>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="navbar-collapse collapse w-100 menu_bar justify-content-center" id="navbarCollapse">
                                <ul className="navbar-nav w-100">
                                    <li className="nav-item active">
                                        {loggedIn ? (
                                            <Link className="nav-link" to="/campaignestimate">
                                                캠페인 등록
                                            </Link>
                                        ) : (
                                            <Link className="nav-link" onClick={() => logoutEnterPage("campaignestimate")}>
                                                캠페인 등록
                                            </Link>
                                        )}
                                    </li>

                                    <li className="nav-item">
                                        {loggedIn ? (
                                            <Link className="nav-link" to="/mycampaign">
                                                마이 캠페인
                                            </Link>
                                        ) : (
                                            <Link className="nav-link" onClick={() => logoutEnterPage("mycampaign")}>
                                                마이 캠페인
                                            </Link>
                                        )}
                                    </li>
                                    <li className="nav-item landing_page_btn">
                                        <Link className="nav-link" to="/downloadapp">
                                            앱 다운로드
                                        </Link>
                                    </li>
                                </ul>
                                <ul className="nav navbar-nav ml-auto w-100 justify-content-end">
                                    {loggedIn ? (
                                        <li className="nav-item dropdown after_login" style={{ display: "block" }}>
                                            <a
                                                className="nav-link dropdown-toggle"
                                                href="#"
                                                id="navbarDropdownMenuLink"
                                                role="button"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                <span className="user_name">{userInfo?.manager_name}</span>님<br />
                                                <span className="bran_name">{userInfo?.company_name}</span>
                                            </a>
                                            <ul className="dropdown-menu text-center" aria-labelledby="navbarDropdownMenuLink">
                                                <li style={{ display: "none" }}>
                                                    <p>100,000원</p>
                                                </li>
                                                <li>
                                                    <Link className="dropdown-item" to="/userInfo" onClick={scrollToTop}>
                                                        계정정보
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="dropdown-item" to="/settlementdetails" onClick={scrollToTop}>
                                                        정산내역
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="dropdown-item" to="/faq" onClick={scrollToTop}>
                                                        자주하는질문
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="dropdown-item" to="/contactus" onClick={scrollToTop}>
                                                        문의하기
                                                    </Link>
                                                </li>
                                                {/* <li>
                                                    <Link className="dropdown-item" to="/contactus" onClick={scrollToTop}>
                                                        실시간 채팅
                                                    </Link>
                                                </li> */}
                                                <li className="nav-item">
                                                    <div className="d-flex justify-content-center">
                                                        <Link className="dropdown-item" to="#" onClick={() => purge()}>
                                                            로그아웃
                                                        </Link>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                    ) : (
                                        <li className="nav-item after_logout">
                                            <Button variant="primary" className="btn_layout" onClick={() => setModalShow(true)}>
                                                회원가입 / 로그인
                                            </Button>
                                        </li>
                                    )}

                                    {/*  <li className="nav-item">
                                        <Link className="nav-link custom_btn btn btn-primary tooltip" to="/join">
                                            회원가입<span className="tooltiptext">지금 가입하시면 즉시 10,000원을 지급해 드립니다!</span>
                                        </Link>
                                         </li>*/}
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default LogOutHeader;
