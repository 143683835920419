import React, { useRef } from "react";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import AxiosPost from "components/untils/AxiosPost";
import { Modal } from "react-bootstrap";

const dataArr = [
    { id: 1, score: 0.5 },
    { id: 2, score: 1 },
    { id: 3, score: 1.5 },
    { id: 4, score: 2 },
    { id: 5, score: 2.5 },
];

function InfluencerEvaluation({ campaignId, influencerId, setChangeEvent, show, handleClose }) {
    const ref = useRef(null);
    const {
        register,
        handleSubmit,
        reset,
        watch,
        trigger,
        formState: { errors, isSubmitting, isDirty, isValid },
    } = useForm({ mode: "onSubmit" });

    const validate = (value) => {
        return value?.length > 0 || "모든 항목을 체크해 주세요.";
    };

    const onSubmit = (data) => {
        console.log("완료 ", data);
        let satisfaction = data.dateCompletion === "Y" ? 2.5 : 0;
        let complete = data.doYouUse === "Y" ? 2.5 : 0;
        AxiosPost("campaign/evaluationUpdate", {
            confirm_satisfaction: satisfaction,
            confirm_good_fit: data.satisfaction,
            confirm_revisit: data.appropriate,
            confirm_post_complete: complete,
            campaign_id: campaignId,
            influencer_id: influencerId,
        }).then((res) => {
            // console.log("평과 결과", res);
            if (res?.data?.result === "success") {
                setChangeEvent(true);
                reset();
                ref.current.click();
            }
        });
    };

    const onError = (data) => {
        console.log("에러", data);
        alert("모든 항목을 체크해 주세요.");
    };

    return (
        <Modal
            className="modal fade user_modal campaign_modal"
            id="influencer_evelution"
            show={show}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="lg"
        >
            <Modal.Body className="">
                <div className="modal-content1">
                    <div className="modal-header">
                        <h5 className="modal-title text-center">인플루언서 평가</h5>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit, onError)}>
                        <div className="modal-body text-left">
                            <div className="row mb-3">
                                <div className="col-sm-12">
                                    <label className="form-label">
                                        <span className="numbers">01</span> 해당 인플루언서가 기간내에 포스팅을 완료하였나요?
                                    </label>
                                </div>

                                <div className="col-sm-12 text-start">
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="period"
                                            id="inlineRadio1"
                                            value="Y"
                                            {...register("dateCompletion", { validate: validate })}
                                        />
                                        <label className="form-check-label" htmlFor="inlineRadio1">
                                            네
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="period"
                                            id="inlineRadio2"
                                            value="N"
                                            {...register("dateCompletion", { validate: validate })}
                                        />
                                        <label className="form-check-label" htmlFor="inlineRadio2">
                                            아니오
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-sm-12">
                                    <label className="form-label">
                                        <span className="numbers">02 </span>전반적으로 해당 인플루언서의 포스팅 만족도는 어느정도 이신가요?
                                    </label>
                                </div>

                                <div className="col-sm-12 text-start">
                                    {dataArr?.map((item) => {
                                        return (
                                            <div className="form-check form-check-inline" key={item.id}>
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name={"post" + item.id}
                                                    id={"post" + item.id}
                                                    value={item.score}
                                                    {...register("satisfaction", { validate: validate })}
                                                />
                                                <label className="form-check-label" htmlFor={"post" + item.id}>
                                                    {item.id}
                                                </label>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-sm-12">
                                    <label className="form-label">
                                        <span className="numbers">03 </span>인플루언서와 제품/서비스의 적합도(비슷한 카테고리, 포스팅 등)는 적절 했나요?
                                    </label>
                                </div>

                                <div className="col-sm-12 text-start">
                                    {dataArr?.map((item) => {
                                        return (
                                            <div className="form-check form-check-inline" key={item.id}>
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name={"categories" + item.id}
                                                    id={"categories" + item.id}
                                                    value={item.score}
                                                    {...register("appropriate", { validate: validate })}
                                                />
                                                <label className="form-check-label" htmlFor={"categories" + item.id}>
                                                    {item.id}
                                                </label>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-sm-12">
                                    <label className="form-label">
                                        <span className="numbers">04 </span>다음에도 더프리미언 플랫폼을 이용하실 의향이 있으신가요?
                                    </label>
                                </div>

                                <div className="col-sm-12 text-start">
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="premium"
                                            id="nextRadio1"
                                            value="Y"
                                            {...register("doYouUse", { validate: validate })}
                                        />
                                        <label className="form-check-label" htmlFor="nextRadio1">
                                            네
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="premium"
                                            id="nextRadio2"
                                            value="N"
                                            {...register("doYouUse", { validate: validate })}
                                        />
                                        <label className="form-check-label" htmlFor="nextRadio2">
                                            아니오
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-sm-6">
                                    <Button className="btn_layout un_active width_100" data-bs-dismiss="modal" ref={ref} onClick={handleClose}>
                                        취소
                                    </Button>
                                </div>

                                <div className="col-sm-6">
                                    <Button className={`btn_layout width_100 `} type="submit">
                                        확인
                                    </Button>
                                    {/* ${isValid ? "" : "un_active"} */}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default InfluencerEvaluation;
