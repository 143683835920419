import React from "react";
import { ResponsiveRadar } from "@nivo/radar";
import "../../assets/style/customRadarChart.css";

function RadarChart({ userInfo }) {
    let username = userInfo?.name;
    const settingData = [];
    let scoreName = ["호감도", "반응율", "전문성", "성실도", "컨텐츠"];
    for (let gradeScore of scoreName) {
        let scoreDist = {};
        if (gradeScore === "호감도") {
            scoreDist["id"] = 1;
            scoreDist["score"] = "호감도";
            scoreDist[username] = isNaN(userInfo?.preference_score) ? 0 : userInfo?.preference_score?.toString();
        } else if (gradeScore === "반응율") {
            scoreDist["id"] = 2;
            scoreDist["score"] = "반응율";
            scoreDist[username] = isNaN(userInfo?.response_rate_score) ? 0 : userInfo?.response_rate_score?.toString();
        } else if (gradeScore === "전문성") {
            scoreDist["id"] = 3;
            scoreDist["score"] = "전문성";
            scoreDist[username] = isNaN(userInfo?.professionalism_score) ? 0 : userInfo?.professionalism_score?.toString();
        } else if (gradeScore === "성실도") {
            scoreDist["id"] = 4;
            scoreDist["score"] = "성실도";
            scoreDist[username] = isNaN(userInfo?.potential_score) ? 0 : userInfo?.potential_score?.toString();
        } else if (gradeScore === "컨텐츠") {
            scoreDist["id"] = 5;
            scoreDist["score"] = "컨텐츠";
            scoreDist[username] = isNaN(userInfo?.quality_score) ? 0 : userInfo?.quality_score?.toString();
        }
        settingData.push(scoreDist);
        // console.log("scoreDist -- ", scoreDist);
    }

    let sum = userInfo?.preference_score + userInfo?.response_rate_score + userInfo?.professionalism_score + userInfo?.potential_score + userInfo?.quality_score;

    return (
        <div style={{ width: "auto", height: "300px", margin: "0 auto" }}>
            <div className="radarchart_percentage">
                <p>인플루언서 평점</p>
                <h4>{isNaN(sum / 5) ? 0 : (sum / 5).toFixed(2)}</h4>
            </div>
            <ResponsiveRadar
                data={settingData}
                // data={settingData.map((item, index) => ({
                //     id: item.id,
                //     인플루언서: item.인플루언서,
                //     score: item.score,
                //     key: `item-${index}`,
                // }))}
                // data={settingData?.map((item) => ({
                //     id: item.id,
                //     username: item.username,
                //     score: item.score,
                // }))}
                keys={[username]}
                indexBy="score"
                keyProp="id"
                valueFormat=">-.1f"
                maxValue={10}
                margin={{ left: 20, top: 15, bottom: 0, right: 220 }}
                curve="linearClosed"
                borderWidth={2}
                borderColor={{ from: "color" }}
                gridLevels={5}
                gridShape="linear"
                gridLabelOffset={10}
                enableDots={true}
                dotBorderWidth={2}
                dotBorderColor={{ from: "color" }}
                enableDotLabel={true}
                dotLabel="value"
                dotLabelYOffset={-12}
                colors={{ scheme: "paired" }}
                fillOpacity={0.7}
                blendMode="darken"
                animate={true}
                motionConfig="wobbly"
                isInteractive={true}
                sliceTooltip={(node) => {
                    const key = node.index;
                    let customText = "";
                    if (key === "호감도") {
                        customText = "이전에 수행한 캠페인의 평가 점수 평점";
                    } else if (key === "반응율") {
                        customText = "최신 포스팅들의 반응율";
                    } else if (key === "전문성") {
                        customText = "카테고리 키워드가 최신 포스팅에서 사용된 #의 숫자";
                    } else if (key === "성실도") {
                        customText = "전달 대비 포스팅, 좋아요, 댓글의 증감수 비교";
                    } else {
                        customText = "광고 포스팅의 비율";
                    }
                    return (
                        <div className="tooltip-container">
                            <p className="title-text">{key}</p>
                            <p>{customText}</p>
                        </div>
                    );
                }}
                // legends={[
                //     {
                //         anchor: "bottom-right",
                //         direction: "column",
                //         translateX: -120,
                //         translateY: -30,
                //         itemWidth: 90,
                //         itemHeight: 30,
                //         itemTextColor: "#999",
                //         symbolSize: 20,
                //         fontSize: 18,
                //         symbolShape: "square",
                //         effects: [
                //             {
                //                 on: "hover",
                //                 style: {
                //                     itemTextColor: "#000",
                //                 },
                //             },
                //         ],
                //     },
                // ]}
            />
        </div>
    );
}

export default RadarChart;
