import AxiosPost from "components/untils/AxiosPost";
import React, { useRef } from "react";
import Button from "react-bootstrap/Button";
import { Modal } from "react-bootstrap";

function PostingStatusModal({ datas, campaignId, setCurrentEffect, influDatas, getInfo, setNextEffect, scrollEvt, show, handleClose }) {
    const ref = useRef(null);
    const saveNextBtn = (e) => {
        const isValue = datas?.some((item) => item.drafting === "Y");

        influDatas.forEach((item) => {
            AxiosPost("auth/sendInfluencer", {
                phoneNumber: item.phone_num,
                companyName: getInfo.campaign_name,
                campaignId: campaignId,
                influencerId: item.influencer_id,
            }).then((res) => {
                if (res?.data?.result === "success") {
                    AxiosPost("campaign/selectInfluencer", { campaign_id: campaignId, influencer_id: item.influencer_id });
                    AxiosPost("push/individualPushV1", {
                        influencer_id: item.influencer_id,
                        title: "캠페인에 선발 확정",
                        bodyText: getInfo.campaign_name + " 캠페인에 선발 되었습니다.",
                    })
                        .then((res) => {
                            return true;
                        })
                        .catch((error) => {
                            // console.log(error);
                        });
                    AxiosPost("campaign/updateStatus", { campaign_id: campaignId, currentStatus: "shipping" }).then((res) => {
                        if (res?.data?.result === "success") {
                            setCurrentEffect(true);
                            setNextEffect(true);
                            scrollEvt();
                            ref.current.click();
                        }
                    });
                }
            });
        });
    };

    return (
        <Modal
            className="modal fade user_modal campaign_modal"
            id="posting_status"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="lg"
            show={show}
            onHide={handleClose}
        >
            <Modal.Body className="">
                <div className="modal-content1">
                    <div className="modal-body text-center">
                        <p>
                            <span>‘확인’</span> 버튼을 누르면
                            <br />
                            선발 확정된 인플루언서 {influDatas?.length}명에게 확정 안내 문자가 전송됩니다.
                        </p>
                        <br />
                        <div className="row">
                            <div className="col-sm-6">
                                <Button className="btn_layout un_active width_100" data-bs-dismiss="modal" ref={ref} onClick={handleClose}>
                                    취소
                                </Button>
                            </div>
                            <div className="col-sm-6">
                                <Button className="btn_layout width_100" onClick={saveNextBtn}>
                                    확인
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default PostingStatusModal;
