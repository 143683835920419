import React, { Fragment, useEffect, useState } from "react";
import BasicProfile from "../../assets/images/profile_ex.png";
import Button from "react-bootstrap/Button";
import AxiosPost from "components/untils/AxiosPost";
import { useNavigate } from "react-router-dom";
import { selectAgeType } from "components/common/SelectData";
import { addCommas, formatInstagramNumber, processText, formatInstagram } from "components/common/common";
// import { getRandomFallbackImage } from "components/common/common";

function InfluencerRecruitmentStatus({ datas, campaignId, setDataEffect, setCurrentEffect, getInfo, setNextEffect, scrollEvt, loadCurrent, preChange, currentType }) {
    const navigate = useNavigate();
    const [dataList, setDataList] = useState([]);
    const influeSelectClick = (influencerSeq, selected) => {
        let result = selected === "Y" ? "N" : "Y";
        AxiosPost("campaign/drafting", {
            campaign_id: campaignId,
            influencer_id: influencerSeq,
            flag: result,
        }).then((res) => {
            if (res?.data?.result === "success") {
                setDataEffect(true);
            }
        });
    };

    // 삭제할지 말지 확인
    useEffect(() => {
        AxiosPost("campaign/selectInfluencerList", { campaign_id: campaignId }).then((res) => {
            // console.log("selectInfluencerList ---- ", res?.data?.data);
            setDataList(res?.data?.data);
        });
    }, [preChange, currentType]);

    const saveNextBtn = (e) => {
        const isValue = datas?.some((item) => item.drafting === "Y");
        if (isValue) {
            AxiosPost("campaign/updateStatus", { campaign_id: campaignId, currentStatus: "recruitDone" }).then((res) => {
                if (res?.data?.result === "success") {
                    setCurrentEffect(true);
                    setNextEffect(true);
                    scrollEvt();
                }
            });
        } else {
            alert("프리미언을 선발해주세요.");
        }
    };

    return (
        <>
            <div className="recuritstatusbody">
                <p className="text-center">
                    참여를 신청한 프리미언의 SNS 활동을 확인하시고 선발하기 버튼을 누르시면 해당 프리미언이 선발 리스트에 등록됩니다. <br />
                    모집 기간이 완료되면 자동으로 최종 선택된 프리미언에게 포스팅 작성이 요청됩니다. 그 전까지 자유롭게 프리미언를 선택하고 취소하실 수 있습니다.
                </p>

                <div className="recuritstatus_detail recuritstatus_detail1">
                    <div className="row">
                        {datas &&
                            datas?.map((item) => {
                                return (
                                    <div className="col-sm-3" key={item?.influencer_id}>
                                        <div className={`recuritstatus_box ${item?.drafting === "Y" ? "influe_selected" : ""}`}>
                                            <div className="row recuritstatus_profile_info ">
                                                <div className="col-md-4 p-0">
                                                    <img
                                                        src={item?.profile_picture_url !== "except X" && item?.profile_picture_url ? item?.profile_picture_url : BasicProfile}
                                                        alt="User Pic"
                                                    />
                                                </div>
                                                <div className="col-md-8">
                                                    <h3>
                                                        {item?.name}/{item?.gender === "여성" ? "여" : "남"}
                                                    </h3>
                                                    <p>@{item?.instagram_username}</p>
                                                </div>
                                            </div>

                                            <div className="btn_iconss ">
                                                <div className="select_confmd">
                                                    선발
                                                    <br />
                                                    확정
                                                </div>
                                                <div className="percent">
                                                    {item?.matchPercenTage}
                                                    <span>%</span>
                                                </div>
                                            </div>

                                            <div className="recuritstatus_detailinfo mt-3">
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <p>팔로워</p>
                                                        <p>
                                                            <span>{item?.follower_count ? formatInstagram(item?.follower_count) : 0}</span>
                                                        </p>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <p>포스팅</p>
                                                        <p>
                                                            <span>{item?.media_count ? formatInstagram(item?.media_count) : 0}</span>
                                                        </p>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <p>연령대</p>
                                                        <p>
                                                            <span>{selectAgeType(item?.age_group)}</span>
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="ai_tech">
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <p>관심사</p>
                                                        </div>
                                                        <div className="col-sm-6 p-0">
                                                            <p>
                                                                <span>{item?.category}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    {item?.most_category !== null && item?.most_category !== "" && (
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <p>AI분석 관심사</p>
                                                            </div>
                                                            <div className="col-sm-6 p-0">
                                                                <p>
                                                                    <span>{item?.most_category}</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-6 pe-1 ">
                                                        <Button
                                                            onClick={() => {
                                                                navigate(`/influencerprofile/${campaignId}/${item?.influencer_id}`, {
                                                                    state: {
                                                                        campaignId: campaignId,
                                                                        influencerId: item?.influencer_id,
                                                                        getInfo: getInfo,
                                                                    },
                                                                });
                                                            }}
                                                            className="btn_layout2 btn btn-primary"
                                                        >
                                                            프로필 보기
                                                        </Button>
                                                    </div>
                                                    <div className="col-sm-6 ps-1">
                                                        {item?.drafting === "N" ? (
                                                            <Button
                                                                className="btn_layout2 bg_skyblue"
                                                                onClick={() => influeSelectClick(item?.influencer_id, item?.drafting)}
                                                                disabled={loadCurrent === "shipping" || loadCurrent === "posting"}
                                                            >
                                                                선발 하기
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                className="btn_layout2 bg_grey"
                                                                onClick={() => influeSelectClick(item?.influencer_id, item?.drafting)}
                                                                disabled={loadCurrent === "shipping" || loadCurrent === "posting"}
                                                            >
                                                                선발 취소
                                                            </Button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>

                <div className="col-sm-12 text-center mt-5">
                    <Button className="btn_layout" onClick={() => saveNextBtn()} disabled={loadCurrent !== "recruiting"}>
                        선발 프리미언 확인
                    </Button>
                </div>
            </div>
        </>
    );
}

export default InfluencerRecruitmentStatus;
