import React, { Fragment, useState } from "react";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ErrorCodeList } from "components/common/errorCodeList";

function FindPwdReset({ datas }) {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors, isSubmitting, isDirty, isValid },
    } = useForm({ mode: "onSubmit" });
    const [pwdCapsLock, setPwdCapsLock] = useState(false);
    const [rePwdCapsLock, setRePwdCapsLock] = useState(false);

    const onSubmit = (data) => {
        axios
            .post(process.env.REACT_APP_API + "client/user/changePwd", {
                client_id: datas?.client_id,
                newPwd: data.userpassword,
            })
            .then((res) => {
                if (res?.data?.result === "success") {
                    navigate("/pwd/result");
                } else {
                    alert(ErrorCodeList.msg611);
                }
            });
    };

    const onError = (data) => {
        console.log("onError -- ", data);
    };

    const checkCapsLock1 = (e) => {
        let capsLock = e.getModifierState("CapsLock");
        setPwdCapsLock(capsLock);
    };

    const checkCapsLock2 = (e) => {
        let capsLock = e.getModifierState("CapsLock");
        setRePwdCapsLock(capsLock);
    };

    return (
        <Fragment>
            <div className="findpwd_panel">
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <div className="row mb-3">
                        <label className="col-sm-3 col-form-label text-end">새 비밀번호</label>
                        <div className="col-sm-9">
                            <input
                                type="password"
                                className="form-control"
                                name="userpassword"
                                placeholder="영문, 숫자 조합 6자리 이상."
                                onKeyDown={(e) => checkCapsLock1(e)}
                                {...register("userpassword", {
                                    required: "비밀번호는 필수입니다.",
                                    pattern: {
                                        value: /^[a-zA-Z0-9]*$/,
                                        message: "영문, 숫자를 조합하여 주세요.",
                                    },
                                    minLength: {
                                        value: 6,
                                        message: "6자리 이상 입력하여 주세요.",
                                    },
                                })}
                            />
                            {errors.userpassword && <span className="form-text text-danger">{errors.userpassword.message}</span>}
                            {pwdCapsLock === true && <span className="form-text text-danger">Caps Lock이 켜져있습니다.</span>}
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label className="col-sm-3 col-form-label text-end">비밀번호 확인</label>
                        <div className="col-sm-9">
                            <input
                                type="password"
                                className="form-control"
                                name="verifypwd"
                                placeholder="비밀번호를 다시 입력해 주세요."
                                onKeyDown={(e) => checkCapsLock2(e)}
                                {...register("verifypwd", {
                                    required: true,
                                    validate: (value) => {
                                        if (watch("userpassword") != value) return false;
                                    },
                                })}
                            />
                            {errors.verifypwd && errors.verifypwd.type === "required" && <span className="form-text text-danger">비밀번호는 필수입니다.</span>}
                            {errors.verifypwd && errors.verifypwd.type === "validate" && <span className="form-text text-danger">비밀번호가 일치하지 않습니다.</span>}
                            {rePwdCapsLock === true && <span className="form-text text-danger">Caps Lock이 켜져있습니다.</span>}
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-sm-2"></div>
                        <div className="col-sm-9 text-center">
                            {" "}
                            <Button type="submit" className="btn_layout">
                                비밀번호 변경
                            </Button>
                        </div>
                        <div className="col-sm-1"></div>
                    </div>
                </form>
            </div>
        </Fragment>
    );
}

export default FindPwdReset;
