export const channelList = [
    { id: 1, label: "인스타그램", value: "instaPosting", disabled: false },
    { id: 2, label: "유튜브 비디오", value: "youtube", disabled: true },
    { id: 3, label: "틱톡", value: "tiktok", disabled: true },
];

export const photographyList = [
    { id: 1, value: "상품과 함께 셀카 형식으로 촬영", label: "selfie" },
    { id: 2, value: "상품을 실제 사용하는 장면을 촬영", label: "picture" },
    { id: 3, value: "상품이나 브랜드가 잘 보이도록 촬영", label: "photo" },
    // { id: 4, value: "직접입력", label: "direct" },
];

export const regionDataList = [
    { id: 1, value: "전국" },
    { id: 2, value: "서울" },
    { id: 3, value: "경기" },
    { id: 4, value: "인천" },
    { id: 5, value: "세종" },
    { id: 6, value: "강원" },
    { id: 7, value: "대전" },
    { id: 8, value: "전주" },
    { id: 9, value: "대구" },
    { id: 10, value: "부산" },
    { id: 11, value: "여수" },
    { id: 12, value: "충남" },
    { id: 13, value: "충북" },
    { id: 14, value: "경남" },
    { id: 15, value: "경북" },
    { id: 16, value: "전남" },
    { id: 17, value: "전북" },
    { id: 18, value: "제주" },
]; 