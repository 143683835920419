import React, { Fragment, useState } from "react";
import CheckRequestPart from "components/RegisterCampaign/CheckRequestPart";
import EditCampaignRequest from "./EditCampaignRequest";
import { useNavigate } from "react-router-dom";
import CampaignQuote from "./CampaignQuote";

const S3_BUCKET = "samplelife-bucket-prd";
const DIR_NAME = "images/clients/"; // + tab1_info.company_name; // ex) "images/campaigns/79(캠페인 번호)"
const REGION = "ap-northeast-2";
const ACCESS_KEY = "AKIA55KDZRSHS5FNWOOT";
const SECRET_ACCESS_KEY = "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";

const CheckCampaignRequest = ({ setActiveTab, jsonData, setJsonData }) => {
    const navigate = useNavigate();
    const [editEffect, setEditEffect] = useState(null);
    const [editResult, setEditResult] = useState(false);

    return (
        <Fragment>
            <div className="hr_line"></div>
            <div className="check_campaign_request mtb190">
                <div className="container">
                    <h2 className="inner_heading">캠페인 요청서 확인</h2>
                    <div className="findresults mb-5">
                        <p>
                            <span className="color_blue">{jsonData?.product_name}</span>의 캠페인 요청서 작성이 완료되었습니다.  <br />입력하신 내용과 예상 견적을 확인해 보세요. <br />
                            <br /> 캠페인이 APP에 게시되면 캠페인 요청서의 내용을 수정할 수 없습니다. 
                        </p>
                    </div>

                    {editResult ? (
                        <EditCampaignRequest setEditResult={setEditResult} setJsonData={setJsonData} jsonData={jsonData} />
                    ) : (
                        <CheckRequestPart jsonData={jsonData} editEffect={editEffect} setEditResult={setEditResult} />
                    )}

                    <CampaignQuote jsonData={jsonData} />
                </div>
            </div>
        </Fragment>
    );
};

export default CheckCampaignRequest;
