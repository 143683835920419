import React, { useState } from "react";
import Pagination from "react-bootstrap/Pagination";

// const itemsPerPage = 5; // 페이지당 아이템 개수

const PaginationBox = ({ totalCount, itemsPerPage, onPageChange }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(totalCount / itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
        onPageChange(page);
    };

    // Calculate the range of page numbers to display
    const pageRange = 5; // Number of page numbers to show
    const halfRange = Math.floor(pageRange / 2);
    let startPage = currentPage - halfRange;
    let endPage = currentPage + halfRange;

    if (startPage <= 0) {
        startPage = 1;
        endPage = Math.min(pageRange, totalPages);
    } else if (endPage > totalPages) {
        startPage = Math.max(totalPages - pageRange + 1, 1);
        endPage = totalPages;
    }

    return (
        <>
            <Pagination>
                <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
                <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />

                {Array.from({ length: endPage - startPage + 1 }).map((_, index) => {
                    const pageNumber = startPage + index;
                    return (
                        <Pagination.Item key={pageNumber} active={currentPage === pageNumber} onClick={() => handlePageChange(pageNumber)}>
                            {pageNumber}
                        </Pagination.Item>
                    );
                })}

                <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
                <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
            </Pagination>
        </>
    );
};

export default PaginationBox;
