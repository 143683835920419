import React, { Fragment, useState } from "react";
import Button from "react-bootstrap/Button";
import { useLocation, useNavigate } from "react-router-dom";

const JoinPageResult = () => {
    const location = useLocation();
    const resultData = location?.state?.data;
    const navigate = useNavigate();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Fragment>
            <div className="hr_line"></div>
            <div className="register_form mtb190">
                <div className="container">
                    <div className="findresult_panel_ad ">
                        <h2 className="inner_heading">회원가입 완료</h2>
                        <div className="findresults">
                            <h5>
                                <span>10,000</span>원을 적립해 드렸습니다.
                            </h5>

                            <p>
                                <span>{resultData?.companyUser}</span>
                                님의 브랜드 파트너 더프리미언이 최적의 인플루언서를
                                <br /> 찾아드리겠습니다. 지금 바로 캠페인을 시작해 보세요.
                            </p>
                            <Button className="btn_layout" onClick={() => navigate("/campaignestimate")}>
                                캠페인 시작하기
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default JoinPageResult;
