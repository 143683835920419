import React, { Fragment } from "react"

function TermOfUse() {
    return (
        <Fragment>
            <div className="hr_line"></div>
            <div className="disclosure mtb190">
                <div className="container">
                    <h2 className="inner_heading">이용약관</h2>

                    <div className="disclosure_detls">
                        <div className="disclosure_detls_box">
                        <h6>제1장 총칙 제1조 (목적)</h6>
                        <ul>
                            <li>이 약관은 주식회사 더프리미언(이하 “회사”라 합니다)가 운영하는 서비스와 더프리미언 “애플리케이션”(이하 “홈페이지”와 “애플리케이션”을 “APP”이라고 합니다)의 서비스 이용 및 제공에 관한 제반 사항의 규정을 목적으로 합니다.</li>
                        </ul>
                        </div>
                    </div>

                    <div className="disclosure_detls">
                        <div className="disclosure_detls_box">
                        <h6>제2조 (용어의 정의) </h6>
                        <ul>
                            <li>이 약관에서 사용하는 용어의 정의는 다음과 같습니다
                            <ul>
                                    <li>“서비스”라 함은 구현되는 PC, 모바일 기기를 통하여 “이용자”가 이용할 수 있는 보장분석서비스 등 회사가 제공하는 제반 서비스를 의미합니다.</li>
                                    <li>“이용자”란 “APP”에 접속하여 이 약관에 따라 “APP”이 제공하는 서비스를 받는 회원 및 비회원을 말합니다.</li>
                                    <li>“회원”이란 “APP”에 개인정보를 제공하여 회원등록을 한 자로서, “APP”이 제공하는 서비스를 이용하는 자를 말합니다. </li>
                                    <li>“모바일 기기”란 콘텐츠를 다운로드 받거나 설치하여 사용할 수 있는 기기로서, 휴대폰, 스마트폰, 휴대정보단말기(PDA), 태블릿 등을 의미합니다.</li>
                                    <li>“계정정보”란 회원의 회원번호와 내보험다보여 등 외부계정정보, 기기정보 등 회원이 회사에 제공한 정보를 의미합니다.</li>
                                    <li>“애플리케이션”이란 회사가 제공하는 서비스를 이용하기 위하여 모바일 기기를 통해 다운로드 받거나 설치하여 사용하는 프로그램 일체를 의미합니다. ② 이 약관에서 사용하는 용어의 정의는 본 조 제1항에서 정하는 것을 제외하고는 관계법령 및 서비스별 정책에서 정하는 바에 의하며, 이에 정하지 아니한 것은 일반적인 상 관례에 따릅니다.</li>
                                </ul>
                            </li>
                        </ul>
                        </div>
                    </div>

                    <div className="disclosure_detls">
                        <div className="disclosure_detls_box">
                        <h6>제3조 (약관의 효력 및 변경)</h6>
                        <ul>
                            <li>본 약관은“APP” 내 또는 그 연결화면에 게시하거나 이용자에게 공지함으로써 효력이 발생합니다. </li>
                            <li>회사는 불가피한 여건이나 사정이 있을 경우 「약관의 규제에 관한 법률」, 「정보통신망이용촉진 및 정보보호 등에 관한 법률」등 관련 법령에 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</li>
                            <li>회사가 약관을 개정할 경우에는 적용일자 및 개정내용, 개정사유 등을 명시하여 최소한 그 적용일 7일 이전부터 “APP” 내 또는 그 연결화면에 게시하여 이용자에게 공지합니다. 다만, 변경된 내용이 회원에게 불리하거나 중대한 사항의 변경인 경우에는 그 적용일 30일 이전까지 본문과 같은 방법으로 공지하고, 회원의 전자우편주소, 전자메모, 서비스 내 쪽지, 문자메시지(LMS/SMS)의 방법으로 회원에게 통지합니다. 이 경우 개정 전 내용과 개정 후 내용을 명확하게 비교하여 회원이 알기 쉽도록 표시합니다.</li>
                            <li>회사가 약관을 교칙할 경우 교문약관 공지 후 교문 약관의 적용에 대한 회원의 동의 여부를 확인합니다. 회사는 제3항의 공고 또는 법을 할 경우 회원이 약관에 대해 동의 또는 이용의 의사 표시를 하지 않거나 동의한 것으로 볼 수 있는 내용도 함께 공지 또는 달라고 하거나, 회원이 이 기간을 연장일까지 이용의 의사 표시를 하지 않으면 약관에 동의한 것으로 볼 수 있습니다. 회원이 상호약관에 대해 동의하지 않는 경우 회사 또는 회원은 서비스 이용 계약을 종료할 수 있습니다.</li>
                        </ul>
                        </div>
                    </div>

                    <div className="disclosure_detls">
                        <div className="disclosure_detls_box">
                        <h6>제4조(약관 외 준칙)</h6>
                        <ul>
                            <li>이 조항에서 정하지 않은 사항과 이 조항의 근거는 「약관의 감속에 관한 감시」, 「정보통신망이용촉진 및 정보보호에 관한 감시」등 관련 문제 또는 상례에 정확하게</li>
                        </ul>
                        </div>
                    </div>

                    <div className="disclosure_detls">
                        <div className="disclosure_detls_box">
                        <h6>제5조 (회원가입)</h6>
                        <ul>
                            <li>이용자는 “APP”에서 정한 양식에 따라 회원정보를 기입한 후 이 약관의 내용에 대하여 동의하여 회원가입 신청을 하면, 회사가 이러한 신청을 승낙하여 회원으로 가입됩니다.</li>
                            <li>회사는 원칙적으로 전 항에 따라 회원가입신청에 대하여 승낙함을 원칙으로 합니다. 다만, 회사는 다음 각 호의 어느 하나에 해당하는 이용자에 대해서는 회원가입을 거절하거나 사후에 회원자격을 상실시킬 수 있습니다.
                                <ul>
                                    <li>회원정보 내용을 허위로 기재하거나 타인의 명의를 도용한 경우 </li>
                                    <li>회사가 서비스를 제공하지 않은 국가에서 비정상적이거나 우회적인 방법을 통해 서비스를 이용하는 경우</li>
                                    <li>사회의 안녕과 질서 또는 미풍양속을 저해할 목적으로 신청한 경우</li>
                                    <li>부정한 용도로 서비스를 이용하고자 하는 경우</li>
                                    <li>영리를 추구할 목적으로 서비스를 이용하고자 하는 경우</li>
                                    <li>가입 신청자가 본 약관에 의거 이전에 회원자격을 상실한 적이 있는 경우</li>
                                    <li>만 14세 미만인 경우 8. 그 밖에 각 호에 준하는 사유로서 승낙이 부적절하다고 판단되는 경우 ③ 회원은 가입시 등록한 회원정보의 변경이 발생한 경우, 즉시 “APP”에서 직접 수정 또는 전자우편, 기타 방법으로 회사에 그 변경 사실을 알려야 합니다.</li>
                                </ul>
                            </li>
                        </ul>
                        </div>
                    </div>

                    <div className="disclosure_detls">
                        <div className="disclosure_detls_box">
                        <h6>제6조 (회원탈퇴 및 자격상실)</h6>
                        <ul>
                            <li>회원은 언제든지 서비스 이용을 원하지 않는 경우 언제든지 탈퇴를 요청할 수 있으며, 이 경우 회사는 즉시 회원탈퇴를 처리합니다. 회원탈퇴로 인해 회원이 서비스 내에서 보유한 이용정보는 모두 삭제되어 복구가 불가능하게 됩니다.</li>
                            <li>회사는 회원이 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 등 본 계약을 유지할 수 없는 중대한 사유가 있는 경우에는 회원에게 통지하고, 서비스 이용을 제한․중지하거나 회원 자격을 상실시킬 수 있습니다.</li>
                            <li>회사가 회원자격을 상실시키는 경우에는 회원 등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원 등록 말소 전에 소명할 기회를 부여합니다.</li>
                            <li>회사는 최근의 서비스 이용일부터 연속하여 1년 동안 회사의 서비스를 이용하지 않은 회원(이하 “휴면계정”이라 합니다)의 개인정보를 보호하기 위해 이용계약을 정지 또는 해지하고 회원의 개인정보를 분리보관 또는 파기 등의 조치를 취할 수 있습니다. 이 경우 조치일 30일 전까지 계약 정지 또는 해지, 개인정보 분리보관 또는 파기 등의 조치가 취해진다는 사실 및 파기될 개인정보 등을 회원에게 통지합니다.</li>
                        </ul>
                        </div>
                    </div>

                    <div className="disclosure_detls">
                        <div className="disclosure_detls_box">
                        <h6>제7조 (회원에 대한 통지)</h6>
                        <ul>
                            <li>회사가 회원에 대한 통지를 하는 경우, 회원이 회사에 제출한 전자우편 또는 휴대번호로 할 수 있다.</li>
                            <li>회사는 불특정다수 회원에 대한 통지의 경우 30일 이상 “APP”에 게시함으로서 개별 통지에 갈음 할 수 있다.</li>
                        </ul>
                        </div>
                    </div>

                    <div className="disclosure_detls">
                        <div className="disclosure_detls_box">
                        <h6>제8조 (회사의 의무)</h6>
                        <ul>
                            <li>회사는 관련 법령, 이 약관에서 정하는 권리의 행사 및 의무의 이행을 신의에 따라 성실하게 준수합니다.</li>
                            <li>회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고 준수합니다. 회사는 이 약관 및 개인정보처리방침에서 정한 경우를 제외하고는 회원의 개인정보가 제3자에게 공개 또는 제공되지 않도록 합니다.</li>
                            <li>회사는 계속적이고 안정적인 서비스의 제공을 위하여 서비스 개선을 하던 중 설비에 장애가 생기거나 데이터 등이 멸실․훼손된 때에는 천재지변, 비상사태, 현재의 기술로는 해결이 불가능한 장애나 결함 등 부득이한 사유가 없는 한 지체 없이 이를 수리 또는 복구하도록 최선의 노력을 다합니다.</li>
                        </ul>
                        </div>
                    </div>

                    <div className="disclosure_detls">
                        <div className="disclosure_detls_box">
                        <h6>제9조 (회원의 의무)</h6>
                        <ul>
                            <li>회원은 회사에서 제공하는 서비스의 이용과 관련하여 다음 각 호에 해당하는 행위를 해서는 안 됩니다.
                            <ul>
                                    <li>이용신청 또는 회원 정보 변경 시 타인의 명의를 도용하거나 허위사실을 기재하는 행위</li>
                                    <li>회사의 직원이나 운영자를 가장하거나 타인의 명의를 도용하여 메일을 발송하는 행위, 타인으로 가장하거나 타인과의 관계를 허위로 명시하는 행위</li>
                                    <li>다른 회원의 개인정보를 무단으로 수집⋅저장⋅게시 또는 유포하는 행위</li>
                                    <li>서비스를 무단으로 영리, 영업, 광고, 홍보, 정치활동, 선거운동 등 본래의 용도 이외의 용도로 이용하는 행위</li>
                                    <li>회사의 서비스를 이용하여 얻은 정보를 무단으로 복제․유통․조장하거나 상업적으로 이용하는 행위, 알려지거나 알려지지 않은 버그를 악용하여 서비스를 이용하는 행위</li>
                                    <li>타인을 기망하여 이득을 취하는 행위, 회사의 서비스의 이용과 관련하여 타인에게 피해를 입히는 행위</li>
                                    <li>회사나 타인의 지적재산권 또는 초상권을 침해하는 행위, 타인의 명예를 훼손하거나 손해를 가하는 행위</li>
                                    <li>법령에 의하여 전송 또는 게시가 금지된 정보(컴퓨터 프로그램)나 컴퓨터 소프트웨어⋅하드웨어 또는 전기통신장비의 정상적인 작동을 방해⋅파괴할 목적으로 고안된 바이러스⋅컴퓨터 코드⋅파일⋅프로그램 등을 고의로 전송⋅게시⋅유포 또는 사용하는 행위</li>
                                    <li>회사로부터 특별한 권리를 부여받지 않고 애플리케이션을 변경하거나, 애플리케이션에 다른 프로그램을 추가⋅삽입하거나, 서버를 해킹⋅역설계하거나, 소스 코드나 애플리케이션 데이터를 유출⋅변경하거나, 별도의 서버를 구축하거나, 웹사이트의 일부분을 임의로 변경⋅도용하여 회사를 사칭하는 행위</li>
                                    <li>그 밖에 관련 법령에 위반되거나 선량한 풍속 기타 사회통념에 반하는 행위 </li>
                                </ul>
                                <li>회원의 ID와 비밀번호에 관한 관리책임은 회원에게 있으며, 이를 제3자가 이용하도록 하여서는 안됩니다.</li>
                                <li>이용자는 본 약관 및 관련법령에 규정한 사항을 준수하여야 합니다.</li>
                            </li>
                        </ul>
                        </div>
                    </div>

                    <div className="disclosure_detls">
                        <div className="disclosure_detls_box">
                        <h6>제10조 (서비스의 이용)</h6>
                        <ul>
                            <li>“APP”은 다음과 같은 서비스를 회원에게 제공합니다. 단, 회사가“APP”으로 제공하는 서비스 이용을 위해 필요시 이용자에게 위치정보이용약관 및 “계정정보”의 제공에 관한 동의를 추가로 요구할 수 있으며, 동의하지 않을 경우 보험계약 조회 등 일부 서비스가 제한될 수 있습니다.
                                <ul>
                                    <li>보험계약 조회 및 분석정보 제공</li>
                                    <li>내보험다보여(한국신용정보원) APP 가입 대행</li>
                                    <li>이메일 수신 서비스</li>
                                    <li>보험상품 관련 설계사 연결 및 보장 컨설팅</li>
                                    <li>회사가 취급하는 보험상품의 설명 및 안내</li>
                                    <li>시사, 금융, 투자, 부동산, 건강관리 등 컨텐츠 제공</li>
                                    <li>보험금 청구 관련 서비스</li>
                                    <li>기타 회사가 정하는 서비스</li>
                                </ul>
                            </li>
                            <li>회사는 회원에게 별도의 동의를 받은 경우 서비스 이용에 대한 유용한 각종 정보에 대하여 “APP”에 게재하는 것 이외에 문자메시지, 푸시(Push) 알림 등의 방법으로 회원에게 제공할 수 있습니다.</li>
                            <li>서비스의 이용은 “APP”의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴를 원칙으로 합니다. 다만, 정기점검 등의 필요로 인하여 회사가 정한 날 및 시간에 대해서는 예외로 합니다.</li>
                            <li>회사는 “APP” 시스템 등의 보수, 점검, 교체, 시스템의 고장, 통신의 두절, 기타 불가항력적 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.</li>
                        </ul>
                        </div>
                    </div>

                </div>
            </div>
        </Fragment>
    )
}

export default TermOfUse;