import React, { Fragment } from "react";

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

function ImageProduct({ pictureImgs }) {
    const imgDatas = pictureImgs || [];

    const images = imgDatas.map((item) => ({
        original: item.image_data,
        thumbnail: item.image_data,
    }));

    return (
        <Fragment>
            <div className="slider">
                <ImageGallery
                    items={images}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    autoPlay={false}
                    isRTL={false}
                    disableSwipe={false}
                    stopPropagation={false}
                />
            </div>
        </Fragment>
    );
}

export default ImageProduct;
