export function selectCampaignType(data) {
    let result = "";
    switch (data) {
        case "productReview":
            result = "상품리뷰";
            break;
        case "serviceReview":
            result = "서비스리뷰";
            break;
        case "visitReview":
            result = "방문리뷰";
            break;
        default:
            break;
    }
    return result;
}

export function selectChannelValue(data) {
    let result = "";
    switch (data) {
        case "instaPosting":
            result = "인스타그램 포스팅";
            break;
        case "instaReels":
            result = "인스타그램 릴스";
            break;
        case "youtube":
            result = "유튜브 비디오";
            break;
        case "틱톡":
            result = "틱톡";
            break;
        default:
            break;
    }
    return result;
}

export function selectContentsType(data) {
    let result = "";
    switch (data) {
        case "image":
            result = "이미지";
            break;
        case "video":
            result = "동영상";
            break;
        case "all":
            result = "이미지, 동영상";
            break;
        default:
            break;
    }
    return result;
}

export function selectGenderType(data) {
    let result = "";
    switch (data) {
        case "W":
            result = "여성";
            break;
        case "M":
            result = "남성";
            break;
        case "A":
            result = "상관없음";
            break;
        default:
            break;
    }
    return result;
}

export function selectAgeType(data) {
    const values = [];

    const dataArray = data?.split(",");

    dataArray?.forEach((item) => {
        switch (
            item.trim() // 각 아이템 앞뒤 공백을 제거하고 비교
        ) {
            case "child":
                values.push("영유아");
                break;
            case "age10s":
                values.push("10대");
                break;
            case "age20s":
                values.push("20대");
                break;
            case "age30s":
                values.push("30대");
                break;
            case "age40s":
                values.push("40대");
                break;
            case "age50s":
                values.push("50대");
                break;
            case "age60s":
                values.push("60대 이상");
                break;
            default:
                values.push(null);
                break;
        }
    });
    return values;
}

export function selectMethodType(data) {
    let result = "";
    switch (data) {
        case "free":
            result = "상품 무료 제공";
            break;
        case "retrieval":
            result = "캠페인 진행 후 상품 회수";
            break;
        default:
            break;
    }
    return result;
}

export function selectProcessType(data) {
    let result = "";
    switch (data) {
        case "recruiting":
            result = "모집중";
            break;
        case "recruitDone":
            result = "모집완료";
            break;
        case "shipping":
            result = "배송중";
            break;
        case "posting":
            result = "포스팅 현황";
            break;
        case "evaluation":
            result = "포스팅 평가";
            break;
        case "cancel":
            result = "캠페인 취소";
            break;
        default:
            break;
    }
    return result;
}

export function selectProcessText(data) {
    let result = "";
    switch (data) {
        case "recruiting":
            result = "프리미언 선발까지 <br/> 남은시간";
            break;
        case "recruitDone":
            result = "포스팅 마감까지 <br/> 남은시간";
            break;
        case "shipping":
            result = "포스팅 마감까지 <br/> 남은시간";
            break;
        case "posting":
            result = "포스팅 마감까지 <br/>남은시간";
            break;
        case "evaluation":
            result = "포스팅 평가";
            break;
        case "캠페인 시작까지 <br/>남은시간":
            result = "캠페인 취소";
            break;
        default:
            break;
    }
    return result;
}
