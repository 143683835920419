import React, { Fragment } from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

function NoOngoingCampaign() {
    return (
        <Fragment>
            <div className="noongoing_campaign">
                <div className="findresults">
                    <h3>해당하는 캠페인이 없습니다​</h3>
                    <p>더프리미언이 담당자님의 브랜드 파트너가 되어드리겠습니다. 지금 바로 캠페인을 시작해 보세요.</p>
                </div>
                <div className="col-sm-12 text-center">
                    <Link to="/campaignestimate" className="btn_layout">
                        캠페인 시작하기
                    </Link>
                </div>
            </div>
        </Fragment>
    );
}

export default NoOngoingCampaign;
