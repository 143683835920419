import React, { Fragment } from "react";
import { Link } from "react-router-dom";

function PrivacyPolicy() {
    return (
        <Fragment>
            <div className="hr_line"></div>
            <div className="mycampaign faq_panel mtb190">
                <div className="container">
                    <h2 className="inner_heading">개인정보취급방침</h2>

                    <div className="disclosure_detls">
                        <h4>thePremian에 오신 것을 환영합니다.</h4>
                        <p>다음은 thePremian Mobile APP Serivce을 이용함에 있어 회원이 회사에 제공하는 개인정보에 관한 개인정보취급방침에 대하여 설명합니다.</p>
                        <div className="disclosure_detls_box">
                            <h6>개인정보취급방침</h6>
                            <ul>
                                <li>본 개인정보취급방침은 정보통신망법을 기준으로 작성되었으며, 관계 법령 및 개인정보보호규정, 가이드라인을 준수합니다.</li>
                                <li>주식회사 thePremian(이하 “회사”라고 합니다.)는 thePremian Mobile APP Serivce(이하 “서비스”라고 합니다.)를 이용하는 이용자의 동의를 기반으로</li>
                                <li>개인정보를 수집/이용 및 제공하며, 이용자의 개인정보 자기결정권(이하 “이용자의 권리”하고 합니다)을 적극적으로 보장합니다.</li>
                            </ul>
                        </div>

                        <div className="disclosure_detls_box">
                            <h6>개인정보 수집</h6>
                            <ul>
                                <li>이용자는 회사가 정한 회원가입절차에 따라 회원가입을 한 후 서비스를 이용할 수 있습니다.</li>
                                <li>
                                    회원가입을 할 경우, 회사는 이용자로부터 수집하는 개인정보는 아래와 같습니다.
                                    <ul>
                                        <li>휴대폰 번호, 페이스북/인스타그램 SNS 정보</li>
                                    </ul>
                                </li>
                                <li>
                                    서비스 이용할 경우, 회사는 회원으로부터 추가로 수집하는 개인정보는 아래와 같습니다.
                                    <ul>
                                        <li>방문 및 학습 시간, 학습결과 등 서비스 이용 기록, 디바이스 정보</li>
                                    </ul>
                                </li>
                                <li>회사는 회원의 소중한 인권을 침해할 우려가 있는 정보는 수집하지 않습니다.</li>
                            </ul>
                        </div>

                        <div className="disclosure_detls_box">
                            <h6>개인정보 이용</h6>
                            <ul>
                                <li>
                                    회사는 개인정보를 회원관리, 서비스 개발/제공 및 향상, 안전한 이용환경 구축 등 아래의 목적으로만 이용합니다.
                                    <ul>
                                        <li>회원 가입의사 및 연령 확인, 회원 탈퇴의사 확인</li>
                                        <li>이벤트 행사시 정보전달, 마케팅 및 광고 등에 활용</li>
                                        <li>서비스 이용 기록과 접속 빈도 분석 서비스 이용에 대한 통계, 맞춤형 서비스 제공, 서비스 개선 및 개발에 활용</li>
                                        <li>서비스 이용약관 및 법령 위반하는 회원에 대한 제한조치, 불량 회원의 부정이용에 대한 방지 및 제재</li>
                                        <li>다양한 서비스 제공, 공지사항, 문의사항 및 불만처리</li>
                                    </ul>
                                </li>
                                <li>회사는 개인정보의 이용 목적이 변경될 경우, 반드시 사전에</li>
                                <li>회원의 동의를 구하여야 합니다.</li>
                            </ul>
                        </div>

                        <div className="disclosure_detls_box">
                            <h6>개인정보의 제공</h6>
                            <ul>
                                <li>
                                    회사는 원칙적으로 회원의 사전 동의 없이 개인정보를 외부에 제공하지 않습니다. 제3자에게 개인정보를 제공하여야 하는 경우, 반드시 사전에 회원에게.
                                    해당 사실을 고지하고 동의를 구하여야 합니다.
                                </li>
                                <li>
                                    다만, 아래의 목적의 경우 예외적으로 회원의 개인정보를 제3자에게 제공합니다.
                                    <ul>
                                        <li>회원이 외부 제휴사의 서비스를 이용하기 위하여 개인정보 제공에 동의를 한 경우</li>
                                        <li>관련 법령에 의거하여 회사에 개인정보 제출 의무가 발생한 경우</li>
                                        <li>회원의 생명이나 안전에 급박한 위험이 확인되어 이를 해소하기 위한 경우</li>
                                    </ul>
                                    <li>회사는 편리하고 더 나은 서비스를 제공하기 위하여 업무 중 일부를 외부에 위탁하고 있으며, 위탁받은 업체가 정보통신망법 등 </li>
                                    <li> 관계 법령에 의거하여 개인정보를 안전하게 취급하도록 필요한 사항을 규정하고 관리/감독 합니다.</li>
                                </li>
                            </ul>
                        </div>

                        <div className="disclosure_detls_box">
                            <h6>개인정보 파기</h6>
                            <ul>
                                <li>
                                    회사는 원칙적으로 회원의 개인정보를 회원 탈퇴 시 지체없이 파기합니다. 또한, 회원의 개인정보에 대하여 개인정보의 수집/이용 목적이 달성된 경우,
                                    해당 정보를 재생이 불가능한 방법으로 지체없이 파기합니다.
                                </li>
                                <li>
                                    다만, 전자상거래등에서의 소비자보호에 관한 법률, 전자금융거래법, 통신비밀보호법 등 관계법령에 의하여 보관하여야 하는 정보는 법령이 정한 기간동안
                                    보관한 후 파기합니다.
                                </li>
                                <li>이때 별도 저장 관리되는 개인정보는 법령에 정한 경우가 아니고서는 절대로 다른 목적으로 이용하지 않습니다.</li>
                                <li>
                                    전자적 파일 형태인 경우 복구 및 재생되지 않도록 기술적인 방법을 이용하여 완전하게 삭제하고, 그밖에 기록물, 인쇄물, 서면 등의 경우 분쇄하거나
                                    소각하여 파기합니다.
                                </li>
                            </ul>
                        </div>

                        <div className="disclosure_detls_box">
                            <h6>개인정보 보호</h6>
                            <ul>
                                <li>
                                    회원의 개인정보를 보호 및 취급함에 있어 다음과 같이 최선을 다하고 있습니다.
                                    <ul>
                                        <li>회원의 개인정보를 암호화하고 있습니다.</li>
                                        <li>해킹이나 컴퓨터 바이러스로부터 보호하기 위하여 노력하고 있습니다.</li>
                                        <li>개인정보 접근권한 부여를 최소화하고 있습니다.</li>
                                        <li>임직원에게 회원의 개인정보 보호에 대하여 정기적인 교육을 실시하고 있습니다.</li>
                                    </ul>
                                </li>
                                <li>
                                    회원의 개인정보를 보호하고 의문점 해결, 불만처리를 위하여 다음과 같이 책임자를 지정하고 운영하고 있습니다. 언제라도 문의 주시면 신속하고 충분한
                                    답변을 드리도록 하겠습니다.
                                    <ul>
                                        <li>
                                            개인정보 관리책임자
                                            <ul>
                                                <li>책 임 자: 박지영</li>
                                                <li>연 락 처: 010-5246-7650</li>
                                                <li>메 일: support@thepremian.com</li>
                                            </ul>
                                        </li>
                                        <li>
                                            기타 개인정보 침해 신고 및 상담
                                            <ul>
                                                <li>
                                                    개인정보 침해신고센터: 국번없이 118{" "}
                                                    <Link to="https://privacy.kisa.or.kr/" target="_blank">
                                                        (privacy.kisa.or.kr)
                                                    </Link>
                                                </li>
                                                <li>
                                                    대검찰청 사이버수사과: 국번없이 1301 <Link to="https://www.spo.go.kr/site/spo/main.do">(www.spo.go.kr)</Link>
                                                </li>
                                                <li>
                                                    경찰청 사이버안전국 : 국번없이 182 <Link to="http://www.ctrc.go.kr/">(www.ctrc.go.kr)</Link>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>

                        <div className="disclosure_detls_box">
                            <h6>개정 전 고지의무</h6>
                            <ul>
                                <li>본 개인정보취급방침의 내용의 추가, 삭제 및 수정이 있을 경우, 개정 최소 7일전에 공지사항을 통해 사전에 공지하여야 합니다.</li>
                                <li>
                                    다만, 수집하는 개인정보의 항목, 이용목적의 변경 등과 같이 회원의 권리의 중대한 변경이 발생하는 경우, 최소 30일 전에 공지하여야 하며 필요 시
                                    이용자 동의를 구하여야 합니다.
                                </li>
                                <li>위 개인정보취급방침은 2022년 04월 30일에 공지합니다.</li>
                                <li>위 개인정보취급방침은 2022년 04월 30일부터 적용합니다.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default PrivacyPolicy;
