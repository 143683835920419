import React, { Fragment, useState } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";

const RegisterPostingGuide = ({ setActiveTab, jsonData, setJsonData }) => {
    // console.log("들어온 img", jsonData);
    const [status, setStatus] = useState(0); // 0: no show, 1: show yes, 2: show no.
    const [isFormValid, setFormValid] = useState(false);
    const {
        register,
        handleSubmit,
        getValues,
        watch,
        formState: { errors, isValid },
    } = useForm({
        mode: "onChange",
    });
    const watchUserEmail = watch("contents_type");
    const watchPhotography = watch("photography_preference");

    const validate = (value) => {
        return value?.length > 0 || "체크박스를 하나 이상 선택해주세요.";
    };

    const checkFormValidity = () => {
        const formValues = getValues(); // 현재 입력 필드 값 가져오기
        const isAnyValueEntered = Object.values(formValues).some((value) => value !== ""); // 입력 필드 값 중 하나라도 값이 입력되었는지 확인

        setFormValid(isAnyValueEntered);
    };

    const onSubmit = (data) => {
        if (jsonData?.product_name === "") {
            alert("기본정보를 작성해 주세요.");
        } else if (jsonData?.campaign_name === "") {
            alert("상세정보를 작성해 주세요.");
        } else if (jsonData?.campaign_product_pictures?.length == 0) {
            alert("대표 이미지를 업로드 해주세요.");
        } else {
            let contentType = data.contents_type?.length === 2 ? "all" : data.contents_type[0] === "image" ? "image" : "video";

            let setData = {
                contents_type: contentType,
                include_text: data.include_text == true ? "Y" : "",
                photography_preference: data.photography_preference,
                text_requirements: data.text_requirements,
                campaign_hashtag: {
                    hashtag1: data.hashtag0,
                    hashtag2: data.hashtag1,
                    hashtag3: data.hashtag2,
                    hashtag4: data.hashtag3,
                },
                needHeader: false,
            };

            let tempJson = jsonData;
            for (var key in setData) {
                tempJson[key] = setData[key];
            }
            setJsonData(tempJson);
            setActiveTab("checkcampaign");
        }
    };
    const onError = (data) => {
        console.log("onError --- ", data);
    };

    const radioHandler = (status) => {
        setStatus(status);
    };

    return (
        <Fragment>
            <div className="register_posting_guide common_review">
                <div className="container">
                    <form onSubmit={handleSubmit(onSubmit, onError)}>
                        <div className="row mb-7">
                            <div className="col-sm-12">
                                <label className="form-label">
                                    <span className="numbers">01</span>콘텐츠 유형을 선택해 주세요 (중복 가능)<span className="required_field">*</span>
                                </label>
                            </div>

                            <div className="col-sm-12">
                                <div className=" input-group">
                                    <div className="form-check form-check-inline  mb-3">
                                        <input className="form-check-input" type="checkbox" name="contents_type" value="image" id="image" {...register("contents_type")} />
                                        <label className="form-check-label" htmlFor="image">
                                            {" "}
                                            이미지{" "}
                                        </label>
                                    </div>

                                    <div className="form-check form-check-inline  mb-3">
                                        <input className="form-check-input" type="checkbox" name="contents_type" value="video" id="video" {...register("contents_type")} />
                                        <label className="form-check-label" htmlFor="video">
                                            {" "}
                                            동영상{" "}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-7">
                            <div className="col-sm-12">
                                <label className="form-label">
                                    <span className="numbers">02</span>원하시는 촬영 방법을 선택해 주세요<span className="required_field">*</span>
                                </label>
                            </div>

                            <div className="col-sm-12 mb-7">
                                <div className=" input-group">
                                    <div className="form-check width_100  mb-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="shooting"
                                            value="상품과 함께 셀카 형식으로 촬영"
                                            id="selfi"
                                            checked={status === 1}
                                            onClick={(e) => radioHandler(1)}
                                            onChange={() => {}}
                                            {...register("photography_preference")}
                                        />
                                        <label className="form-check-label" htmlFor="selfi">
                                            {" "}
                                            상품과 함께 셀카 형식으로 촬영{" "}
                                        </label>
                                    </div>

                                    <div className="form-check width_100  mb-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="shooting"
                                            value="상품을 실제 사용하는 장면을 촬영"
                                            id="picture"
                                            checked={status === 2}
                                            onClick={(e) => radioHandler(2)}
                                            onChange={() => {}}
                                            {...register("photography_preference")}
                                        />
                                        <label className="form-check-label" htmlFor="picture">
                                            {" "}
                                            상품을 실제 사용하는 장면을 촬영{" "}
                                        </label>
                                    </div>

                                    <div className="form-check width_100  mb-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="shooting"
                                            value="상품이나 브랜드가 잘 보이도록 촬영"
                                            id="photo"
                                            checked={status === 3}
                                            onClick={(e) => radioHandler(3)}
                                            onChange={() => {}}
                                            {...register("photography_preference")}
                                        />
                                        <label className="form-check-label" htmlFor="photo">
                                            {" "}
                                            상품이나 브랜드가 잘 보이도록 촬영{" "}
                                        </label>
                                    </div>

                                    <div className="form-check width_100  mb-3">
                                        <div className="row">
                                            <div className="col-sm-2">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="shooting"
                                                    value="direct"
                                                    id="direct"
                                                    checked={status === 4}
                                                    onClick={(e) => radioHandler(4)}
                                                    onChange={() => {}}
                                                />
                                                <label className="form-check-label" htmlFor="direct">
                                                    {" "}
                                                    직접 입력{" "}
                                                </label>
                                            </div>
                                            <div className="col-sm-10">
                                                {status === 4 && (
                                                    <input className="form-control" placeholder="" style={{ display: "inline-block" }} {...register("photography_preference")} />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-7">
                                <div className="col-sm-12">
                                    <label className="form-label">
                                        <span className="numbers">03</span>포스팅 시 반드시 포함되어야 할 키워드 및 예시를 알려주세요
                                        <span className="required_field">*</span>
                                    </label>
                                </div>
                                <div className="col-sm-12">
                                    <div className=" input-group">
                                        <textarea
                                            className="form-control"
                                            placeholder="프리미언(인플루언서) 맨션에 꼭 들어가야 하는 중요 키워드, 설명 등 필수 필요사항을 입력해 주세요.
                                                        키워드는 해시태그와는 다른 것으로 선정하시는 것이 좋습니다!
                                                        (3줄 이하로 간단하게 작성)"
                                            rows="6"
                                            {...register("text_requirements", { required: true })}
                                        ></textarea>
                                    </div>
                                    <div className="form-check" style={{ marginTop: "10px" }}>
                                        <input className="form-check-input" type="checkbox" id="include_text" {...register("include_text")} />
                                        <label className="form-check-label" htmlFor={"include_text"}>
                                            포스팅이 잘 검색될 수 있도록, 3줄이상 / 이미지 5장 이상 포스팅에 포함되어야 한다는 문구 보이기
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-7">
                                <div className="col-sm-12">
                                    <label className="form-label">
                                        <span className="numbers">04</span>필수로 입력해야 할 해시태그를 입력해 주세요<span className="required_field">*</span>
                                    </label>
                                </div>
                                <div className="col-sm-12">
                                    <div className="row hash_tags">
                                        {Array.from({ length: 4 }, (_, i) => {
                                            return (
                                                <div className="col-sm-3" key={i}>
                                                    <input className="form-control" name="campaign_hashtag" {...register(`hashtag${i}`)} onChange={checkFormValidity} />
                                                </div>
                                            );
                                        })}
                                        <div className="col-sm-12 form-text">
                                            <span className="required_field">#광고</span>#광고 해시태그가 첫 번째에 고정 노출. 이는 ‘추천·보증 등에 관한 표시·광고 심사지침 개정’에
                                            따라 해당 내용을 필수로 고지해야 합니다.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 text-center">
                                    <Button
                                        type="submit"
                                        className={`btn_layout ${
                                            isValid && status !== 0 && isFormValid && watchUserEmail?.length > 0 && !(status === 4 && watchPhotography === "") ? "" : "un_active"
                                        }`}
                                    >
                                        캠페인 등록{" "}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    );
};

export default RegisterPostingGuide;
